"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("gatewayTemplateService", [
    "httpService",
    function (httpService) {
        var service = {};

        service.find = function (partnerId, clientId) {
            return httpService.get(`/api/gateway-template?partnerId=${partnerId}&clientId=${clientId}`);
        };
        service.get = function (id) {
            return httpService.get("/api/gateway-template/" + id);
        };
        service.create = function (template) {
            return httpService.post("/api/gateway-template", template);
        };
        service.update = function (id, template) {
            return httpService.put("/api/gateway-template/" + id, template);
        };
        service.delete = function (id) {
            return httpService.delete("/api/gateway-template/" + id);
        };
        service.getSchema = function () {
            return httpService.get("/api/gateway-template/schema");
        };

        return service;
    },
]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("GatewayTemplateListCtrl", [
    "$scope",
    "$location",
    "coreService",
    function ($scope, $location, core) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, "/manage/proxy-configurations");
        };

        $scope.filter = core.filter;

        $scope.dtConfig = {
            ajax: {
                url: "/api/gateway-template/list",
                data: function (d) {
                    d.filter = $scope.filter;
                },
            },
            columnDefs: [
                { targets: [0], data: "Id", render: r1, name: "t.Name", width: "5%" },
                { targets: [1], data: "PartnerName", title: "Partner", name: "p.Name", width: "25%" },
                { targets: [2], data: "ClientName", title: "Client", name: "c.Name", width: "25%" },
                { targets: [3], data: "Name", title: "Name", name: "t.Name", width: "25%" },
                { targets: [4], data: "Reference", title: "Reference", name: "t.Reference", width: "25%" },
                {
                    targets: [5],
                    data: "Active",
                    render: core.dt.renderYesNoFromBool,
                    title: "Active",
                    name: "t.Active",
                    width: "5%",
                },
            ],
            reloadEvent: "reloadList",
            fnRowCallback: function (nRow, aData) {
                if (!aData.Active) {
                    $(nRow).addClass("text-muted");
                }
            },
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };

        (function () {
            $scope.reload();
        })();
    },
]);

portal.controller("GatewayTemplateDetailsCtrl", [
    "$scope",
    "$location",
    "coreService",
    "$routeParams",
    "uiService",
    function ($scope, $location, core, $routeParams, uiService) {
        var resetHours = uiService.appConfig.detailedAuditResetHoursPeriod;
        var resetTicks = resetHours * 3600 * 1000;
        var currentDay = new Date();
        var nextDay = new Date(currentDay.getTime() + resetTicks);
        $scope.clients = null;

        $scope.configJson = {};
        $scope.resetHours = resetHours;

        $scope.canDelete = false;
        $scope.canEdit = true;

        Object.defineProperty($scope, "detailedAuditEnabled", {
            get: function () {
                var result = 0;
                if ($scope.configJson.detailedAuditEndDate) {
                    try {
                        result = new Date($scope.configJson.detailedAuditEndDate) - currentDay > 0 ? 1 : 0;
                    } catch {
                        // invalid date
                    }
                }

                return result;
            },
            set: function (value) {
                if (value == 1) {
                    $scope.configJson.detailedAuditEndDate = nextDay.toISOString();
                } else {
                    delete $scope.configJson.detailedAuditEndDate;
                }
            },
        });

        $scope.model = {
            Active: 0,
        };

        $scope.editorOptions = null;
        $scope.schema = null;

        const defaultEditorOptions = {
            name: "Products",
            modes: ["code", "tree", "view" /*, "form", "text", "preview"*/],
            allowSchemaSuggestions: true,
            onChangeText: function (json) {
                try {
                    // $scope.model.Configuration = JSON.stringify(JSON.parse(json), null, 2);
                    Object.assign($scope.configJson, JSON.parse(json));
                } catch (err) {}
            },
            onEditable: function () {
                return $scope.canEdit;
            },
            /*
            onError: function (e) {
                console.log("[jsoneditor] onError", e);
            },
            onModeChange: function (newMode, oldMode) {
                console.log("[jsoneditor] onModeChange", newMode, oldMode);
            },
            onValidate: function (json) {
                console.log("[jsoneditor] onValidate", json);
            },
            onValidationError: function (errors) {
                console.log("[jsoneditor] onValidationError", errors);
                errors.forEach((error) => {
                    switch (error.type) {
                        case "validation": // schema validation error
                            break;
                        case "customValidation": // custom validation error
                            break;
                        case "error": // json parse error
                            break;
                    }
                });
            },
            */
            schema: null,
            schemaRefs: null,
            allowSchemaSuggestions: true,
            search: true,
        };

        $scope.changePartner = function () {
            $scope.loadClients();
        };

        $scope.generateReference = function () {
            var key = core.ui.generateUnique();
            var keymd5 = core.ui.generateHash(key);
            $scope.model.Reference = keymd5;
        };

        $scope.loadClients = function () {
            if ($scope.model.PartnerId) {
                core.client.findByPartner($scope.model.PartnerId).then(function (data) {
                    $scope.clients = data;
                });
            } else {
                $scope.clients = [];
            }
        };

        function normalizeDetailedAuditEndDate(json) {
            try {
                if (json.detailedAuditEndDate && new Date(json.detailedAuditEndDate) - currentDay > resetTicks) {
                    json.detailedAuditEndDate = nextDay.toISOString();
                }
            } catch {
                delete json.detailedAuditEndDate;
            }

            return json;
        }

        $scope.save = function () {
            var data = { ...$scope.model };
            try {
                data.Configuration = JSON.stringify(normalizeDetailedAuditEndDate($scope.configJson), null, 0);
            } catch (err) {
                console.error(err);
            }

            if ($scope.model.Id > 0) {
                return core.gatewayTemplate.update(data.Id, data).then(function (r) {
                    core.ui.showResult(r, "Proxy Configuration has been updated.");
                    $scope.close();
                }, core.ui.showValidation($scope));
            } else {
                return core.gatewayTemplate.create(data).then(function (r) {
                    core.ui.showResult(r, "Proxy Configuration has been added.");
                    $scope.close();
                }, core.ui.showValidation($scope));
            }
        };

        $scope.delete = function () {
            core.ui
                .showConfirmDialog("Confirmation", "Are you sure you wish to delete Proxy Configuration?")
                .then(function () {
                    core.gatewayTemplate.delete($scope.model.Id).then(function (r) {
                        core.ui.showResult(r, "Proxy Configuration has been deleted.");
                        $scope.close();
                    }, core.ui.showValidation($scope));
                });
        };

        $scope.close = function () {
            $location.path("/manage/proxy-configurations");
        };

        $scope.confirmPublish = function () {
            if ($scope.model.Active === 1) {
                return core.ui
                    .showConfirmDialog(
                        "Confirmation",
                        "Are you sure you want to publish this Proxy Configuration? You will not be able to modify it after it's published."
                    )
                    .then(
                        function () {
                            $scope.model.Active = 1;
                        },
                        function () {
                            $scope.model.Active = 0;
                        }
                    );
            }
        };

        (function () {
            core.gatewayTemplate
                .getSchema()
                .then(function (schema) {
                    // normalize schema for editor
                    schema.$schema = "http://json-schema.org/draft-07/schema";
                    $scope.schema = schema;
                })
                .then(function () {
                    if ($routeParams.id > 0) {
                        return core.gatewayTemplate.get($routeParams.id).then(function (data) {
                            try {
                                $scope.configJson = JSON.parse(data.Configuration);
                            } catch (err) {
                                console.error(err);
                            }

                            $scope.model = data;
                            $scope.canEdit = $scope.canDelete = data.Active == 0;
                        });
                    }
                })
                .then(function () {
                    return $scope.loadClients();
                })
                .then(function () {
                    $scope.editorOptions = { ...defaultEditorOptions, ...{ schema: $scope.schema } };
                });
        })();
    },
]);
