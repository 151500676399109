"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("documentStoreService", ["httpService", "$q", "$enums",
    function (httpService, $q, $enums) {
        var service = {};

        service.get = function (id) {
            return  httpService.get("/api/documentStore/" + id);
        };

        service.delete = function (id) {
            return  httpService.delete("/api/documentStore/" + id);
        };

        service.create = function (formData) {
            return  httpService.put('/api/documentStore', formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        service.update = function (documentId, formData) {
            return  httpService.post('/api/documentStore/' + documentId, formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        return service;
    }]);


/*****************
 CONTROLLERS
 ******************/
portal.controller("DocumentStoreListCtrl", ["$scope", "$location", "$uibModal", "$compile", "coreService", "$enums", "$routeParams",
    function ($scope, $location, $modal, $compile, core, $enums, $routeParams) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, '/manage/documentstore/eula');
        };

        $scope.filter = core.filter;
        $scope.filter.type = $enums.DocumentType[$routeParams.type];

        $scope.getTitle = function(){
            var title = 'Documents';
            switch ($scope.filter.type){
                case $enums.DocumentType.eula:
                    title = 'EULA Documents';
                    break;
            }
            return title;
        };

        $scope.dtConfig = {
            ajax: {
                url: "/api/documentstore/list"
            },
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: "Id", render: r1, name: "d.Id", width: "1%"},
                {targets: [1], data: "Name", title: "Name", name: "d.Name", width: "10%"},
                {targets: [2], data: "ContentType", title: "Content Type", name: "d.ContentType", width: "10%"},
                {targets: [3], data: "Size", title: "Size", name: "d.Size", width: "10%"},
                {targets: [4], data: "ActivationDate", render: core.dt.renderDate, title: "Activation Date", name: "de.ActivationDate", width: "10%"},
                {targets: [5], data: "PartnerName", title: "Partner Name", name: "p.Name", width: "10%"},
                {targets: [6], data: "Notes", title: "Notes", name: "p.Notes", width: "10%"}
            ],
            createLink: "/manage/documentstore/" + $routeParams.type + "/0"
        };
    }]);

portal.controller("DocumentStoreEditCtrl", ["$scope", "$http", "$location", "$routeParams", "coreService", "$enums", "moment",
    function ($scope, $http, $location, $routeParams, core, $enums, moment) {

        $scope.filter = core.filter;

        $scope.isEdit = $routeParams.id > 0;

        $scope.document = {
            DocumentTypeId: $enums.DocumentType[$routeParams.type]
        };

        $scope.extended = {
            ActivationDate: $scope.isEdit ? undefined : moment().toDate()
        };

        $scope.getTitle = function(){
            var title = 'Document';
            switch ($scope.document.documentTypeId){
                case $enums.DocumentType.eula:
                    title = 'EULA Document';
                    break;
            }
            return title;
        };

        var _appendFormData = function(formData, key, value){
            if(!_.isNil(value)){
                formData.append(key, value);
            }
        };
        var _buildFormData = function(){
            var formData = new FormData($('#document-store-details')[0]);
            _appendFormData(formData, 'id', $scope.document.Id);
            _appendFormData(formData, 'documentTypeId', $scope.document.DocumentTypeId);
            _appendFormData(formData, 'fileName', $scope.document.FileName);
            _appendFormData(formData, 'version', $scope.document.Version);
            _appendFormData(formData, 'contentType', $scope.document.ContentType);
            _appendFormData(formData, 'size', $scope.document.Size);
            _appendFormData(formData, 'code', $scope.document.Code);
            _appendFormData(formData, 'extendedPartnerId', $scope.extended.PartnerId);
            _appendFormData(formData, 'extendedId', $scope.extended.Id);
            _appendFormData(formData, 'extendedVersion', $scope.extended.Version);

            return formData;
        };

        $scope.save = function () {
            var formData = _buildFormData();
            if ($scope.isEdit) {
                return core.documentStore.update($routeParams.id, formData).then(function (r) {
                    core.ui.showResult(r, "Document has been updated.", $scope.close);
                }, core.ui.showValidation($scope));
            } else {
                return core.documentStore.create(formData).then(function (r) {
                    core.ui.showResult(r, "Document has been added.", $scope.close);
                }, core.ui.showValidation($scope));
            }
        };

        $scope.confirmDelete = function () {
            core.ui.showConfirmDialog('Confirmation', 'Are you sure you wish to delete document?')
                .then(function(){
                    core.documentStore.delete($routeParams.id).then(function (r) {
                        core.ui.showResult(r, "Document has been deleted successfully.", $scope.close);
                    }, core.ui.showValidation($scope));
                });
        };

        $scope.close = function () {
            $location.path("/manage/documentstore/eula");
        };

        (function () {
            if ($routeParams.id > 0) {
                core.documentStore.get($routeParams.id).then(function (document) {
                    $scope.document = document.document;
                    $scope.document.SizeKb = Math.round($scope.document.Size / 1000, 1);
                    $scope.extended = document.extended;
                });
            }
        })();
    }
]);