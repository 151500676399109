"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("eulaService", ["httpService",
    function (httpService) {
        var service = {};

        service.getEulaContent = function(){
            return httpService.get("/api/user/eula/content" );
        };

        service.accept = function(){
            return httpService.post("/api/user/eula", { accept: true } );
        };

        service.reject = function(){
            return httpService.post("/api/user/eula", { reject: true } );
        };

        return service;
    }]);


/*****************
 CONTROLLERS
 ******************/

portal.controller("SettingsEulaCtrl", ["$scope", "coreService", "authService", "$location",
function ($scope, core, authService, $location) {
    $scope.content = null;
    $scope.loaded = false;

    core.eula.getEulaContent().then(function(data){
        $scope.content = data.Content;
        $scope.loaded = true;
    });

    $scope.accept = function () {
        core.eula.accept()
        .then(function(){

            /*
            authService.principal.claims.EULA_MISSCONFIGURED = false;
            authService.principal.claims.RESTRICT_BUSINESS_USAGE = false;
            authService.principal.claims.ROLE_AUTHORIZED = true;
            authService.principal.claims.BUSINESS_ACTIVITY = true;
            authService.principal.claims.CREDENTIALS_ACTIVITY = true;
            */

            // for some reasons BUSINESS_ACTIVITY change does not reload top menu
            // and i have to perform page refresh
            window.location.href = '/';
        }, core.ui.showValidation($scope));
    };

    $scope.reject = function () {
        core.eula.reject()
        .then(function(){
            authService.signout();
            $location.path("/");
        }, core.ui.showValidation($scope));
    };
}]);
