"use strict";

var portal = angular.module('portal');

/*****************
 SERVICES
 ******************/

portal.factory("decryptionMetricService", ["httpService",
    function (httpService) {
        var service = {};

        service.getReadMetrics = function (filter) {
            return  httpService.post("/api/decryptionMetric/read", filter);
        };

        service.getWriteMetrics = function (filter) {
            return  httpService.post("/api/decryptionMetric/write", filter);
        };

        return service;
    }]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("TokenizationMetricCtrl", ["$scope", "moment", "coreService", "$enums",
    function ($scope, moment, core, $enums) {
        var _defaultFilter = function() {
            return _.merge(core.filter, {
                partner: null,
                client: null,
                metric: 'write',
                displayMetric: 'Tokenizations',
                frequency: 'daily',
                dateFrom: moment().add( -1, 'months').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate()
            });
        };
        $scope.filter = _defaultFilter();

        $scope.rc = {};

        var convertDataToArray = function() {
            var dataList = [];

            for (var i = 0; i < $scope.decryptionMetricChartData[0].values.length; i ++) {
                var singleData = $scope.decryptionMetricChartData[0].values[i].concat();
                singleData[0] = d3.time.format("%x %X")(new Date(singleData[0]));
                singleData[1] = singleData[1].toString();
                singleData[2] = singleData[2].toString();
                singleData[3] = singleData[3].toString();
                if(_.isEqual($scope.filter.frequency.value, "day")) {
                    singleData[0] = singleData[0].slice(0,10);
                }
                dataList.push(singleData);
            }

            return dataList;
        };

        $scope.downloadPDF = function() {
            var headerArray = [
                {text: "Date/Time", style: "header"}, {text: "Amount", style: "header"},
                {text: "Success", style: "header"}, {text: "Failed", style: "header"}
            ];

            var pdfContent = convertDataToArray();
            pdfContent.unshift(headerArray);

            var docDefinition = {
                content: [
                  {
                    text: "Totals"
                  },
                  {
                    style: "dataTable",
                    table: {
                      widths: ["*", "*", "*", "*"],
                      body: pdfContent
                    }
                  }
                ],
                styles: {
                  header: {
                    bold: true,
                    color: "#000",
                    fontSize: 11
                  },
                  dataTable: {
                    color: "#666",
                    fontSize: 10
                  }
                }
              };

              pdfMake.createPdf(docDefinition).download();
        };

        $scope.downloadCSV = function() {
            var a = document.createElement("a");
            var mimeType = "application/octet-stream";
            var fileName = $scope.filter.metric === 'read' ? 'Detokenization Totals.csv' : 'Tokenization Totals.csv';
            var content = Papa.unparse({
                        "fields": ["Date/Time", "Amount", "Success", "Failed"],
                        "data": JSON.stringify(convertDataToArray())
                    });

            if (navigator.msSaveBlob) { // IE10
                navigator.msSaveBlob(new Blob([content], {
                    type: mimeType
                  }), fileName);
            } else if(URL && "download" in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob([content], {
                    type: mimeType
                }));
                a.setAttribute("download", fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
            }
        };

        var renderDecryptionMetricChart = function() {
            $scope.decryptionMetricChartOptions = {
                chart:{
                    type: "multiBarChart",
                    height: 450,
                    showLegend : false,
                    showControls : false,
                    margin : {
                        top: 20,
                        right: 20,
                        bottom: 65
                    },
                    x: function(d){return d[0];},
                    y: function(d){
                        switch($scope.filter.result) {
                            case "total":
                                return d[1];
                            case "success":
                                return d[2];
                            default:
                                return d[3];
                        }
                    },
                    showValues: true,
                    duration: 100,
                    xAxis: {
                        axisLabel: "Date",
                        tickFormat: function(d) {
                            return d3.time.format("%x")(new Date(d));
                        },
                        showMaxMin: false
                    },
                    yAxis: {
                        axisLabel: "Amount",
                        tickFormat: function(d){
                            return d3.format("d")(d);
                        }
                    },
                    useInteractiveGuideline: false,
                    tooltip: {
                        contentGenerator: function (d) {
                            var timeFormat = _.isEqual($scope.filter.frequency, 'daily') ? "%x" : "%x %X";
                            var html = "<center><h4>" + d3.time.format(timeFormat)(new Date(d.data[0])) + "</h4></center>"
                                        + "<ul style='list-style-type:none;'>"
                                        + "<li><span style='font-size:12;'>"
                                        + "Total " + $scope.filter.displayMetric + "</span> : <b>" + d.data[1] + "</b></li>"
                                        + "<li><span style='font-size:12;'>"
                                        + "Successful " + $scope.filter.displayMetric + "</span> : <b>" + d.data[2] + "</b></li>"
                                        + "<li><span style='font-size:12;'>"
                                        + "Failed " + $scope.filter.displayMetric + "</span> : <b>" + d.data[3] + "</b></li>"
                                        + "</ul>";

                            return html;
                        }
                    },
                    color: function(d) {
                        return "#1f77b4";
                    }
                }
            };
        };

        var createDecryptionMetricChartData = function(metrics) {
            for(var i = 0; i < metrics.length; i++) {
                var transactionTime = metrics[i].transactionTime;
                // [as] now we got local time from server in daily mode. subtract time to get correct date. consider better solution
                var time =  _.isEqual($scope.filter.frequency, 'daily') ?
                    transactionTime.substring(0, transactionTime.indexOf('T')) :
                    transactionTime;
                $scope.decryptionMetricChartData[0].values.push(
                    [
                        moment(time).valueOf(),
                        metrics[i].totalCount,
                        metrics[i].successCount,
                        metrics[i].failedCount
                    ]
                );
            }

            return $scope.decryptionMetricChartData;
        };

        $scope.getDecryptionMetric = function() {
            $scope.decryptionMetricChartData = [{"key":"Transactions", "bar": true, values: []}];
            $scope.disableDownload = true;
            $scope.filter.displayMetric = $scope.filter.metric === "write" ? "Tokenizations" : "Detokenizations";
            var func = $scope.filter.metric === "write" ? core.decryptionMetric.getWriteMetrics : core.decryptionMetric.getReadMetrics;
            func($scope.filter)
                .then(function(metrics){
                    return createDecryptionMetricChartData(metrics);
                })
                .then(function() {
                    return renderDecryptionMetricChart();
                })
                .then(function() {
                    $scope.disableDownload = false;
                })
                .catch(function (res) {
                    $scope.decryptionMetricChartData = [{"key":"Transactions", "bar": true, values: []}];
                });
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            $scope.rc = {};
        };

        $scope.$watch("filter.result", function(){
            ($scope.decryptionMetricChartData[0].values.length > 0) && $scope.decryptionMetricChartApi.refresh();
        });

        $scope.$watch("filter.dateTo", function(newVal, oldVal){
            if (!moment(newVal).isSame(oldVal, 'day')) {
                $scope.minDate =  moment(newVal).subtract(3, 'months').toDate();
                $scope.filter.dateFrom = moment($scope.filter.dateFrom).isBefore($scope.minDate, 'day') ?
                    $scope.minDate : $scope.filter.dateFrom;
                $scope.filter.dateFrom = moment($scope.filter.dateFrom).isAfter(newVal, 'day') ?
                    $scope.minDate : $scope.filter.dateFrom;
            }
        });

        (function(){
            $scope.decryptionMetricChartData = [{"key":"Transactions", "bar": true, values: []}];
            $scope.disableDownload = true;
            $scope.hasDecryptionMetrics = ($scope.decryptionMetricChartData[0].values.length > 0);

            $scope.minDate = moment().subtract(3, 'months').toDate();
        })();
    }
]);
