"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/

// todo [as] split user service into two services for authenticated user and not. moved to app.common.js for now
/*
portal.factory("userService", ["httpService", "$q",
    function (httpService, $q) {
        var service = {};
        service.get = function (id) {
            return  httpService.get("/api/user/" + id);
        };
        service.getReadonly = function (id, withDetails) {
            return  httpService.get("/api/user/" + id + '/readonly/' + (withDetails ? 1 : 0));
        };
        service.getRole = function (id) {
            return  httpService.get("/api/user/role/" + id);
        };
        service.create = function (user) {
            return  httpService.put("/api/user/", user);
        };
        service.update = function (user) {
            return  httpService.post("/api/user/", user);
        };
        service.reset = function (record) {
            return httpService.post("/api/user/reset", record);
        };
        service.forgot = function (userName) {
            return httpService.post("/api/user/forgot", {userName: userName});
        };
        service.restore = function (code, password, passwordConfirm) {
            return httpService.post("/api/user/restore", {code: code, password: password, passwordConfirm: passwordConfirm});
        };
        service.find = function (clientId) {
            // todo [as]: check if it should be post
            return httpService.post("/api/user/find/", {clientId: clientId});
        };
        service.search = function (clientId, search, onlyCustodian) {
            return httpService.post("/api/user/search/", {clientId: clientId, search: search, onlyCustodian: onlyCustodian});
        };
        service.notifications = function () {
            // todo [as]: check if it should be post
            return  httpService.post("/api/user/notifications");
        };
        service.dashboardSummary = function () {
            return  httpService.get("/api/dashboard/summary");
        };
        service.dashboardSummaryTransaction = function () {
            return  httpService.get("/api/dashboard/summary-transaction");
        };
        service.disableTfa = function (id) {
            return  httpService.delete("/api/user/" + id + "/tfa");
        };
        return service;
    }]);
*/

/*****************
 CONTROLLERS
 ******************/
portal.controller("UserListCtrl", ["$scope", "$location", "coreService", "$compile",
    function ($scope, $location, core, $compile) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, '/manage/users');
        };

        var r2 = function(number, f, d){
            //return number;
            return '<span id="telsp_' + d.Id + '" class="text-nowrap"></span>' +
                '<div class="hidden"><input id="tel_' + d.Id + '" ng-model="tel" value="'+ number + '" ng-intl-tel-input/></div>';
        };

        var formatNumber = function(row, id){
            var inp = row.find('#tel_' + id);
            var v = inp.intlTelInput("getNumber");
            var countryData = inp.intlTelInput("getSelectedCountryData");
            if (window.intlTelInputUtils) {
                var format = window.intlTelInputUtils.numberFormat.INTERNATIONAL;
                v = window.intlTelInputUtils.formatNumber(v, countryData.iso2, format);
            }
            row.find('#telsp_' + id).text(v);
        };

        $scope.filter = core.filter;

        $scope.dtConfig = {
            ajax: {
                url: "/api/user/list",
                data: function (d) {
                    d.filter = $scope.filter;
                }
            },
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: "Id", render: r1, name: "FirstName", width: "5%"},
                {targets: [1], data: "FirstName", title: "First Name", name: "FirstName", width: "20%"},
                {targets: [2], data: "LastName", title: "Last Name", name: "LastName", width: "20%" },
                {targets: [3], data: "Email", title: "Email", name: "Email", width: "20%" },
                {targets: [4], data: "Phone", render: r2, title: "Phone", name: "Phone", width: "20%" },
                {targets: [5], data: "UserName", title: "User Name", name: "UserName", width: "15%" },
                {targets: [6], data: "UserRoleName", title: "Role", name: "ur.Name", width: "15%" }
            ],
            createLink: "/manage/users/0",
            showTableTools: 'Users',
            reloadEvent: "reloadList",
            fnRowCallback: function( nRow, aData) {
                var row = $(nRow);
                if(!aData.IsActive) {
                    row.addClass("text-muted");
                }

                $compile(row)($scope);

                // copy formatted phone value from input to span
                formatNumber(row, aData.Id);
            }
        };

        $scope.showFilter = !core.auth.isClientAnyUser();

        $scope.allowChangeOnlyActive = function(){
            return !!$scope.filter.partner;
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };

        $scope.clear = function () {
            $scope.filter.partner = null;
            $scope.filter.client = null;
            $scope.filter.status = null;
            $scope.reload();
        };
    }]);

portal.controller("SystemNotificationsCtrl", ["$scope","$rootScope", "$location", "coreService", "$route","$compile", "$uibModal",
    function ($scope, $rootScope, $location, core, $route, $compile, $modal) {

        $scope.filter = core.filter;

        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }

        var r1 = function(id){
            return '<input class="notification" ng-checked="checkAll" type="checkbox"  id="'+id+'" />';
        };

        var r2 = function (id) {
            var btn = '<a class="glyphicon glyphicon-eye-open" ng-click="viewNotification('+id+')"></a>';
            return btn;
        };

        $scope.viewNotification = function (id) {
            var notification = _.find($scope.notifications, { id });

            let n = {};
            n.id       = notification['id'];
            n.icon     = notification['icon'];
            n.title    = notification['title'];
            n.message  = notification['message'];

            var modalInstance = $modal.open({
                backdrop: 'static',
                keyboard: false,
                templateUrl: "/partials/manage/systemnotifications.modalpreview.html",
                controller: 'NotificationDetailCtrl',
                resolve: { notification : n }
            });
        };

        $scope.dtSystemNotifications = {
            serverSide: false,
            ajax: {
                url: "/api/manage/systemnotifications",
                dataSrc:"",
                data: function (d) {
                    d.filter = $scope.filter;
                    d.status = $scope.status;
                }
            },
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0],  data: "id", name: "id", title: "id", visible: false},
                {targets: [1],  render: r1, sortable: false, data: "id", name: "dateRead", title: "<input type='checkbox' id='select-all' ng-model='checkAll' title='Select All'/>", width: "1%"},
                {targets: [2],  sortable: true, data: "title", name: "title", title: "Notification Title"},
                {targets: [3],  sortable: true, data: "date", name: "date", title: "Date"},
                {targets: [4],  render: r2, sortable: false, data: "id", name: "id", title: "Read"}
            ],
            "createdRow": function ( row) {
                $compile(row)($scope);
            },
            searching:false,
            bInfo : false,
            paging: false,
            drawCallback: function() {
                core.user.notificationsAvailable()
                    .then(function (n) {
                        $scope.notifications = n;
                    });

                $('#select-all').unbind('click'); // prevent multiple event bindings
                $compile($('#select-all'))($scope);

            }
        };

        var _markNotifications = function(){
            var readTime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            var notificationIds = getSelectedNotifications();
            if(notificationIds.length > 0) {
                core.user.markNotifications(notificationIds, readTime, 'hide')
                    .then(function () {
                        $route.reload();
                    });

            }
        };

        $scope.dismissNotifications = function(){
            _markNotifications();
        }

        var getSelectedNotifications = function () {
            var selectedArray = [];

            $(".notification:checked").each(function() {
                selectedArray.push($(this).attr('id'));
            });

            var selectedNotifications;

            selectedNotifications = selectedArray.join(',');
            return selectedNotifications.toString();

        };

    }]);

portal.controller("UserDetailsCtrl", ["$scope", "$location", "$routeParams", "coreService", "ROLES",
    function ($scope, $location, $routeParams, core, ROLES) {
        $scope.clients = null;
        $scope.user = {
            SendWelcomeEmail: parseInt($routeParams.id) === 0,
            IsActive: 1,
            AuthorizationType: 'None'
        };
        $scope.isEdit = $routeParams.id > 0;
        $scope.showPasswordLock = $scope.isEdit && core.auth.isSystemAnyUser();
        $scope.canChangeRole = false;

        $scope.canEditSecurity = false;

        $scope.refreshPrincipal = function () {
            if(core.auth.principal.id == $routeParams.id){
                core.auth.principal.userName = $scope.user.UserName;
            }
        };

        $scope.generateHmacKey = function(){
            $scope.user.SecretHmac = core.ui.generateHmacKey();
        };

        $scope.save = function () {
            console.log("saving user: ", $scope.user);

            switch($scope.user.AuthorizationType){
                case "Hmac":
                    $scope.user.Secret = $scope.user.SecretHmac;
                    break;
                case "Rsa":
                    $scope.user.Secret = $scope.user.SecretRsa;
                    break;
                default:
                    $scope.user.Secret = null;
                    break;
            }

            if ($scope.user.Id > 0) {
                return core.user.update($scope.user).then(function (r) {
                    core.ui.showResult(r, "User has been updated.");
                    $scope.refreshPrincipal();
                    $scope.close();
                }, core.ui.showValidation($scope));
            } else {
                return core.user.create($scope.user).then(function (r) {
                    core.ui.showResult(r, "User has been added.");
                    $scope.close();
                }, core.ui.showValidation($scope));
            }
        };

        $scope.close = function () {
            $location.path("/manage/users");
        };

        $scope.changeRole = function () {
            $scope.showPartners = (core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser()) &&
                _.includes([ROLES.partnerUser, ROLES.partnerSupervisor, ROLES.clientAdmin, ROLES.clientUser], $scope.user.UserRoleId);
            $scope.showClients = (core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser()) &&
                _.includes([ROLES.clientAdmin, ROLES.clientUser], $scope.user.UserRoleId);

            if ($scope.showClients) {
                _loadClients();
            }

            refreshCanEditSecurity();
        };

        $scope.allowDisableTfa = function(){
            return $scope.user.TfaStatus == 2 /*on*/;
        };

        $scope.disableTfa = function () {
            core.user.disableTfa($scope.user.Id)
                .then(function(res){
                    if(res.disabled){
                        $scope.user.TfaStatus = 0 /*off*/;
                        core.ui.success("Two-factor authentication deactivated.");
                    }
                });
        };

        $scope.loadClients = function () {
            _loadClients();
        };

        var _loadClients = function () {
            if ($scope.user.partner) {
                core.client.findByPartner($scope.user.partner)
                    .then(function (c) {
                        $scope.clients = c;
                    });
            } else {
                $scope.clients = [];
            }
        };

        var refreshCanEditSecurity = function(){
            $scope.canEditSecurity = (core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser()) &&
                _.includes([ROLES.systemAdmin, ROLES.systemUser, ROLES.partnerUser, ROLES.partnerSupervisor], $scope.user.UserRoleId);

            $scope.user.AuthorizationType = $scope.canEditSecurity ? $scope.user.AuthorizationType : 'None';
        };

        var refreshCanEditRole = function(){
            $scope.canChangeRole = core.auth.isSystemAnyUser() || core.auth.isPartnerSupervisor() || core.auth.isClientAdmin() ||
                (
                    core.auth.isPartnerUser() && (
                        !$scope.isEdit ||
                        _.includes(_.concat([ROLES.partnerUser], ROLES.clientAnyUser), $scope.user.UserRoleId)
                        )
                );

            $scope.user.UserRoleReadOnly = $scope.user.UserRoleId ? core.ui.getRoleName($scope.user.UserRoleId) : undefined;
        };

        var refreshAvailableRoles = function () {
            var roles = [];
            if (core.auth.isSystemAdmin()) {
                roles.push({id: ROLES.systemAdmin, title: core.ui.getRoleName(ROLES.systemAdmin)});

            }
            if (core.auth.isSystemAdmin() || (core.auth.isSystemUser() && $scope.isEdit)) {
                roles.push({id: ROLES.systemUser, title: core.ui.getRoleName(ROLES.systemUser)});
            }

            if(core.auth.isSystemAnyUser() || core.auth.isPartnerSupervisor()){
                roles.push({id: ROLES.partnerSupervisor, title: core.ui.getRoleName(ROLES.partnerSupervisor)});
            }
            if(core.auth.isSystemAnyUser() || core.auth.isPartnerSupervisor() || core.auth.isPartnerUser()){
                roles.push({id: ROLES.partnerUser, title: core.ui.getRoleName(ROLES.partnerUser)});
            }

            if (core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser() || core.auth.isClientAdmin()) {
                roles.push({id: ROLES.clientAdmin, title: core.ui.getRoleName(ROLES.clientAdmin)});
                roles.push({id: ROLES.clientUser, title: core.ui.getRoleName(ROLES.clientUser)});
            }

            $scope.roles = roles;
        };

        $("#phone").on("countrychange", function(e, countryData) {
            // force number update in model
            var number = $("#phone").intlTelInput("getNumber");
            $scope.user.Phone = number;
        });

        (function () {

            if ($routeParams.id > 0) {
                core.user.get($routeParams.id).then(function (u) {
                    $scope.user = u;
                    $scope.user.partner = u.PartnerId;
                    $scope.user.client = u.ClientId;

                    switch($scope.user.AuthorizationType){
                        case "Hmac":
                            $scope.user.SecretHmac = $scope.user.Secret;
                            break;
                        case "Rsa":
                            $scope.user.SecretRsa = $scope.user.Secret;
                            break;
                        default:
                            break;
                    }

                    // if this is client role - load partner id
                    if(_.includes([ROLES.clientAdmin, ROLES.clientUser], $scope.user.UserRoleId)){
                        core.client.get($scope.user.ClientId)
                            .then(function(c){
                                $scope.user.partner = c.DirectPartnerId;
                                $scope.changeRole();
                            });
                    }else {
                       $scope.changeRole();
                    }

                    refreshCanEditSecurity();

                    refreshCanEditRole();

                    refreshAvailableRoles();
                });
            } else {
                refreshCanEditRole();

                refreshAvailableRoles();
            }
        })();
    }
]);
