"use strict";
var portal = angular.module("portal");

/*****************
 CONTROLLERS
 ******************/
portal.controller("CarrierListCtrl", ["$scope", "$location", "coreService",
    function ($scope, $location, core) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, '/manage/carriers');
        };
        $scope.dtConfig = {
            serverSide: false,
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: "Id", render: r1, name: "FirstName", width: "5%"},
                {targets: [1], data: "Name", title: "Name", name: "Name", width: "20%"},
                {targets: [2], data: "TrackingUrl", title: "Tracking Url", name: "TrackingUrl", width: "60%" },
                {targets: [3], data: "IsActive", render: core.dt.renderYesNoFromBool, title: "Active", name: "IsActive", width: "5%" }
            ],
            createLink: "/manage/carriers/0",
            fnRowCallback: function( nRow, aData) {
                if(!aData.IsActive) {
                    $(nRow).addClass("text-muted");
                }
            }
        };

        (function () {
            core.dictionary.getCarriers()
                .then(function(d){
                    core.dt.refresh('#dtCarriers', d);
                });
        })();

    }]);

portal.controller("CarrierDetailsCtrl", ["$scope", "$location", "$routeParams", "coreService", "ROLES",
    function ($scope, $location, $routeParams, core, ROLES) {
        $scope.carrier = {};
        $scope.isEdit = $routeParams.id > 0;

        $scope.save = function () {
            console.log("saving carrier: ", $scope.carrier);
            if ($scope.isEdit) {
                return core.dictionary.updateCarrier($scope.carrier).then(function (r) {
                    core.ui.showResult(r, "Carrier has been updated.");
                    $scope.close();
                }, core.ui.showValidation($scope));
            } else {
                return core.dictionary.createCarrier($scope.carrier).then(function (r) {
                    core.ui.showResult(r, "Carrier has been added.");
                    $scope.close();
                }, core.ui.showValidation($scope));
            }
        };

        $scope.close = function () {
            $location.path("/manage/carriers");
        };

        (function () {
            if ($scope.isEdit) {
                core.dictionary.getCarrier($routeParams.id).then(function (c) {
                    $scope.carrier = c;
                });
            }
        })();
    }
]);