"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("virtualTerminalService", ["httpService", "$q",
    function (httpService, $q) {
        var service = {};

        service.tokenize = function (data) {
            return httpService.post("/api/virtualTerminal/tokenize", data);
        };

        service.detokenize = function (data) {
            return httpService.post("/api/virtualTerminal/detokenize", data);
        };

        service.read = function (data) {
            return httpService.post("/api/virtualTerminal/read", data);
        };

        service.isAvailable = function () {
            return httpService.get("/api/virtualTerminal/available")
                .then(function(r) {
                    return r.available;
                });
        };

        return service;
    }]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("VirtualTerminalCtrl", ["$scope", "$location", "$compile", "$enums", "coreService",
    function ($scope, $location, $compile, $enums, core) {

        $scope.options = {
            partnerId: undefined,
            clientId: core.auth.isClientAnyUser() ? core.auth.principal.clientId : undefined
        };

        $scope.loading = false;


        $scope.clients = $scope.templates = [];

        $scope.config = undefined;
        $scope.response = undefined;

        $scope.showPartners = $scope.showClients = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

        $scope.changePartner = function () {
            _loadClients();
        };

        $scope.changeClient = function () {
            _loadTemplates();
        };

        $scope.changeTemplate = function () {
            _loadTemplate();
        };

        $scope.tokenize = function (omit) {
            _beginCall();

            var data = {
                omit: $scope.options.omit,
                templateId: $scope.options.templateId,
                values: _.map($scope.config.fields, function(f) {
                    return { name: f.name, value: _.isEmpty(f.tokenizeValue) ? null : f.tokenizeValue };
                })
            };
            return core.virtualTerminal.tokenize(data)
                .then(_renderTokenizeResponse, core.ui.showValidation($scope))
                .finally (_finalizeCall);
        };

        $scope.detokenize = function () {
            _beginCall();

            var data = {
                bfid: $scope.options.bfid,
                values: _.map($scope.config.fields, function(f) {
                    return { name: f.name, value: _.isEmpty(f.detokenizeValue) ? null : f.detokenizeValue };
                })
            };
            return core.virtualTerminal.detokenize(data)
                .then(_renderDetokenizeResponse, core.ui.showValidation($scope))
                .finally (_finalizeCall);
        };

        $scope.read = function () {
            _beginCall();

            var data = {
                bfid: $scope.options.bfid
            };
            return core.virtualTerminal.read(data)
                .then(_renderTokenizeResponse, core.ui.showValidation($scope))
                .finally (_finalizeCall);
        };

        var _beginCall = function () {
            $scope.loading = true;
            $scope.errors = null;
            $scope.response = null;
        };

        var _finalizeCall = function () {
            $scope.loading = false;
        };

        var _renderTokenizeResponse = function(r) {
            $scope.response = r;
            $scope.options.bfid = r.bfid;
            _.each($scope.config.fields, function(f) {
                var field = _.find(r.values, {name: f.name});
                if(field) {
                    f.detokenizeValue = field.value;
                }

                if(field || $scope.options.omit) {
                    f.tokenizeValue = null;
                }
            });
        };

        var _renderDetokenizeResponse = function(r) {
            $scope.response = r;

            _.each($scope.config.fields, function(f) {
                var field = _.find(r.values, {name: f.name});
                if(field) {
                    f.tokenizeValue = field.value;
                    f.detokenizeValue = null;
                }
            });
        };


        var _loadClients = function () {
            if ($scope.showClients && $scope.options.partnerId) {
                core.client.findByPartner($scope.options.partnerId)
                    .then(function (c) {
                        $scope.clients = c;
                    });
            } else {
                $scope.clients = [];
            }
        };

        var _loadTemplates = function () {
            if ($scope.options.clientId) {
                core.template.findByClient($scope.options.clientId)
                    .then(function (t) {
                        $scope.templates = _.orderBy(t, ['Favorite', 'Active', 'Name'], ['desc', 'desc', 'asc']);
                    });
            } else {
                $scope.templates = [];
            }
        };

        var _loadTemplate = function () {
            if($scope.options.templateId) {
                core.template.get($scope.options.templateId)
                    .then(function (t) {
                        $scope.config = JSON.parse(t.Configuration);
                    });
            } else {
                $scope.config = null;
            }
        };

        (function(){
            _loadTemplates();
        })();
    }]);
