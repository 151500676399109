"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("tfaService", ["httpService", "$q", "uuid",
    function (httpService, $q, uuid) {
        var service = {};

        var properties = {
            otpauth_url: null,
            base32: null,
            token: null,
            verified: false,
            codes: [],
            tfaRememberDevice: 0
        };

        service.getProperties = function(){
            return properties;
        };

        service.requestOtpAuth = function () {
            properties.otpauth_url = null;
            properties.base32 = null;
            properties.token = null;
            properties.verified = false;
            properties.tfaRememberDevice = 0;
            return  httpService.post("/api/tfa/otpauth")
                .then(function (secret) {
                    properties.otpauth_url = secret.otpauth_url;
                    properties.base32 = secret.base32;
                    /*return properties;*/
                });
        };

        service.validateOtp = function (token) {
            properties.token = null;
            properties.verified = false;
            var options = { token: token, base32: properties.base32 };
            return  httpService.post("/api/tfa/otp/validate", options)
                .then(function(res){
                    properties.token = token;
                    properties.verified = res.verified;
                    return res;
                });
        };

        service.resetCodes = function(){
            // clean old array while array reference stays the same
            while (this.length) {
                properties.codes.pop();
            }
        };

        service.generateCodesCore = function(){

            // generate new codes
            var codes = [];
            for(var i = 0; i < 10; i++){
                var code = uuid.v4().substring(9, 18);
                codes.push(code);
            }

            // it is not quarantined the middle of guid will be unique
            // so we will remove duplicates
            codes = _.uniq(codes);

            return $q.resolve(codes);
        };

        service.generateCodes = function(){

            return service.generateCodesCore()
                .then(function(codes){
                    service.resetCodes();

                    // fill properties.codes with values.
                    // The reference to properties.codes stays the same !!!
                    for(var i = 0; i < codes.length; i++){
                        properties.codes[i] = codes[i];
                    }

                    return true;
                });

        };

        service.activate = function(){
            var data = {
                base32: properties.base32,
                codes: properties.codes,
                tfaRememberDevice: properties.tfaRememberDevice
            };
            return  httpService.post("/api/tfa/activate", data)
                .then(function(){
                    // reset
                    properties.otpauth_url = null;
                    properties.base32 = null;
                    properties.token = null;
                    properties.verified = false;
                    properties.tfaRememberDevice = 0;
                    service.resetCodes();
                });
        };

        service.disable = function(){
            return  httpService.delete("/api/tfa");
        };

        service.status = function(){
            return  httpService.get("/api/tfa/status");
        };

        service.loadCodes = function(){
            return httpService.get("/api/tfa/codes");
        };

        service.update = function(data){
            return  httpService.post("/api/tfa", data);
        };

        service.regenerateCodesAndStore = function(){
            return service.generateCodesCore()
                .then(function(codes){
                    return httpService.post("/api/tfa/codes", { codes: codes })
                        .then(function(){
                            return codes;
                        });
                });
        };

        return service;
    }]);


/*****************
 CONTROLLERS
 ******************/

// todo [ds]: add sudo mode

portal.controller("SettingsSecurityCtrl", ["$scope", "$rootScope", "coreService",
    function ($scope, $rootScope, core) {
        $scope.enabled = false;
        $scope.tfaRememberDevice = undefined;
        $scope.loading = true;

        (function () {
            $scope.loading = true;
            core.tfa.status().then(function (res) {
                $scope.enabled = res.status == 2 /*on*/;
                $scope.tfaRememberDevice = res.tfaRememberDevice;
            })
            .finally(function(){
                $scope.loading = false;
            });
        })();
    }
]);

portal.controller("SettingsSecurityTfaAppCtrl", ["$scope", "$rootScope", "coreService", "$location",
    function ($scope, $rootScope, core, $location) {
        $scope.secret = core.tfa.getProperties();
        $scope.initialized = false;
        $scope.loading = true;
        $scope.token = null;

        $scope.showCode = function () {
            core.ui.modal("Your two-factor secret", $scope.secret.base32);
        };

        $scope.continueButtonEnabled = function(){
            return !$scope.loading;
        };

        $scope.continueButtonShown = function(){
            return $scope.initialized;
        };

        $scope.continue = function(){
            if($scope.tfaAppForm.$valid) {
                core.tfa.validateOtp($scope.token)
                    .then(function (res) {
                        if (res.verified) {
                            $location.path("/settings/two_factor_authentication/verify");
                        }
                        else {
                            core.ui.error("Two-factor secret verification failed. Please verify your secret and try again.");
                            _requestOtpAuth();
                        }
                    })
                    .finally(function () {
                        $scope.token = null;
                        $scope.tfaAppForm.$setPristine();
                    });
            }
        };

        var _requestOtpAuth = function(){
            $scope.loading = true;
            return core.tfa.requestOtpAuth()
                .then(function(){
                    $scope.loading = false;
                });
        };

        (function () {
            _requestOtpAuth().then(function () {
                $scope.initialized = true;
            });
        })();
    }
]);

portal.controller("SettingsSecurityTfaVerifyCtrl", ["$scope", "$rootScope", "coreService", "$location",
    function ($scope, $rootScope, core, $location) {
        $scope.secret = core.tfa.getProperties();
        $scope.clipboard = new Clipboard('.clipboard');
        $scope.codesStored = false;

        $scope.continueButtonEnabled = function(){
            return $scope.codesStored;
        };

        $scope.download = function(){
            var content = $scope.secret.codes.join("\r\n");
            var blob = new Blob([content], { type:"text/plain" });
            var downloadLink = angular.element('<a></a>');
            downloadLink.attr('href',window.URL.createObjectURL(blob));
            downloadLink.attr('download', 'portal-recovery-codes.txt');
            downloadLink[0].click();

            $scope.codesStored = true;
        };

        $scope.print = function(){
            window.print();

            $scope.codesStored = true;
        };

        $scope.copy = function(){
            $scope.codesStored = true;
        };

        $scope.continue = function(){
            core.tfa.activate()
                .then(function(){
                    /*core.auth.afterTfaActivated();*/
                    core.ui.success("Two-factor authentication activated.")
                    // we are changing TFA state
                    // in the same time we have a claims
                    // related to TFA
                    // on the server we will update data in the session
                    // so we have to reload the client
                    // to update claims
                    document.location.href = "/settings/two_factor_authentication/configure";
                });
        };


        (function () {
            core.tfa.generateCodes();
        })();

        $scope.$on('$destroy', function () {
            if($scope.clipboard) {
                $scope.clipboard.destroy();
            }
        });

    }
]);

portal.controller("SettingsSecurityTfaConfigureCtrl", ["$scope", "$rootScope", "coreService", "$location",
    function ($scope, $rootScope, core, $location) {
        $scope.tfaRememberDevice = 0;
        $scope.loading = true;

        $scope.blockTfaDisable = function(){
            return core.auth.principal.claims.TFA_ENFORCED;
        };

        $scope.disable = function(){
            core.tfa.disable()
                .then(function(){
                    core.ui.success("Two-factor authentication deactivated.")
                    $location.path("/settings/security");
                });
        };

        $scope.update = function(){
            core.tfa.update({tfaRememberDevice: $scope.tfaRememberDevice})
                .then(function(){
                    core.ui.success("Two-factor settings have been updated.")
                });
        };

        (function () {
            $scope.loading = true;
            core.tfa.status().then(function (res) {
                $scope.tfaRememberDevice = res.tfaRememberDevice;
            })
            .finally(function(){
                $scope.loading = false;
            });
        })();
    }
]);

portal.controller("SettingsSecurityTfaRecoveryCodesCtrl", ["$scope", "$rootScope", "coreService", "$location",
    function ($scope, $rootScope, core, $location) {
        $scope.codes = [];
        $scope.loading = true;
        $scope.clipboard = new Clipboard('.clipboard');


        $scope.download = function(){
            var content = $scope.codes.join("\r\n");
            var blob = new Blob([content], { type:"text/plain" });
            var downloadLink = angular.element('<a></a>');
            downloadLink.attr('href',window.URL.createObjectURL(blob));
            downloadLink.attr('download', 'portal-recovery-codes.txt');
            downloadLink[0].click();
        };

        $scope.print = function(){
            window.print();
        };

        $scope.regenerate = function(){
            core.tfa.regenerateCodesAndStore()
                .then(function(codes){
                    $scope.codes = codes;
                    core.ui.success("Two-factor authentication recovery codes regenerated.")
                });
        };

        (function () {
            $scope.loading = true;
            core.tfa.loadCodes()
                .then(function(codes){
                    $scope.codes = codes;
                })
                .finally(function(){
                    $scope.loading = false;
                });
        })();

        $scope.$on('$destroy', function () {
            if($scope.clipboard) {
                $scope.clipboard.destroy();
            }
        });

    }
]);

