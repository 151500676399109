"use strict";
var portal = angular.module("portal");

portal.factory("injectKeyService", [
    "httpService",
    "$q",
    function (httpService, $q) {
        var service = {};
        service.get = function (id) {
            return httpService.get("/api/injectKeys/" + id);
        };

        service.create = function (record) {
            return httpService.post("/api/injectKeys/", record);
        };

        service.update = function (record) {
            return httpService.put("/api/injectKeys/" + record.Id, record);
        };

        return service;
    },
]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("InjectKeyListCtrl", [
    "$scope",
    "coreService",
    function ($scope, core) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, "/manage/injectKeys");
        };
        $scope.dtConfig = {
            ajax: {
                url: "/api/injectKeys/list",
            },
            columnDefs: [
                { targets: [0], sortable: false, searchable: false },
                { targets: [0], data: "Id", render: r1, name: "k.Name", width: "5%" },
                { targets: [1], data: "Name", title: "Name", name: "k.Name", width: "25%" },
                { targets: [2], data: "Status", title: "Status", name: "k.Status", width: "25%" },
                { targets: [3], data: "PartnerName", title: "Partner", name: "p.Name", width: "25%" },
            ],
            createLink: "/manage/injectKeys/0",
        };
    },
]);

portal.controller("InjectKeyDetailsCtrl", [
    "$scope",
    "$location",
    "$routeParams",
    "$enums",
    "coreService",
    function ($scope, $location, $routeParams, $enums, core) {
        $scope.injectKey = {
            Status: $enums.InjectKeyStatus.available,
        };

        $scope.allowUpdate = true;

        $scope.save = function () {
            if ($scope.injectKey.Id > 0) {
                var innerSave = function () {
                    return core.injectKey.update($scope.injectKey).then(function (r) {
                        core.ui.showResult(r, "Inject Key been update successfully.", $scope.close);
                    }, core.ui.showValidation($scope));
                };

                var message =
                    "Once you assign partner to the key, it will be activated and can no longer be modified. If this partner already has an active FPT key assigned, the currently assigned key will be permanently deactivated. Are you sure you want to continue?";
                return $scope.injectKey.PartnerId
                    ? core.ui.showConfirmDialog("Confirmation", message).then(function () {
                          innerSave();
                      })
                    : innerSave();
            } else {
                return core.injectKey.create($scope.injectKey).then(function (r) {
                    core.ui.showResult(r, "Inject Key has been added.", $scope.close);
                }, core.ui.showValidation($scope));
            }
        };

        $scope.close = function () {
            $location.path("/manage/injectKeys");
        };

        (function () {
            if ($routeParams.id > 0) {
                core.injectKey.get($routeParams.id).then(function (ik) {
                    $scope.injectKey = ik;
                    $scope.allowUpdate = $scope.injectKey.Status == $enums.InjectKeyStatus.available;
                });
            }
        })();
    },
]);
