"use strict";

var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("notificationService", ["httpService",
    function (httpService) {
        var service = {};

        service.delete = function(notificationId){
            return  httpService.delete("/api/notification/" + notificationId);
        };

        service.get = function(notificationId){
            return  httpService.get("/api/notification/" + notificationId);
        };

        service.update = function(notifcationId, data){
            return  httpService.put("/api/notification/" + notifcationId, data);
        };

        service.create = function (data) {
            return  httpService.post("/api/notification", data);
        };

        return service;
    }
]);

portal.controller("NotificationListCtrl", ["$scope", "coreService",
    function ($scope, core) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, '/manage/notificationManagement');
        };
        $scope.dtConfig = {
            ajax: {
                url: "/api/notification/list"
            },
            sorting: [3, 'desc'],
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [1], render: function ( data, type, row ) {
                        return data.length > 120 ?
                            data.substr( 0, 120 ) +'…' :
                            data;
                    }
                },
                {targets: [0], data: "Id", render: r1, name: "Id", width: "5%"},
                {targets: [1], data: "Title", title: "Title", name: "Title", width: "50%"},
                {targets: [2], data: "DateActive", title: "Date Active", render: core.dt.renderDateWithoutTime, name: "DateActive", width: "15%" },
                {targets: [3], data: "DateExpired", title: "Date Expired", render: core.dt.renderDateWithoutTime, name: "DateExpired", width: "15%" }
            ],
            createLink: "/manage/notificationManagement/0",
            fnRowCallback: function( nRow, aData) {
                if(!aData.IsActive) {
                    $(nRow).addClass("text-muted");
                }
            }
        };
    }
]);

portal.controller("NotificationDetailsCtrl", ["$scope", "$location", "$routeParams", "coreService",  "moment", "$uibModal","$sce",
    function ($scope, $location, $routeParams, core, moment, $modal) {
        $scope.isEdit = $scope.allowDelete = parseInt($routeParams.id) > 0;
        $scope.notification = {};
        $scope.maxCharInputLength = 5000;
        $scope.editorModules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image'],
                ['video']
            ]
        };

        $scope.close = function () {
            $location.path("/manage/notificationManagement");
        };

        $scope.save = function () {
            var timeFormat = "YYYY-MM-DD HH:mm:ss";
            $scope.notification.DateActive = moment($scope.notification.DateActive).format(timeFormat);
            $scope.notification.DateExpired = moment($scope.notification.DateExpired).format(timeFormat);

            var selectedPartners = _.filter($scope.rootAndSubPartners, function(p) { return p.Selected === 1; });

            $scope.notification.PartnerIds = selectedPartners.map(function(p) {
                return p.Id;
            }).join(',');

            if ($scope.notification.Id > 0) {
                return core.notification.update($routeParams.id, $scope.notification).then(function (r) {
                    core.ui.showResult(r, "Notification has been updated.", $scope.close);
                }, core.ui.showValidation($scope));
            } else {
                return core.notification.create($scope.notification).then(function (r) {
                    core.ui.showResult(r, "Notification has been added.", $scope.close);
                }, core.ui.showValidation($scope));
            }
        };

        $scope.confirmDelete = function () {
            core.ui.showConfirmDialog('Confirmation', 'Are you sure you wish to delete the notification?')
                .then(function(){
                        core.notification.delete($scope.notification.Id).then(function (r) {
                            core.ui.showResult(r, "The notification has been deleted successfully.", $scope.close);
                        }, core.ui.showValidation($scope));
                    }
                );
        };

        $scope.hstep = $scope.mstep = 1;

        $scope.openDateActive = function() {
            $scope.dateActiveOpened = true;
        };

        $scope.openDateExpired = function() {
            $scope.dateExpiredOpened = true;
        };

        var _reloadPartnerConfigs = function() {
            return core.partner.all()
                .then(function(partners) {
                    $scope.rootAndSubPartners = partners;
                            var partnerIds = $scope.notification.PartnerIds.split(',').map(Number);

                            _.each($scope.rootAndSubPartners, function(p) {
                                if(_.includes(partnerIds,p.Id)) {
                                    p.Selected = 1;
                                }
                            });
                });
        };

        $scope.selectAllPartners = function() {
            _.each($scope.rootAndSubPartners, function(p) {
                p.Selected = $scope.check;
            });
        }

        $scope.setIconValue = function (iconValue) {
            $scope.notification.Icon = iconValue;
        }

        $scope.previewNotification = function () {
            let n = {};
            n.icon     = $scope.notification.Icon;
            n.title    = $scope.notification.Title;
            n.message  = $scope.notification.Body;

            var modalInstance = $modal.open({
                backdrop: 'static',
                keyboard: false,
                templateUrl: "/partials/manage/systemnotifications.modalpreview.html",
                controller: 'NotificationDetailCtrl',
                resolve: { notification : n }
            });
        };

        // initilally loaded， get list of notifications.
        var _load = function(){
            if ($routeParams.id > 0) {
                core.notification.get($routeParams.id).then(function (notification) {
                    $scope.notification = notification;
                    $scope.notification.DateActive = moment($scope.notification.DateActive).toDate();
                    $scope.notification.DateExpired = moment($scope.notification.DateExpired).toDate();
                });
            } else {
                $scope.notification.DateActive = moment().toDate();
                $scope.notification.DateExpired = moment($scope.notification.DateActive).add(1, "days").toDate();
            }
        };

        (function () {
            if(core.auth.isAuthenticated()) {
                core.user.notifications()
                    .then(function (n) {
                        $scope.notifications = n;
                        $rootScope.notificationCountNo = n.length;
                    });
            }

            _load();
            _reloadPartnerConfigs();
        })();
    }
]);

portal.controller("NotificationDetailCtrl", ["$scope", "$uibModalInstance", "notification","$sce","coreService","$rootScope",
    function ($scope, $modalInstance, notification, $sce, core, $rootScope) {
        $scope.notification = notification;
        $scope.cancel = function (preview) {
            if(preview !== 1) {
                var readTime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
                core.user.markNotifications(String(notification.id),readTime, 'read');
                $rootScope.notificationCountNo -=1;
            }
            $modalInstance.dismiss('cancel');
        };
    }
])
    .filter('html', ['$sce', function ($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    }]);
