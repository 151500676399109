"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("emailTemplateService", ["httpService", "$q",
    function (httpService, $q) {
        var service = {};

        service.types = function () {
            return  httpService.get("/api/emailtemplate/list/types");
        };

        service.delete = function (id) {
            return  httpService.delete("/api/emailtemplate/" + id);
        };

        service.update = function (data) {
            return  httpService.post("/api/emailtemplate", data);
        };

        service.create = function (data) {
            return  httpService.put("/api/emailtemplate", data);
        };

        service.getDetails = function(id){
            return  httpService.get("/api/emailtemplate/" + id);
        };

        return service;
    }]);


/*****************
 CONTROLLERS
 ******************/

portal.controller("EmailTemplateListCtrl", ["$scope", "$location", "coreService",
    function ($scope, $location, core) {
        var r1 = function (id, opt, data) {
            var actions = "";
            actions += core.dt.renderViewEdit(id, '/manage/emails');

            return actions;
        };

        var r2 = function (v, opt, data) {
            return data.Title + " ["+data.Type + "]";
        };

        var r3 = function (v, opt, data) {
            var actions = "";

            if($scope.filter.partner && !data.PartnerName) {
                actions += "<a class=\"glyphicon glyphicon-log-out\" href=\"/manage/emails/" +
                    data.Id +
                    "?overrideFor=" +
                    $scope.filter.partner +
                   "\" title=\"Override\"></a>";
            }

            if(data.PartnerName) {
                actions += "<button title='Remove' class='btn btn-default' onclick='angular.element(\".email-template-list\").scope().remove(" + data.Id + ", \"" + data.PartnerName + "\", \"" + data.Title + "\")'>" +
                    "<i class='glyphicon glyphicon-remove'></i>" +
                    "</button>";
            }

            return actions;
        };

        $scope.filter = core.filter;

        $scope.dtConfig = {
            bFilter: false,
            ajax: {
                url: "/api/emailtemplate/list",
                data: function (d) {
                    d.filter = $scope.filter;
                }
            },
            columnDefs: [
                { targets: [0, 4], sortable: false, searchable: false },
                { targets: [0], data: "Id", render: r1, name: "et.Title", width: "5%" },
                { targets: [1], data: "Title", render: r2, title: "Title", name: "et.Title", width: "35%" },
                { targets: [2], data: "Subject", title: "Subject", name: "et.Subject" },
                { targets: [3], data: "PartnerName", title: "Partner", name: "p.Name", width: "15%" },
                { targets: [4], data: "Id", render: r3, name: "et.Id", width: "5%" }
            ],
            reloadEvent: "reloadList",
            fnRowCallback: function( nRow, aData) {
                if(!aData.PartnerName) {
                    $(nRow).addClass("text-muted");
                }
            }
        };

        $scope.types = [];

        $scope.reload = function(){
            $scope.$emit("reloadList");
        };

        $scope.clear = function () {
            $scope.filter.partner = null;
            $scope.filter.type = '';
            $scope.reload();
        };

        $scope.override = function(type){
            $location.path("/manage/emails");//?type=" + type + "&partner=" + $scope.filter.partner);
        };

        $scope.remove = function(id, partner, title ){
            if(confirm('Are you sure you want to delete "' + title + "\" email template for partner \"" + partner + "\"?")) {
                core.emailTemplate.delete(id).then(function(){
                    core.ui.success('Email template "' + title + "' deleted.");
                    $scope.reload();
                });
            }
        };

        (function(){
            core.emailTemplate.types().then(function(d){
               $scope.types = d;
            });
        })();
    }]);

portal.controller("EmailTemplateDetailsCtrl", ["$scope", "$location", "coreService", "$routeParams",
    function ($scope, $location, core, $routeParams) {
        var createMode = parseInt($routeParams.overrideFor) > 0;
        $scope.instance = {};

        $scope.close = function () {
            $location.path("/manage/emails").search({overrideFor: null, partnerName: null});
        };

        $scope.save = function(){
            if (!createMode) {
                return core.emailTemplate.update($scope.instance).then(function (r) {
                    core.ui.showResult(r, "Email template has been updated.", $scope.close);
                }, core.ui.showValidation($scope));
            } else {
                return core.emailTemplate.create($scope.instance).then(function (r) {
                    core.ui.showResult(r, "Email template created.", $scope.close);
                }, core.ui.showValidation($scope));
            }
        };

        (function () {
            core.emailTemplate.getDetails($routeParams.id).then(function (d) {
                $scope.instance = d;
                if(createMode){
                    delete $scope.instance.Id;
                    delete $scope.instance.Version;
                    $scope.instance.PartnerId = parseInt($routeParams.overrideFor);

                    core.partner.get($scope.instance.PartnerId)
                        .then(function(p){
                            $scope.instance.PartnerName = p.Name;
                        });
                }
            });
        })();
    }
]);
