"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("templateSharingService", [
    "httpService",
    "$q",
    "uuid",
    function (httpService, $q, uuid) {
        var service = {};

        service.getTemplateSharingPartners = function (partnerId) {
            return httpService.get("/api/template/" + partnerId + "/sharing");
        };

        service.getAllowedTemplateSharingPartners = function (partnerId) {
            return httpService.get("/api/template/" + partnerId + "/sharing/allowed");
        };

        service.saveTemplateSharingPartners = function (partnerId, data) {
            return httpService.post(
                "/api/template/" + partnerId + "/sharing",
                data
            );
        };

        return service;
    },
]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("TemplateSharingCtrl", ["$scope", "coreService",
    function ($scope, core) {
        $scope.filter = core.filter;

        $scope.allowEdit = false;

        $scope.data = [];

        $scope.reload = function () {
            if ($scope.filter.partner) {
                core.templateSharing
                    .getTemplateSharingPartners($scope.filter.partner)
                    .then(function (d) {
                        $scope.data = d;
                    });
            } else {
                $scope.data = [];
            }
        };

        $scope.validateSelection = function(d){
            if (!d.Selected && d.References) {
                var templates =  d.References.split(',').join(', ');
                core.ui.showConfirmDialog(
                    'Confirmation',
                    'You are removing partners that currently have access to templates (' +  templates + ').' +
                    ' This may impact templates that are in use. Are you sure you want to continue?')
                .then(function(){
                    d.Selected = 0;
                },function() {
                    d.Selected = 1;
                });
            }
        };

        $scope.save = function () {
            console.log("saving template sharing: ", $scope.data);

            var payload = _($scope.data)
                .filter({Selected: 1})
                .value();

            return core.templateSharing
                .saveTemplateSharingPartners($scope.filter.partner, payload)
                .then(function (r) {
                    core.ui.showResult(
                        r,
                        "Template Sharing has been updated.",
                        $scope.close
                    );
                }, core.ui.showValidation($scope));
        };

        (function () {
            $scope.reload();

            // check if root partner
            if (core.auth.isSystemAnyUser()) {
                $scope.allowEdit = true;
            } else if (core.auth.isPartnerAnyUser()) {
                core.partner
                    .get(core.auth.principal.partnerId)
                    .then(function (p) {
                        $scope.allowEdit = p.ParentId == null;
                    });
            } else {
                $scope.allowEdit = false;
            }
        })();
    },
]);
