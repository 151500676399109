"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("maintenanceService", ["httpService", "$q", "ApplicationOptions", "$enums", "$timeout",
    function (httpService, $q, applicationOptions, $enums, $timeout) {
        var service = {};

        service.validateImportClient = function (data) {
            return httpService.put("/api/maintenance/validateImportClient", data);
        };

        service.importClient = function (data) {
            return httpService.put("/api/maintenance/importClient", data);
        };

        service.createImport = function (actionType, importData) {
            console.log('creating import ', actionType, importData);

            return httpService.post("/api/maintenance/import/" + actionType, importData);
        };

        service.createImportExt = function (actionType, formData) {
            console.log('creating import ', actionType, formData);
            return  httpService.post("/api/maintenance/import-ext/" + actionType, formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        };

        service.getImportStatus = function (importId) {
            return httpService.get("/api/maintenance/import/" + importId + "/status?t=" + new Date().getTime());
        };
        service.getImport = function (importId, displayMode) {
            return httpService.get("/api/maintenance/import/" + importId + "?displayMode=" + displayMode);
        };
        service.getImportSummary = function (importId) {
            return httpService.get("/api/maintenance/import/" + importId + "/summary");
        };

        // ui helper

        service.waitTimeout = null;
        service.waitCounter = 0;
        service.waitCurrentTimeout = applicationOptions.defaultWaitTimeout;

        service.waitImportCompleted = function(deferred, importId, progress){
            service.getImportStatus(importId)
                .then(function(statusData){
                    var completed = statusData.StatusId === $enums.ImportHistoryStatus.imported ||
                        statusData.StatusId === $enums.ImportHistoryStatus.failed;
                    if(!completed){
                        service.waitCounter ++;
                        if(service.waitCounter >= 10 && service.waitCounter % 10 === 0 && service.waitCurrentTimeout < 10000) {
                            service.waitCurrentTimeout += 1000;
                        }

                        service.updateProgress(progress, statusData);
                        service.waitTimeout = $timeout(function(){
                            service.waitImportCompleted(deferred, importId, progress);
                        }, service.waitCurrentTimeout);
                    }else{
                        deferred.resolve(statusData);
                    }
                })
                .catch(function(err){
                    deferred.reject(err);
                });
        };

        service.waitImportCompletedDelegate = function(importId, progress){
            var deferred = $q.defer();

            // reset
            service.waitCounter  = 0;
            service.waitCurrentTimeout = applicationOptions.defaultWaitTimeout;

            service.waitImportCompleted(deferred, importId, progress);
            return deferred.promise;
        };

        service.handleImportCompleted = function(importId, progress, displayMode){
            // wait job completes
            return service.waitImportCompletedDelegate(importId, progress)
                .then(function() {
                    // reload after wait
                    return service.getImport(importId, displayMode)
                        .then(function (importHistory) {
                            if(importHistory.StatusId === $enums.ImportHistoryStatus.imported){
                                return importHistory;
                            }else if(importHistory.StatusId === $enums.ImportHistoryStatus.failed){
                                console.log('Import failed: ', importHistory.Error);
                                var err = new Error('Import failed: ' + importHistory.Error);
                                // extract validation errors if present
                                var content = JSON.parse(importHistory.Content);
                                err.errors = content && content.errorData ?  content.errorData.errors : undefined;
                                throw err;
                            }else{
                                console.warn('unknown status ', importHistory.StatusId);
                            }
                        });
                });
        };

        service.clearWaitTimeout = function() {
            if (service.waitTimeout) {
                $timeout.cancel(service.waitTimeout);
            }
        };

        service.resetProgress = function(progress) {
            progress.current = progress.total = 0;
        };

        service.updateProgress = function(progress, statusData) {
            var p = statusData.ExtendedData ? JSON.parse(statusData.ExtendedData).progress : {total: 0};
            _.merge(progress, p);

            progress.current = progress.current || 0;
            if(p.current) {
                progress.current = p.current;
            } else {
                var step = Math.round(progress.total / 10);
                progress.current = (progress.current + step) < progress.total ? progress.current + step : progress.total / 2;
            }
        };

        return service;
    }]);

/*****************
 MODEL
 ******************/
var ImportClient = function () {
};
ImportClient.prototype.isDisabled = function () {
    return !this.isValid();
};
ImportClient.prototype.isValidProp = function (name) {
    return !this.Errors[name];
};
ImportClient.prototype.init = function () {
    this.Enabled = true;
    this.Errors = {};
};
ImportClient.prototype.error = function (errors) {
    var self = this;
    errors = errors || [];
    this.Errors = {};
    _.each(errors, function (e) {
        self.Errors[e] = true;
    });
    this.Enabled = errors.length === 0;
    this.isValid = errors.length === 0;
    this.isDisabled = errors.length > 0;
};



var ImportDevice = function (d) {
    if(d){
        _.assign(this, d);
    }

    this.Enabled = true;
    this.isValid = true;
    this.isDisabled = false;
    this.Errors = [];
};
ImportDevice.prototype.findPropError = function (name) {
    var errors = _(this.Errors).filter ({Key: name}).value();
    return errors.length > 0 ? errors[0] : undefined;
};
ImportDevice.prototype.isValidProp = function (name) {
    var error = this.findPropError(name);
    return _.isNil(error);
};
ImportDevice.prototype.getInvalidMessage = function (name) {
    var error = this.findPropError(name);
    return error ? error.Message : undefined;
};
ImportDevice.prototype.init = function () {
    this.Enabled = true;
    this.Errors = [];
};
ImportDevice.prototype.error = function (errors) {
    var self = this;
    errors = errors || [];
    this.Errors = errors;
    this.Enabled = errors.length === 0;
    this.isValid = errors.length === 0;
    this.isDisabled = errors.length > 0;
};
ImportDevice.prototype.getValue = function(name){
    return this[name];
};

/*****************
 DIRECTIVE
 ******************/

portal.directive("daasInvalidImport", function () {
    return {
        restrict: 'E',
        scope: {
            title: '@'
        },
        template: '<span class="glyphicon glyphicon-warning-sign" aria-hidden="true" title="{{title}}"></span>'
    };
});


/*****************
 CONTROLLERS
 ******************/
portal.controller("MaintenanceClientCsvImportCtrl", ["$scope", "$location", "$compile" ,"coreService", "WizardHandler", "ApplicationOptions",
    function ($scope, $location, $compile, core, WizardHandler, applicationOptions) {
        $scope.options = {
            sendWelcome: true
        };
        $scope.importFile = {};
        $scope.data = [];
        $scope.results = [];
        $scope.clientCreationMaxBatchSize = applicationOptions.clientCreationMaxBatchSize;

        $scope.refresh = false; // indicates page is refreshed

        var csvHeaderTemplate = ["DirectPartner","ClientName","ClientBillingId",
            "UserName","UserRole","FirstName","LastName","Email","Phone"];
        var validated         = false;

        $scope.canFileBeUploaded = function() {
            return (!!$scope.importFile.data);
        };

        $scope.clipboard = new Clipboard('.clipboard');

        $scope.isCreationMaxBatchSizeExceeded = function(){
            return _.filter($scope.data, function(d){
                return d.Enabled;
            }).length > $scope.clientCreationMaxBatchSize;
        };

        $scope.canFileBeImported = function() {
            var data = _.chain($scope.data).filter(function(d){ return d.Enabled;}).value();
            return validated && data.length > 0 && !$scope.isCreationMaxBatchSizeExceeded();
        };

        $scope.cancel = function () {
            $scope.data = [];
            $scope.importFile = {};
            $scope.results = [];

            $('#importFile').val('');
        };

        $scope.close = function () {
            $location.path("/");
        };

        var updateImport = function(data) {
            for(var i = 0; i < data.length; i++) {
                var item = data[i];
                var key = item.Key;
                var row = _.find($scope.data, {Key: key});
                row.error(item.Errors);
            }
            validated = true;
        };

        var parse = function(data) {
            var result = core.ui.csvToArray(data, csvHeaderTemplate, function (){return new ImportClient();});
            return result;
        };

        var prepareResults = function(result, data){
            var r = result;
            r.total = result.auditResults.length;
            r.succeedCount =  _.filter(result.auditResults, function(a){return a.succeed;}).length;
            r.failed = _.filter(result.auditResults, function(a){return !a.succeed;});
            r.failedCount = r.failed.length;

            _.each(result.auditResults, function(ar){
                ar.data = _.find(data, {Key: ar.key});
            });

            console.log('import result', r);
            return r;
        };

        $scope.upload = function() {

            validated = false;
            $scope.data = parse($scope.importFile.data);

            core.maintenance.validateImportClient($scope.data)
                .then(updateImport, core.ui.showValidation($scope))
                .then(function(){
                    WizardHandler.wizard().next();
                });
        };

        $scope.import = function() {
            var data =
                _.chain($scope.data)
                    .filter(function(d){
                        return d.Enabled;
                    })
                    .map(function(d){
                        var out = _.clone(d);
                        delete out.Errors;
                        delete out.Enabled;
                        return out;
                    })
                    .value();
            var imp = {
                data: data,
                sendWelcome: $scope.options.sendWelcome
            };

            console.log('importing ', imp);

            core.maintenance.importClient(imp)
                .then(function(results){
                    return prepareResults (results, imp.data);
                })
                .then(function(r){
                    core.ui.success("Import has been processed successfully.");
                    $scope.results = r;

                    WizardHandler.wizard().next();

                }, core.ui.showValidation($scope));
        };
    }]);


portal.controller("MaintenanceBatchActionCtrl", ["$scope", "$location", "$compile" ,"coreService", "WizardHandler", "ApplicationOptions", "$enums", "$routeParams", "$q", "$timeout",
    function ($scope, $location, $compile, core, WizardHandler, applicationOptions, $enums, $routeParams, $q, $timeout) {

        var mode = {
            batch: 'batch'
        };

        $scope.mode = $routeParams.mode ? $routeParams.mode : mode.batch;

        $scope.getPageTitle = function(){
            var title = '';
            switch($scope.mode){
                case mode.batch:
                    title = 'Batch Actions';
                    break;
                default:
                    break;
            }
            return title;
        };

        // #region Configuration

        $scope.actions = [
            //{ key: 'importDevice', title: 'Import devices', actionType: $enums.ImportHistoryActionType.deviceImport, validationType: $enums.ImportHistoryActionType.deviceImportValidate, mode: 'batch'},
        ];

        $scope.configurations = {
            /*
            importDevice: {
                headers: [
                    {key: "Kif", title: "Kif", required: true},
                    {key: "Partner", title: "Partner", required: true},
                    {key: "Client", title: "Client", required: true},
                    {key: "SerialNumber", title: "Serial Number", required: true},
                    {key: "DeviceType", title: "Device Type", required: true},
                    {key: "InjectKey", title: "Inject Key", required: true},
                    {key: "AlternateKey", title: "Alternate Key"},
                    {key: "Name", title: "Name"},
                    {key: "ShipmentTracking", title: "Shipment Tracking", required: true},
                    {key: "Carrier", title: "Carrier", required: true},
                    {key: "TamperLabel", title: "Tamper Label", required: true},
                    {key: "BuildNumber", title: "Build Number", required: true}
                ],
                description: "Creates new devices at KIF and sends them via Shipment to the Client's Location.",
                sample: '/assets/import/device_batch_import.csv'
            }*/
        };

        // #endregion

        $scope.configuration = null;
        // set default action based on mode
        $scope.model = {
            action: _($scope.actions).filter({mode: $scope.mode}).head().key,
            displayMode: $routeParams.displayMode ? $routeParams.displayMode : $enums.MaintenanceDisplayMode.minimized
        };

        $scope.loading = false;

        $scope.importFile = {};
        $scope.uploadFile = {};
        $scope.data = [];
        $scope.results = [];
        $scope.summary = {};
        $scope.batchActionMaxSize = applicationOptions.batchActionMaxSize;
        $scope.options = {};
        $scope.filter = core.filter;
        $scope.filter.partner =  $scope.filter.client = undefined; // reset filter on load due to issue with options

        $scope.progress = {
            total: 0,
            current: 0
        };

        var validated         = false;

        var optionsValid = function(){
            var valid = true;
            switch($scope.model.action) {
                case 'ingenicoStore':
                    valid = !_.isNil($scope.options.PartnerId)
                        && !_.isNil($scope.options.ClientId);
                    break;
                case 'deviceTransfer':
                    valid = !_.isNil($scope.options.PartnerId)
                        && !_.isNil($scope.options.ClientId);
                    break;
            }
            return valid;
        };

        $scope.canFileBeUploaded = function() {
            return (!!$scope.importFile.data) && optionsValid();
        };

        $scope.canFileBeUploadedExt = function() {
            return (!!$scope.uploadFile.file) && optionsValid();
        };

        $scope.clipboard = new Clipboard('.clipboard');

        $scope.isCreationMaxBatchSizeExceeded = function(){
            return _.filter($scope.data, function(d){
                return d.Enabled;
            }).length > $scope.batchActionMaxSize;
        };

        $scope.canFileBeImported = function() {
            var data = _.chain($scope.data).filter(function(d){ return d.Enabled;}).value();
            return validated && data.length > 0 && !$scope.isCreationMaxBatchSizeExceeded();
        };

        $scope.canFileBeImportedExt = function() {
            return validated && $scope.summary.succeedCount > 0;
        };

        $scope.cancel = function () {
            $scope.data = [];
            $scope.importFile = {};
            $scope.results = [];
            $scope.summary = {};
            $scope.errors = [];
            $scope.options = {};
            core.filter.partner =  core.filter.client = undefined;

            $('#importFile, #uploadFile').val('');

            $location.search({});
        };

        $scope.close = function () {
            $location.url("/", {});
        };

        $scope.getTitle = function(){
            var action =  _.find($scope.actions, function(a){
                return a.key === $scope.model.action;
            });
            return action ? action.title : 'Upload';
        };

        var updateImport = function(data, parseData) {
            data = data ? data : [];
            for(var i = 0; i < data.length; i++) {
                var item = data[i];
                var key = item.Key;
                var row = _.find(parseData, {Key: key});
                row.error(item.Errors);
            }
            validated = true;
            return parseData;
        };

        var updateImportExt = function(resultData, importData) {
            resultData = resultData ? resultData : [];
            // iterate importData, change it's type and set errors if any
            for(var i = 0; i < importData.length; i++) {
                var item = importData[i];
                Object.setPrototypeOf(item, ImportDevice.prototype);

                var key = item.Key;
                var row = _.find(resultData, {Key: key});
                if(row) {
                    item.error(row.Errors);
                }
            }
            validated = true;
            return importData;
        };

        var parse = function(data) {
            console.assert($scope.configuration !== null, 'configuration cannot be null');
            var csvHeaderTemplate = _.map($scope.configuration.headers, "key");
            var result = core.ui.csvToArray(data, csvHeaderTemplate, function (){return new ImportDevice();});
            return result;
        };

        var restoreFromImport = function(importData){
            var data = _.map(importData, function(d){
                return new ImportDevice(d);
            });
            return data;
        };

        var prepareResults = function(results, data){
            results = results || data; // todo remove
            var r = results;
            r.total = results.length;
            r.succeedCount =  _.filter(results, {Succeed: true}).length;
            r.failed = _.filter(results, {Succeed: false});
            r.failedCount = r.failed.length;

            _.each(results, function(ar){
                ar.data = _.find(data, {Key: ar.Key});
                //ar.data.error(ar.Errors);
            });

            console.log('import result', r);
            return r;
        };

        var prepareResultsSummary = function(content){
            $scope.summary = content.summary;
            validated = true;
        };

        var start = function(){
            $scope.loading = true;
            core.maintenance.resetProgress($scope.progress);
            core.ui.showLoading('#batch-action-form');
        };

        var stop = function(){
            $scope.loading = false;
            core.ui.hideLoading('#batch-action-form');
        };

        var doAction = function(importData, actionType){
            return core.maintenance.createImport(actionType, importData)
                .then(function(importId){
                    core.ui.success("Import action has been initiated successfully. It may take for few minutes.");
                    $scope.importId = importId;
                    $location.search({importId: importId, displayMode: $scope.model.displayMode});
                    return core.maintenance.handleImportCompleted($scope.importId, $scope.progress, $scope.model.displayMode);
                })
                .catch(core.ui.showValidation($scope));
        };

        var _appendFormData = function(formData, key, value){
            if(!_.isNil(value)){
                formData.append(key, value);
            }
        };
        var _buildFormData = function(importData){
            var formData = new FormData($('#batch-action-form')[0]);
            // append options to formdata
            _appendFormData(formData, "options", JSON.stringify($scope.options));

            return formData;
        };

        var doActionExt = function(importData, actionType){
            var formData = _buildFormData(importData);
            return core.maintenance.createImportExt(actionType, formData)
                .then(function(importId){
                    core.ui.success("Import action has been initiated successfully. It may take for few minutes.");
                    $scope.importId = importId;
                    $location.search({importId: importId, displayMode: $scope.model.displayMode});
                    return core.maintenance.handleImportCompleted($scope.importId, $scope.progress, $scope.model.displayMode);
                });
        };

        $scope.upload = function() {
            start();

            validated = false;
            var actionType = _.find($scope.actions, { key:$scope.model.action }).validationType;
            var importData = parse($scope.importFile.data);
            var content = {
                importData: importData,
                options: $scope.options
            };

            doAction(content, actionType)
                .then(function(importHistory){
                    var content = JSON.parse(importHistory.Content);
                    //importData = importData ? importData : restoreFromImport(content.importData);
                    $scope.data = updateImport(content.resultData, importData);
                })
                .then(function(){
                    WizardHandler.wizard().next();
                })
                .catch(core.ui.showValidation($scope))
                .finally(function(){
                    stop();
                });
        };

        $scope.uploadExt = function() {
            start();

            validated = false;
            var actionType = _.find($scope.actions, { key:$scope.model.action }).validationType;
            //var actionType = _.find($scope.actions, { key:$scope.model.action }).actionType;
            var content = {
                uploadData: $scope.uploadFile,
                options: $scope.options
            };
            doActionExt(content, actionType)
                .then(function(importHistory){
                    var content = JSON.parse(importHistory.Content);
                    prepareResultsSummary(content);
                    //debugger
                    //$scope.data = updateImportExt(content.resultData, content.importData);
                    //prepareResults (content.resultData, content.importData);
                })
                .then(function(){
                    WizardHandler.wizard().next();//goTo(2);
                })
                .catch(core.ui.showValidation($scope))
                .finally(function(){
                    stop();
                });
        };

        $scope.import = function() {
            console.assert($scope.configuration !== null, 'configuration cannot be null');

            start();
            /*var data =
                _.chain($scope.data)
                    .filter(function(d){
                        return d.Enabled;
                    })
                    .map(function(d){
                        var out = _.clone(d);
                        delete out.Errors;
                        delete out.Enabled;
                        return out;
                    })
                    .value();*/

            var actionType = _.find($scope.actions, { key:$scope.model.action }).actionType;

            var content = {
                //importData: data,
                uploadImportId: $scope.importId,
                options: $scope.options
            };

            doAction(content, actionType)
                .then(function(importHistory){
                    core.ui.success("Import has been processed successfully.");
                    var content = JSON.parse(importHistory.Content);
                    //prepareResults (content.resultData, data);
                    prepareResultsSummary(content);
                })
                .then(function(){
                    WizardHandler.wizard().next();
                })
                .catch(core.ui.showValidation($scope))
                .finally(function(){
                    stop();
                });
        };

        /// events
        $scope.$watch("model.action", function(v){
            $scope.configuration = $scope.configurations[v];
            $scope.options = {};
        }, true);

        $scope.$watch("importFile", function(v){
            if(!_.isNil(v.file) && !_.endsWith(v.file, '.csv')){
                core.ui.warning("Only csv files are accepted.");
                $scope.cancel();
            }
        }, true);

        $scope.$watch("uploadFile", function(v){
            if(!_.isNil(v.file) && !_.endsWith(v.file, '.csv')){
                core.ui.warning("Only csv files are accepted.");
                $scope.cancel();
            }
        }, true);


        $scope.$on("$destroy", function() {
            console.log('destroying...');
            core.maintenance.clearWaitTimeout();
        });

        var restoreActionModel = function(actionTypeId){
            var action = _.find($scope.actions, function(a){
                return a.actionType === actionTypeId || a.validationType === actionTypeId;
            });
            $scope.model.action = action.key;
            var firstStep = action.validationType === actionTypeId;
            return firstStep;
        };

        var tryLoadCurrentImport = function(){

            $scope.refresh = !_.isNil($routeParams.importId);

            if($scope.refresh){
                $scope.importId = parseInt($routeParams.importId);
                start();

                core.maintenance.handleImportCompleted($scope.importId, $scope.progress, $scope.model.displayMode)
                    .then(function(importHistory){
                        var content = JSON.parse(importHistory.Content);
                        var actionType = importHistory.ActionTypeId;

                        // identify if it's validate step or not
                        var isFirstStep = restoreActionModel(actionType);
                        var isSecondStep = !isFirstStep;
                        //var importData = restoreFromImport(content.importData);
                        //prepareResults(content.resultData, importData);
                        prepareResultsSummary(content);
                        if(isFirstStep){
                            //$scope.data = updateImport(content.resultData, importData);
                            return 1;
                        }else if (isSecondStep){
                            //prepareResults(content.resultData, importData);
                            return 2;
                        }
                    })
                    .then(function(stepNr){
                        WizardHandler.wizard().goTo(stepNr);
                    })
                    .catch(core.ui.showValidation($scope))
                    .finally(function(){
                        stop();
                    });
            }
        };

        $scope.$on('$viewContentLoaded', function(){
            $timeout(tryLoadCurrentImport, 200);
        });
    }]);


/*
portal.directive("deviceTransitionOptions", function () {
    return {
        restrict: 'E',
        templateUrl: '/partials/maintenance/device.transition.options.html',

        controller: ["$scope", "coreService", function ($scope, core) {
            $scope.kifs = [];
            $scope.injectKeys = [];

            $scope.filter = core.filter;
            $scope.showDeviceTransferOptions = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

            $scope.change = function(){
                var f = $scope.filter;
                $scope.options.PartnerId = f.partner ? parseInt(f.partner) : null;
                $scope.options.ClientId = f.client ? parseInt(f.client) : null;
            };

            (function () {
                if(core.auth.isSystemAnyUser()){
                    core.kif.all().then(function (k) {
                        $scope.kifs = k;
                    });

                    core.injectKey.all().then(function (k) {
                        $scope.injectKeys = k;
                    });
                }
            })();

        }]
    };
});
*/
