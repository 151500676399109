"use strict";
var portal = angular.module("portal");

/*****************
 ENUMS
 ******************/

var inputTypes = {
    input: { title: "Input", value: "input" },
    select: { title: "Dropdown", value: "select" },
    password: { title: "Password", value: "password" },
    creditCardNumber: { title: "Credit Card", value: "creditCardNumber" },
    creditCardExpiration: { title: "Credit Card Expiration", value: "creditCardExpiration" },
    //textarea: { title: "Textarea", value: "textarea" },
    //calendar: { title: "calendar", value: "calendar" },
};

var alphabets = {
    card10: { title: "Numeric (0-9)", value: "CARD10" },
    card62: { title: "Alphanumeric (A-Z, a-z, 0-9)", value: "CARD62" },
    card26: { title: "Alphabetic (a-z)", value: "CARD26" },
    // unicode: { title: "Unicode", value: "UNICODE" }
};

var formats = {
    LAST_FOUR: { title: "All inputs tokenized except last 4", value: "LAST_FOUR", minLength: 9, maxLength: 128 },
    FIRST_SIX: { title: "All inputs tokenized except first 6", value: "FIRST_SIX", minLength: 11, maxLength: 128 },
    FIRST_SIX_LAST_FOUR: { title: "All inputs tokenized except first 6 and last 4", value: "FIRST_SIX_LAST_FOUR", minLength: 15, maxLength: 128 },
    FIRST_TWO_LAST_FOUR: { title: "All inputs tokenized except first 2 and last 4", value: "FIRST_TWO_LAST_FOUR", minLength: 11, maxLength: 128 },
    NONE: { title: "Tokenize all inputs in field", value: "NONE", minLength: 2, maxLength: 128 },
};

var dataTypeKind = {
    numeric: { value: "numeric", alphabets: [alphabets.card10, alphabets.card62] },
    alphanumeric: { value: "alphanumeric", alphabets: [alphabets.card62] },
};

var aliases = {
    allFormats: [formats.NONE, formats.LAST_FOUR, formats.FIRST_SIX, formats.FIRST_SIX_LAST_FOUR, formats.FIRST_TWO_LAST_FOUR],
};

var dataTypes = [
    { title: "Address", value: "address", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "Bank Account Number", value: "account_number", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "Credit Card", value: "credit_card", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.creditCardNumber, minLength: 14, maxLength: 19 },
    { title: "CVV", value: "cvv", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input, minLength: 3, maxLength: 4, formats: [formats.NONE] },
    { title: "Date", value: "date_of_birth", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input, placeholder: "mmddyyyy" },
    { title: "Driver's License", value: "driver_license", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "Email", value: "email", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "Expiration Date", value: "expy_date", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.creditCardExpiration, placeholder: "MM/YY" },
    { title: "Gender", value: "gender", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.select, inputValues: "Man,Woman" },
    { title: "IP Address", value: "ip_address", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "Location", value: "location", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "MAC Address", value: "mac_address", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "Medical ID", value: "medical_id", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "Mobile ID", value: "mobile_id", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "Name", value: "name", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "National ID Number", value: "national_id_number", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "Passport", value: "passport", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "Race", value: "race", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "Telephone", value: "telephone", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "TAX ID", value: "tax_id", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "User Name", value: "user_name", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "Vehicle ID", value: "vehicle_id", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "Alpha String", value: "alpha_string", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
    { title: "Numeric String", value: "numeric_string", kind: dataTypeKind.numeric, alphabet: alphabets.card10, format: formats.NONE, inputType: inputTypes.input },
    { title: "Alphanumeric String", value: "alphanumeric_string", kind: dataTypeKind.alphanumeric, alphabet: alphabets.card62, format: formats.NONE, inputType: inputTypes.input },
];

var methods = [
    {
        title: "Format Preserving Encryption (FPE)",
        value: "FPE",
        minLength: 2,
        maxLength: 56,
        alphabets: [
            { alphabet: alphabets.card10, formats: aliases.allFormats, pattern: "[0-9]+", patternMessage: "Invalid format" },
            { alphabet: alphabets.card26, formats: [formats.NONE], pattern: "[a-zA-Z@.-_:%/+! ();,']+", patternMessage: "Invalid format" }, // just support for backward compatibility
            { alphabet: alphabets.card62, formats: [formats.NONE], pattern: null, patternMessage: null },
        ],
    },
    {
        title: "Format Preserving Tokenization (FPT)",
        value: "FPT",
        minLength: 2,
        maxLength: 128,
        alphabets: [
            { alphabet: alphabets.card10, formats: aliases.allFormats, pattern: "[0-9-@_:%/+! ().;,']+", patternMessage: "Invalid format" },
            { alphabet: alphabets.card26, formats: [formats.NONE], pattern: "[a-zA-Z@.-_:%/+! ();,']+", patternMessage: "Invalid format" }, // just support for backward compatibility
            { alphabet: alphabets.card62, formats: aliases.allFormats, pattern: null, patternMessage: null },
        ],
    },
];

var inputTypesArrays = [inputTypes.input, inputTypes.select, inputTypes.password, inputTypes.creditCardNumber, inputTypes.creditCardExpiration];

var luhnOptions = [
    { value: undefined, title: "None" },
    { value: false, title: "Test input only" },
    { value: true, title: "Test input and output" },
];

var threeDsFieldCardTypes = {
    creditCard: "creditCard",
    cardExpiration: "cardExpiration",
    cvv: "cvv",
    cardHolder: "cardHolder",
};

var threeDsFieldTypes = [
    { value: threeDsFieldCardTypes.creditCard, title: "Credit Card" },
    { value: threeDsFieldCardTypes.cardExpiration, title: "Credit Card Expiration" },
    { value: threeDsFieldCardTypes.cardHolder, title: "Credit Card Holder Full Name" },
    { value: "cardHolderFirstName", title: "Credit Card Holder First Name" },
    { value: "cardHolderLastName", title: "Credit Card Holder Last Name" },
    { value: threeDsFieldCardTypes.cvv, title: "CVV" },
    { value: "amount", title: "Amount" },
    { value: "currency", title: "Currency" },
    { value: "email", title: "Email" },
    { value: "shippingLine1", title: "Shipping Line1" },
    { value: "shippingLine2", title: "Shipping Line2" },
    { value: "shippingLine3", title: "Shipping Line3" },
    { value: "shippingPostCode", title: "Shipping PostCode" },
    { value: "shippingCity", title: "Shipping City" },
    { value: "shippingState", title: "Shipping State" },
    { value: "shippingCountry", title: "Shipping Country" },
    { value: "billingLine1", title: "Billing Line1" },
    { value: "billingLine2", title: "Billing Line2" },
    { value: "billingLine3", title: "Billing Line3" },
    { value: "billingPostCode", title: "Billing PostCode" },
    { value: "billingCity", title: "Billing City" },
    { value: "billingState", title: "Billing State" },
    { value: "billingCountry", title: "Billing Country" },
];

var fieldDetailtTooltips = {
    fieldName: "This is the system name for the field. This is the name that will be used in ShieldConex API requests. Special characters and spaces are not allowed.",
    fieldNameInline: "This is the system name for the field.",
    dataType: "This configures the type of data that will be entered into the field. \r\n Note - Per PCI, CVV data is prohibited from being stored",
    mask: "This configures which characters will be masked in an input value.",
    sortOrder: "This controls the order in which the fields will display from left-to-right and from top-to-bottom within the iFrame.",
    inputType: "Select an option from the drop-down list (Input, Select, Password). If you choose Select, you will be prompted to enter possible values.",
    placeholder: 'The value that will display in an empty field in an iFrame until the user enters something. EXAMPLE: In a field that prompts you to enter the amount of money you want to borrow, the placeholder might display "$1,000 - $10,000".',
    placeholderInline: "The value that will display in an empty field in an iFrame until the user enters something.",
    method: "This configures the data security method for the field value.",
    validation: "This configures which types characters will be allowed to be use for the selected Data Type.",
    required: "Select the checkbox if applicable. This parameter determines if this field requires an entry within the iFrame.",
    luhnCheck: "This option configures the Luhn Validation for the field (the Luhn algorithm is used to validate a credit card number). There are three options to choose from: none - neither the input nor the token is Luhn tested, input only - the input has to pass the Luhn test or an error is thrown, and input & output - the input has to pass the Luhn test and we will only output tokens that pass Luhn Validation.",
    nonIdempotent: 'This parameter determines if different token values are generated for the same input value. If you enable this option, ShieldConex will generate a different token value every time the same input value is specified. If you do not enable this option, the same token value will be returned each time the same value is entered in a field. This option can only be used on input values that pass the Luhn Check; if an input is used that does not pass the Luhn check an error will be returned. Furthermore when this option is selected it is not possible to return a token that passes the Luhn test, therefore the Luhn Check will automatically be set to "input only".',
    nonIdempotentInline: "This parameter determines if different token values are generated for the same input value.",
    groupId: "This parameter sets the group ID (long) for the plaintext to be tokenized. Different tokens will be generated for the same plaintext having different group IDs.",
    clearText: "This parameter determines if the digits in the clear-text position of the input plaintext is used to generate the token.",
    label: "This controls the field's display name in the form.",
    minLength: "This is the minimum length for the field value.",
    maxLength: "This is the maximum length for the field value.",
    inputValues: "This configures the options for Select Input Type",
    threeDsType: "3DS Field Type determines how this information is packaged in the 3DS authentication request",

    templateType: "This configures the type of data that will be entered into the field.",
    width: "The width of the field within the iFrame. Sequential fields that can fit on the same row will be adjacent by default.",
    dateSeparator: "The character that separates the day, month, and year",
    dateFormat: "Determines how the date is automatically formatted.",
    countryCode: "When selected, the country code is included in the phone number.",
    cardOptions: "When selected, the Card Holder Name will be added to the Card Information section",
    addressOptions: "Selected options will be included in the Address fieldset",
    threedsOptions: "Select how this data should be assigned when processing 3DS authentication",

    pattern: "The regular expression that the input must match to be accepted.y",
    patternMessage: "The message shown to the user if the pattern is not matched.",
    phoneInputMasking: "This restricts users to match the format you specify.",

    alphabet: "This configures the allowed characters for the input as well as the output token.",
};

var fieldTemplateTypes = {
    field: "field",
    address: "address",
    card: "card",
    date: "date",
    email: "email",
    bank: "bank",
    routing: "routing",
    tax: "tax",
    phone: "phone",
};

var fieldTemplateTypesArray = [
    { value: fieldTemplateTypes.card, title: "Card Information" },
    { value: fieldTemplateTypes.address, title: "Address" },
    { value: fieldTemplateTypes.date, title: "Date" },
    { value: fieldTemplateTypes.email, title: "Email Address" },
    { value: fieldTemplateTypes.bank, title: "Bank Account Number" },
    { value: fieldTemplateTypes.routing, title: "Routing Number" },
    { value: fieldTemplateTypes.tax, title: "Tax ID Number" },
    { value: fieldTemplateTypes.phone, title: "Phone Number" },
    { value: fieldTemplateTypes.field, title: "Custom Field" },
];

var widths = [
    { value: "100", title: "100%" },
    { value: "75", title: "75%" },
    { value: "50", title: "50%" },
    { value: "25", title: "25%" },
];

var dateSeparators = [
    { value: "/", title: "/" },
    { value: "-", title: "-" },
];

var dateFormats = [
    { value: "MM-DD-YYYY", title: "MM-DD-YYYY" },
    { value: "DD-MM-YYYY", title: "DD-MM-YYYY" },
    { value: "YYYY-MM-DD", title: "YYYY-MM-DD" },
];

var addressFields = [
    { key: "addressLine1", title: "Street Address", name: "street_address1", visible: true, threeDs: "Line1", disabled: true },
    { key: "addressLine2", title: "Street Address 2", name: "street_address2", visible: true, threeDs: "Line2" },
    { key: "city", title: "City", name: "city", visible: true, threeDs: "City" },
    { key: "state", title: "State / Province", name: "state", visible: true, threeDs: "State" },
    { key: "postalCode", title: "ZIP / Postal Code", name: "postalCode", visible: true, threeDs: "PostCode" },
    { key: "country", title: "Country", name: "country", placeholder: "Country", visible: true, threeDs: "Country" },
];

var cardFields = [
    { key: "cardholder", title: "Card Holder Name", name: "cardholder", visible: true, showLuhn: false, showFormats: false, threeDsType: threeDsFieldCardTypes.cardHolder },
    { key: "cardNumber", title: "Card Number", name: "card_number", visible: false, showLuhn: true, showFormats: true, threeDsType: threeDsFieldCardTypes.creditCard },
    { key: "expiration", title: "Expiration Date", name: "expiration", visible: true, showLuhn: false, showFormats: false, threeDsType: threeDsFieldCardTypes.cardExpiration },
    { key: "cvv", title: "Security Code", name: "cvv", visible: true, showLuhn: false, showFormats: false, threeDsType: threeDsFieldCardTypes.cvv },
];

var threedsAddressTypes = {
    none: "none",
    shipping: "shipping",
    billing: "billing",
};

var threedsAddressOptions = [
    { value: threedsAddressTypes.none, title: "None" },
    { value: threedsAddressTypes.shipping, title: "Shipping" },
    { value: threedsAddressTypes.billing, title: "Billing" },
];

var phoneInputMaskingTypes = [
    { value: null, title: "No Masking" },
    { value: "### ### ####", title: "### ### ####" },
    // { value: '(###) ###-####', title: '(###) ###-####' },
    { value: "###-###-####", title: "###-###-####" },
];

/*****************
 SERVICE
 ******************/

portal.factory("templateService", [
    "httpService",
    "$q",
    function (httpService, $q) {
        var service = {};

        service.get = function (id) {
            return httpService.get("/api/template/" + id);
        };

        service.create = function (record) {
            return httpService.put("/api/template/", record);
        };

        service.update = function (record) {
            return httpService.post("/api/template/", record);
        };

        service.generatePreviewLink = function (record) {
            return httpService.post("/api/template/" + record.Id + "/preview", record);
        };

        service.generateSampleLink = function (record) {
            return httpService.get("/api/template/" + record.Id + "/sample").then(function (result) {
                return result.link;
            });
        };

        service.clone = function (record) {
            return httpService.put("/api/template/" + record.Id + "/clone", { Name: record.Name });
        };

        service.delete = function (record) {
            return httpService.delete("/api/template/" + record.Id);
        };

        service.favorite = function (templateId) {
            return httpService.post("/api/template/" + templateId + "/favorite");
        };

        service.findByClient = function (clientId) {
            return httpService.get("/api/template/find/" + clientId);
        };

        service.createByType = function (type, template) {
            return httpService.put("/api/template/" + type, template, { disableUiErrorHandling: true });
        };

        service.getThreeDsFields = function () {
            return httpService.get("/api/template/threeDs/fields");
        };

        service.getThreeDsTemplates = function () {
            return httpService.get("/api/template/threeDs/templates");
        };

        // preview
        service.loadPreview = function (frameId, templateData) {
            $(frameId).ready(function () {
                var tryPost = function () {
                    service.renderPreview(frameId, templateData);
                };

                $(frameId).on("load", tryPost);
            });
        };

        service.renderPreview = function (frameId, templateData) {
            var message = {
                templateData: templateData,
            };

            var frameEl = $(frameId).get(0);
            var frameDoc = frameEl ? frameEl.contentDocument || frameEl.contentWindow : null;
            frameDoc.postMessage(message, "*");
        };

        /*field templates*/

        // utils
        service.mapTemplates = function (fieldTemplates, log) {
            return httpService.post("/api/template/utils/map", { templates: fieldTemplates }).then(function (fields) {
                if (log) {
                    console.log("templates", fieldTemplates);
                    console.log("fields", fields);
                }

                return fields;
            });
        };

        service.getDefinitions = function () {
            return httpService.get("/api/template/utils/definitions");
        };

        service.findDuplicates = function (fieldTemplates) {
            return service.mapTemplates(fieldTemplates).then(function (fields) {
                var duplicates = _(fields)
                    .groupBy("name")
                    .map(function (items, name) {
                        return items.map(function (f) {
                            return {
                                name: name,
                                label: f.options.label || f.templateFieldKey,
                                templateFieldKey: f.templateFieldKey,
                                templateKey: f.templateKey,
                                count: items.length,
                            };
                        });
                    })
                    .flatten()
                    .filter(function (i) {
                        return i.count > 1;
                    })
                    .value();

                return duplicates;
            });
        };

        return service;
    },
]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("TemplateListCtrl", [
    "$rootScope",
    "$scope",
    "$location",
    "$compile",
    "coreService",
    "$uibModal",
    "$q",
    function ($rootScope, $scope, $location, $compile, core, $modal, $q) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, "/manage/templates");
        };

        var r2 = function (id, r, tData) {
            return '<i id="favorite_' + tData.Id + '" class="item-favorite glyphicon glyphicon-star' + (!!tData.Favorite ? "" : "-empty") + '" ng-click="favorite(' + tData.Id + ')"/>';
        };

        var _templateCreation = function (type, template) {
            return core.template.createByType(type, template).then(
                function (id) {
                    core.ui.success("Template has been created.");
                    $location.path("/manage/templates/" + id);
                },
                function (err) {
                    if (err.code === "BS_VALIDATION_ERROR" && err.errors && err.errors.length && err.errors[0].attribute === "unique") {
                        core.ui.warning("Template already exist.");
                    } else {
                        core.ui.error(err.message || "Unexpected error while creating template.");
                    }
                },
            );
        };

        var _loadClients = function () {
            var promise;
            if ($scope.showClients && $scope.selector.PartnerId) {
                promise = core.client.findByPartner($scope.selector.PartnerId);
            } else {
                var deferred = $q.defer();
                deferred.resolve([]);
                promise = deferred.promise;
            }

            return promise.then(function (c) {
                $scope.clients = c;
                return c;
            });
        };

        $scope.showPartners = $scope.showClients = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

        $scope.clients = [];

        $scope.selector = {
            PartnerId: $rootScope.principal.partnerId,
            ClientId: $rootScope.principal.clientId,
        };

        $scope.filter = _.defaults(core.filter, { type: "" });

        $scope.dtConfig = {
            ajax: {
                url: "/api/template/list",
                data: function (d) {
                    d.filter = _.clone($scope.filter);
                },
            },
            columnDefs: [
                { targets: [0, 5], sortable: false, searchable: false },
                { targets: [0], data: "Id", render: r1, name: "t.Name", width: "5%" },
                { targets: [1], data: "Name", title: "Name", name: "t.Name", width: "25%" },
                { targets: [2], data: "Reference", title: "Reference", name: "t.Reference", width: "25%" },
                { targets: [3], data: "Active", render: core.dt.renderYesNoFromBool, title: "Published", name: "t.Active", width: "5%" },
                { targets: [4], data: "Favorite", render: r2, title: "Favorite", name: "tf.TemplateId", width: "5%" },
                { targets: [5], data: "TemplateVersion", title: "TemplateVersion", width: "5%" },
            ],
            createLink: "/manage/templates/0",
            createExtraLinks: [
                // { name: "Create PayConex Token Payments Template", key: "payConexTokenPayments" },
                // { name: "Create PayConex Tokenization Template", key: "payConexTokenization" }
                { name: "Create PayConex Token Payments Template", key: "templatePayConexTokenPayments" },
                { name: "Create PayConex Tokenization Template", key: "templatePayConexTokenization" },
                { name: "Create MyChart Card Transaction Template", key: "templateMyChartCardTransaction" },
                { name: "Create MyChart ACH Transaction Template", key: "templateMyChartAchTransaction" },
            ],
            reloadEvent: "reloadList",
            showTableTools: "Templates",
            fnRowCallback: function (nRow, aData) {
                if (!aData.Active) {
                    $(nRow).addClass("text-muted");
                }
            },
            drawCallback: function () {
                $compile($(".item-favorite"))($scope);
                // $scope.$apply();
            },
        };

        $scope.onExtraLinkClick = function (key) {
            switch (key) {
                // case 'payConexTokenPayments':
                // case 'payConexTokenization':
                case "templatePayConexTokenPayments":
                case "templatePayConexTokenization":
                case "templateMyChartCardTransaction":
                case "templateMyChartAchTransaction":
                    if (core.auth.isClientAnyUser()) {
                        _templateCreation(key, {});
                    } else if (core.auth.isPartnerAnyUser() || core.auth.isSystemAnyUser()) {
                        _loadClients().then(function () {
                            $scope.modalInstance = $modal.open({
                                templateUrl: "client-selector.html",
                                scope: $scope,
                            });

                            $scope.modalInstance.result.then(function (data) {
                                return _templateCreation(key, data);
                            });
                        });
                    } else {
                        console.error("User belongs to unexpected role");
                    }
                    break;
                default:
                    console.error('Unknown key: "' + key + '"');
                    break;
            }
        };

        $scope.clientSelectSuccess = function () {
            $scope.modalInstance.close($scope.selector);
        };

        $scope.clientSelectCancel = function () {
            $scope.modalInstance.dismiss("cancel");
        };

        $scope.favorite = function (templateId) {
            return core.template.favorite(templateId).then(function (res) {
                $("#favorite_" + templateId)
                    .toggleClass("glyphicon-star-empty", !res.Favorite)
                    .toggleClass("glyphicon-star", res.Favorite);
            });
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };

        $scope.clear = function () {
            $scope.filter.partner = null;
            $scope.filter.client = null;
            $scope.filter.type = "";
            $scope.reload();
        };

        $scope.changePartner = function () {
            _loadClients();
        };
    },
]);

portal.controller("TemplateDetailsCtrl", [
    "$scope",
    "$location",
    "$routeParams",
    "$uibModal",
    "$enums",
    "$q",
    "$sce",
    "coreService",
    function ($scope, $location, $routeParams, $modal, $enums, $q, $sce, core) {
        $scope.template = {
            AccessMode: $enums.TemplateAccessMode.unrestricted,
        };
        $scope.fields = [];
        $scope.fieldTemplates = [];
        $scope.templateVersion = "1.1";
        $scope.ref = {};
        $scope.clients = [];
        $scope.options = {
            domains: [],
        };

        $scope.allowedSharePartners = [];

        $scope.is3dsFieldSetValid = true;

        var Default3ds = {
            enabled: false,
            sandbox: true,
            continue3dsFail: true,
            skipUnsupportedBrands: false,
            apiKey: null,
            secret: null,
            options: {
                authenticationInd: "01",
                challengeIndicator: "01",
                shipIndicator: "01",
                deliveryTimeFrame: "01",
                reorderItemsInd: "01",
            },
        };

        $scope.integration = {
            threeDs: _.cloneDeep(Default3ds),
        };

        $scope.fieldEditRef = undefined;
        $scope.fieldTemplateEditRef = undefined;

        $scope.canEdit = true;
        $scope.canCopy = false;
        $scope.canPreview = false;
        $scope.canDelete = false;

        $scope.showPartners = $scope.showClients = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

        // https://docs.3dsintegrator.com/v2.1/reference#post_authenticate-browser
        $scope.authenticationInds = [
            { value: "01", title: "Payment transaction" },
            { value: "02", title: "Recurring transaction" },
            { value: "03", title: "Installment transaction" },
            // {value: '04', title: 'Add card'},
            // {value: '05', title: 'Maintain Card'},
            { value: "06", title: "Card holder verification as part of EMV token ID&V" },
        ];

        $scope.challengeIndicators = [
            { value: "01", title: "No preference" },
            { value: "02", title: "No challenge requested" },
            { value: "03", title: "Challenge requested (3DS Requestor preference)" },
            { value: "04", title: "Challenge requested (Mandate)" },
        ];

        $scope.shipIndicators = [
            { value: "01", title: "Ship to cardholder's billing address" },
            { value: "02", title: "Ship to another verified address on file with merchant" },
            { value: "03", title: "Ship to address that is different than the cardholder's billing address" },
            { value: "04", title: "Ship to Store / Pick-up at local store (Store address shall be populated in shipping address fields)" },
            { value: "05", title: "Digital goods (includes online services, electronic gift cards and redemption codes)" },
            { value: "06", title: "Travel and Event tickets, not shipped" },
            { value: "07", title: "Other (for example, Gaming, digital services not shipped, emedia subscriptions, etc.)" },
        ];

        $scope.deliveryTimeFrames = [
            { value: "01", title: "Electronic Delivery" },
            { value: "02", title: "Same day shipping" },
            { value: "03", title: "Overnight shipping" },
            { value: "04", title: "Two-day or more shipping" },
        ];

        $scope.reorderItemsInds = [
            { value: "01", title: "First time ordered" },
            { value: "02", title: "Reordered" },
        ];

        $scope.tooltips = {
            threeDs: {
                title: "3DS configuration section",
            },
        };

        $scope.trustSrc = function (src) {
            return $sce.trustAsResourceUrl(src);
        };

        $scope.preview = function () {
            // was not able to perform client side validation or use second daasbutton
            // daasbutton submits the form
            var t = _.clone($scope.template);
            t.Configuration = $scope._buildConfiguration();
            core.template.generatePreviewLink(t).then(function (r) {
                console.log("preview", r.link);
                $scope.link = r.link;
                $scope.template.iframeCode = '<iframe border="0" src="' + r.link + '" id="payment_iframe"></iframe>';
                $scope.modalInstance = $modal.open({ size: "lg", templateUrl: "template-preview.html", scope: $scope });

                $scope.modalInstance.rendered.then(function () {
                    core.template.loadPreview("#iframe_modal", r.data);
                });
            });
        };

        $scope.openStyleEditor = function () {
            var modalInstance = $modal.open({
                templateUrl: "/partials/template/style-editor.html",
                controller: "TemplateStyleEditorCtrl",
                backdrop: "static",
                size: "lg",
                scope: $scope,
            });
        };

        $scope.clipboard = new Clipboard(".clipboard");

        $scope.clipboard.on("success", function () {
            core.ui.success("Code copied successfully");
        });

        $scope.clipboard.on("error", function () {
            core.ui.error("Error copying code");
        });

        $scope.$on("$destroy", function () {
            if ($scope.clipboard) {
                $scope.clipboard.destroy();
            }
        });

        $scope.sample = function () {
            var t = $scope.template;
            core.template.generateSampleLink(t).then(function (link) {
                window.open(link, "sample");
            });
        };

        var _match3dsField = function (f, sf) {
            return f.name == sf.name || (f.options.threeDsType && f.options.threeDsType === sf.options.threeDsType);
        };

        var _match3dsTemplate = function (t, st) {
            return t.templateType == st.templateType;
        };

        var _validate3dsControls = function () {
            if ($scope.integration.threeDs.enabled) {
                return core.template
                    .getThreeDsFields()
                    .then(function (fields) {
                        // check all 3ds fields are in place and it's required
                        var missedFields = _.some(fields, function (f) {
                            var x = _.find($scope.fields, function (sf) {
                                return _match3dsField(f, sf);
                            });

                            var fieldExistsAndRequired = !f.required || (!!x && x.required);
                            return !fieldExistsAndRequired;
                        });

                        return !missedFields;
                    })
                    .then(function (allFieldsExist) {
                        $scope.is3dsFieldSetValid = allFieldsExist;
                    });
            } else {
                $scope.is3dsFieldSetValid = true;
                return $q.resolve();
            }
        };

        $scope.confirmPublish = function () {
            if ($scope.template.Active === 1) {
                return _validate3dsControls().then(function () {
                    if ($scope.is3dsFieldSetValid) {
                        return core.ui.showConfirmDialog("Confirmation", "Are you sure you want to publish template? You will not be able to modify it after published.").then(
                            function () {
                                $scope.template.Active = 1;
                            },
                            function () {
                                $scope.template.Active = 0;
                            },
                        );
                    } else {
                        $scope.template.Active = 0;
                    }
                });
            }
        };

        $scope.clone = function () {
            $scope.cloneTemplate = {
                Id: $scope.template.Id,
                Name: $scope.template.Name + " Copy",
            };
            $scope.modalInstance = $modal.open({
                templateUrl: "ref-clone.html",
                scope: $scope,
            });
            return $scope.modalInstance.result.then(function () {
                return core.template.clone($scope.cloneTemplate).then(function (id) {
                    core.ui.success("Template has been cloned.");
                    $location.path("/manage/templates/" + id);
                });
            });
        };

        $scope.apply = function () {
            // validation should be checked here
            // or the method should not be fired in case invalid form
            // also loading not shown and button not disabled
            return $scope._save(false);
        };

        $scope.save = function () {
            return $scope._save(true);
        };

        $scope._save = function (closable) {
            core.ui.clearErrors($scope)();

            // regenerate
            _generateFieldsFromTemplates().then(function () {
                var t = $scope.template;
                t.Configuration = $scope._buildConfiguration();

                var postAction = closable ? $scope.close : function () {};

                if ($scope.template.Id > 0) {
                    var changed = $scope.ref.Reference !== $scope.template.Reference;
                    var _update = function () {
                        return core.template
                            .update(t)
                            .then(function (r) {
                                // for apply button i have to reload data to fetch the latest version number
                                return core.template.get($scope.template.Id).then(function (t) {
                                    $scope.template = t;
                                    return r;
                                });
                            })
                            .then(function (r) {
                                core.ui.showResult(r, "Template has been updated.", postAction);
                            }, core.ui.showValidation($scope));
                    };

                    if (changed) {
                        $scope.modalInstance = $modal.open({ templateUrl: "ref-confirm.html", scope: $scope });
                        return $scope.modalInstance.result.then(_update);
                    } else {
                        return _update();
                    }
                } else {
                    return core.template.create(t).then(function (r) {
                        core.ui.showResult(r, "Template has been added.", $scope.close);
                    }, core.ui.showValidation($scope));
                }
            });
        };

        $scope._buildConfiguration = function () {
            var config = {
                templateVersion: $scope.templateVersion,
                fields: $scope.fields,
                fieldTemplates: $scope.fieldTemplates,
                options: $scope.options,
                integration: $scope.integration,
            };
            return JSON.stringify(config);
        };

        $scope.delete = function () {
            core.ui.showConfirmDialog("Confirmation", "Are you sure you want to delete template?").then(function () {
                core.template.delete($scope.template).then(function (r) {
                    core.ui.showResult(r, "Template has been deleted successfully.", $scope.close);
                }, core.ui.showValidation($scope));
            });
        };

        $scope.close = function () {
            $location.path("/manage/templates");
        };

        $scope.changePartner = function () {
            _loadClients();
            _loadSharePartners();
        };

        $scope.accept = function () {
            $scope.modalInstance.close();
        };

        $scope.deny = function () {
            $scope.modalInstance.dismiss("cancel");
        };

        $scope.addField = function () {
            var max = _.maxBy($scope.fields, "options.sortOrder");
            var f = {
                required: true,
                options: {
                    inputType: "input",
                    sortOrder: max ? max.options.sortOrder + 1 : 1,
                },
            };
            _editFieldDetails(f);
        };

        $scope.editField = function (f) {
            $scope.fieldEditRef = f;
            _editFieldDetails(_.cloneDeep(f));
        };

        $scope.removeField = function (f) {
            _.remove($scope.fields, { name: f.name });
        };

        /* templates*/

        $scope.addFieldTemplate = function () {
            var max = _.maxBy($scope.fieldTemplates, "sortOrder");
            var f = {
                key: core.ui.generateHash(core.ui.generateUnique()),
                required: true,
                sortOrder: max ? max.sortOrder + 1 : 1,
            };
            _editFieldTemplateDetails(f);
        };

        $scope.editFieldTemplate = function (f) {
            $scope.fieldTemplateEditRef = f;
            _editFieldTemplateDetails(_.cloneDeep(f));
        };

        $scope.removeFieldTemplate = function (f) {
            _.remove($scope.fieldTemplates, { key: f.key });
        };

        /* end templates*/

        $scope.generateReference = function () {
            var key = core.ui.generateUnique();
            var keymd5 = core.ui.generateHash(key);
            $scope.template.Reference = keymd5;
        };

        $scope.alphabetTitle = function (v) {
            var t = "";
            if (v) {
                t = alphabets[v.toLowerCase()].title;
            }
            return t;
        };

        $scope.dataTypeTitle = function (v) {
            var t = "";
            if (v) {
                var dt = _.find(dataTypes, { value: v });
                t = dt ? dt.title : "<< obsolete >>";
            }
            return t;
        };

        $scope.inputTypeTitle = function (v) {
            var t = "";
            if (v) {
                var dt = _.find(inputTypesArrays, { value: v });
                t = dt ? dt.title : "<< obsolete >>";
            }
            return t;
        };

        $scope.templateTypeTitle = function (v) {
            var t = "";
            if (v) {
                var dt = _.find(fieldTemplateTypesArray, { value: v });
                t = dt ? dt.title : "<< obsolete >>";
            }
            return t;
        };

        $scope.change3ds = function () {
            $scope.is3dsFieldSetValid = true;

            if ($scope.integration.threeDs.enabled) {
                if ($scope.templateVersion == "1.1") {
                    _generate3dsTemplates();
                } else {
                    _generate3dsFields();
                }
            } else {
                $scope.integration.threeDs = _.cloneDeep(Default3ds);
            }
        };

        $scope.changeAccessMode = function () {
            switch ($scope.template.AccessMode) {
                case $enums.TemplateAccessMode.restricted:
                case $enums.TemplateAccessMode.unrestricted:
                    // reset acl
                    $scope.options.security = _.defaults(
                        {
                            acl: {},
                        },
                        $scope.options.security,
                    );
                    break;
                case $enums.TemplateAccessMode.specific:
                    break;
            }
        };

        $scope.changePartnerSecurity = function (partner) {
            var partnerId = partner.PartnerId;
            var acl = $scope.options.security.acl;
            if (acl && acl[partnerId] && acl[partnerId].permissions && !acl[partnerId].permissions.tokenize && !acl[partnerId].permissions.detokenize) {
                delete acl[partnerId];
            }
        };

        $scope.addDomain = function () {
            var modalInstance = $modal.open({
                templateUrl: "ref-domain-whitelist.html",
                controller: "WhiteilstDomainDetailsCtrl",
            });

            modalInstance.result.then(function (d) {
                if (!$scope.options.domains.includes(d)) {
                    $scope.options.domains.push(d);
                }
            });
        };

        $scope.removeDomain = function (d) {
            _.remove($scope.options.domains, function (e) {
                return e === d;
            });
        };

        var _generate3dsFields = function () {
            core.template.getThreeDsFields().then(function (fields) {
                var fieldsToAdd = _.filter(fields, function (f) {
                    var x = _.find($scope.fields, function (sf) {
                        return _match3dsField(f, sf);
                    });
                    return !x;
                });

                $scope.fields = $scope.fields.concat(fieldsToAdd);
            });
        };

        var _generate3dsTemplates = function () {
            core.template.getThreeDsTemplates().then(function (templates) {
                var templatesToAdd = _.filter(templates, function (t) {
                    t.key = core.ui.generateHash(core.ui.generateUnique());
                    var x = _.find($scope.fieldTemplates, function (st) {
                        return _match3dsTemplate(t, st);
                    });
                    return !x;
                });

                $scope.fieldTemplates = $scope.fieldTemplates.concat(templatesToAdd);
            });
        };

        var _loadClients = function () {
            if ($scope.showClients && $scope.template.PartnerId) {
                core.client.findByPartner($scope.template.PartnerId).then(function (c) {
                    $scope.clients = c;
                });
            } else {
                $scope.clients = [];
            }
        };

        var _loadSharePartners = function () {
            core.templateSharing.getAllowedTemplateSharingPartners($scope.template.PartnerId).then(function (p) {
                $scope.allowedSharePartners = p;
            });
        };

        var _editFieldDetails = function (f) {
            var modalInstance = $modal.open({
                templateUrl: "/partials/template/field-details.html",
                controller: "TemplateFieldDetailsCtrl",
                backdrop: "static",
                keyboard: false,
                size: "lg",
                resolve: {
                    field: function () {
                        return f;
                    },
                    canEdit: function () {
                        return $scope.canEdit;
                    },
                    extras: function () {
                        return {
                            threeDsEnabled: $scope.integration.threeDs.enabled,
                        };
                    },
                },
            });

            modalInstance.result.then(
                function (f) {
                    if ($scope.fieldEditRef) {
                        var ref = _.find($scope.fields, { name: $scope.fieldEditRef.name });
                        _.merge(ref, f);
                        _.assign(ref, { luhnCheck: f.luhnCheck }); // handle luhnCheck = undefined
                    } else {
                        while (_.some($scope.fields, { name: f.name })) {
                            core.ui.warning("Field with '" + f.name + "' already present. Please set unique name");
                            f.name += "1";
                        }
                        $scope.fields.push(f);
                    }

                    $scope.fieldEditRef = undefined;
                },
                function () {
                    $scope.fieldEditRef = undefined;
                },
            );
        };

        var _generateFieldsFromTemplates = function () {
            if ($scope.templateVersion == "1.1") {
                // $scope.fields = core.template.mapTemplates($scope.fieldTemplates, true);
                return core.template.mapTemplates($scope.fieldTemplates, true).then(function (fields) {
                    $scope.fields = fields;
                });
            } else {
                return $q.resolve();
            }
        };

        var _editFieldTemplateDetails = function (ft) {
            var modalInstance = $modal.open({
                templateUrl: "/partials/template/field-template-details.html",
                controller: "TemplateFieldTemplateDetailsCtrl",
                backdrop: "static",
                keyboard: false,
                size: "lg",
                resolve: {
                    fieldTemplate: function () {
                        return ft;
                    },
                    fieldTemplates: function () {
                        return $scope.fieldTemplates;
                    },
                    fieldTemplateEditRef: function () {
                        return $scope.fieldTemplateEditRef;
                    },
                    canEdit: function () {
                        return $scope.canEdit;
                    },
                    extras: function () {
                        return {
                            threeDsEnabled: $scope.integration.threeDs.enabled,
                        };
                    },
                },
            });

            modalInstance.result
                .then(
                    function (f) {
                        if ($scope.fieldTemplateEditRef) {
                            var ref = _.find($scope.fieldTemplates, { key: $scope.fieldTemplateEditRef.key });
                            _.merge(ref, f);
                            _.assign(ref, { luhnCheck: f.luhnCheck });
                        } else {
                            $scope.fieldTemplates.push(f);
                        }

                        $scope.fieldTemplateEditRef = undefined;

                        return _generateFieldsFromTemplates();
                    },
                    function (err) {
                        $scope.fieldTemplateEditRef = undefined;
                    },
                )
                .catch(function (err) {
                    console.error(err);
                });
        };

        $scope.dragFieldControlListeners = {
            orderChanged: function (event) {
                $scope.fields.forEach((e, i) => {
                    e.options.sortOrder = i + 1;
                });
            },
        };

        $scope.dragTemplateControlListeners = {
            orderChanged: function (event) {
                $scope.fieldTemplates.forEach((e, i) => {
                    e.sortOrder = i + 1;
                });
            },
        };

        (function () {
            if (parseInt($routeParams.id) > 0) {
                $scope.canPreview = $scope.canCopy = true;
                core.template.get($routeParams.id).then(function (t) {
                    $scope.template = t;
                    $scope.ref.Reference = t.Reference;
                    $scope.canEdit = $scope.canDelete = t.Active === 0;

                    var config = t.Configuration ? JSON.parse(t.Configuration) : { fields: [], options: {} };
                    $scope.templateVersion = config.templateVersion || "1.0";
                    $scope.fields = config.fields || [];
                    $scope.fieldTemplates = config.fieldTemplates || [];
                    $scope.options = Object.assign({ domains: [] }, config.options);
                    $scope.integration = config.integration || $scope.integration;
                    _loadClients();
                    _loadSharePartners();
                });
            }
        })();
    },
]);

portal.controller("TemplateFieldDetailsCtrl", [
    "$scope",
    "$location",
    "$uibModalInstance",
    "field",
    "canEdit",
    "coreService",
    "extras",
    function ($scope, $location, $modalInstance, field, canEdit, core, extras) {
        var resetNonIdempotentTokens = function () {
            $scope.field.nonIdempotentTokens = $scope.field.alphabet === alphabets.card10.value && $scope.field.format == formats.NONE.value && $scope.field.dataType == "credit_card" ? $scope.field.nonIdempotentTokens || false : undefined;
        };

        $scope.config = {
            dataTypes: dataTypes,
            methods: methods,
            inputTypes: inputTypesArrays,
        };

        $scope.formats = [];

        $scope.field = field;
        $scope.field.required = _.isNil(field.required) || field.required; // force true if undefined
        $scope.dataType = field.dataType ? _.find($scope.config.dataTypes, { value: $scope.field.dataType }) : undefined;
        $scope.method = field.method ? _.find($scope.config.methods, { value: $scope.field.method }) : undefined;

        $scope.minMax = { min: undefined, max: undefined };

        $scope.canEdit = canEdit;

        $scope.threeDsEnabled = extras.threeDsEnabled;

        $scope.changeMethod = function () {
            $scope.method = _.find($scope.config.methods, { value: $scope.field.method });

            $scope.dataType = $scope.alphabet = $scope.formats = undefined;
            $scope.field.format = $scope.field.alphabet = $scope.field.dataType = undefined;
        };

        // Note - Per PCI, CVV data is prohibited from being stored

        $scope.tooltips = fieldDetailtTooltips;

        $scope.luhnOptions = luhnOptions;

        $scope.threeDsFieldTypes = threeDsFieldTypes;

        var changeMinMax = function (force) {
            if ($scope.dataType && ($scope.dataType.minLength || $scope.dataType.maxLength)) {
                $scope.minMax.min = $scope.dataType.minLength;
                $scope.minMax.max = $scope.dataType.maxLength;
            } else if ($scope.field.format && $scope.alphabet) {
                var format = _.find($scope.alphabet.formats, { value: $scope.field.format });
                $scope.minMax.min = Math.max($scope.method.minLength, format.minLength);
                $scope.minMax.max = Math.min($scope.method.maxLength, format.maxLength);
            } else {
                $scope.minMax.min = $scope.minMax.max = undefined;
            }

            if (force) {
                $scope.field.options.minLength = $scope.minMax.min;
                $scope.field.options.maxLength = $scope.minMax.max;
            }
        };

        var resetLuhnCheck = function (force) {
            if (force) {
                $scope.field.luhnCheck = $scope.field.nonIdempotentTokens ? false : undefined;
            }
        };

        $scope.changeDataType = function () {
            $scope.dataType = _.find($scope.config.dataTypes, { value: $scope.field.dataType });

            $scope.field.alphabet = $scope.dataType.alphabet.value;
            $scope.field.format = $scope.dataType.format.value;

            $scope.field.options.inputType = $scope.dataType.inputType.value;
            $scope.field.options.inputValues = $scope.dataType.inputValues;
            $scope.field.options.pattern = $scope.dataType.pattern;
            $scope.field.options.placeholder = $scope.dataType.placeholder;

            changeMinMax(true);
            resetLuhnCheck(true);
            resetNonIdempotentTokens();
        };

        $scope.$watch("field.alphabet", function (v1, v2) {
            var force = (_.isNil(v1) && _.isNil(v2)) || v1 !== v2; // skip initial load

            if ($scope.field.alphabet) {
                $scope.alphabet = _.find($scope.method.alphabets, function (a) {
                    return a.alphabet.value === $scope.field.alphabet;
                });

                $scope.formats = $scope.dataType && $scope.dataType.formats ? $scope.dataType.formats : $scope.alphabet.formats;
            } else {
                $scope.alphabet = $scope.formats = undefined;
            }

            resetLuhnCheck(force);
        });

        $scope.$watch("field.format", function (v1, v2) {
            var force = (_.isNil(v1) && _.isNil(v2)) || v1 !== v2; // skip initial load
            changeMinMax(force);
            resetLuhnCheck(force);
        });

        $scope.$watch("field.nonIdempotentTokens", function (v1, v2) {
            var force = (_.isNil(v1) && _.isNil(v2)) || v1 !== v2; // skip initial load
            resetLuhnCheck(force);
        });

        $scope.save = function () {
            console.log("$scope.field", $scope.field);
            if ($scope.field.dataType === "cvv") {
                core.ui.showConfirmDialog("CVV field", "Your Template contains a CVV field - this can be used to facilitate a transaction authorization, but may not be stored for long-term use. Field must be deleted after authorization.").then(function () {
                    $modalInstance.close($scope.field);
                });
            } else {
                $modalInstance.close($scope.field);
            }
        };

        $scope.cancel = function () {
            $modalInstance.dismiss("cancel");
        };

        (function () {
            resetNonIdempotentTokens();
        })();
    },
]);

portal.controller("TemplateFieldTemplateDetailsCtrl", [
    "$scope",
    "$uibModalInstance",
    "fieldTemplate",
    "fieldTemplates",
    "fieldTemplateEditRef",
    "canEdit",
    "coreService",
    "extras",
    function ($scope, $modalInstance, fieldTemplate, fieldTemplates, fieldTemplateEditRef, canEdit, core, extras) {
        $scope.devMode = false;

        var steps = {
            type: "type",
            details: "details",
            extended: "extended",
        };

        $scope.currentStep = steps.type;
        $scope.hasExtended = false;

        $scope.config = {
            fieldTemplateTypes: fieldTemplateTypesArray,
            dataTypes: dataTypes,
            methods: methods,
            formats: formats,
            inputTypes: [inputTypes.input, inputTypes.select, inputTypes.password],
            widths: widths,
            dateSeparators: dateSeparators,
            dateFormats: dateFormats,
            luhnOptions: luhnOptions,
            threedsAddressOptions: threedsAddressOptions,
            phoneInputMaskingTypes: phoneInputMaskingTypes,
            alphabets: [alphabets.card10, alphabets.card62, alphabets.card26],

            cardFields: _.clone(cardFields),
            addressFields: _.clone(addressFields),
        };

        $scope.fieldTemplate = fieldTemplate;
        $scope.method = fieldTemplate.method ? _.find($scope.config.methods, { value: $scope.fieldTemplate.method }) : undefined;

        $scope.minMax = { min: undefined, max: undefined };

        $scope.canEdit = canEdit;

        $scope.threeDsEnabled = extras.threeDsEnabled;

        $scope.tooltips = fieldDetailtTooltips;

        $scope.threeDsFieldTypes = threeDsFieldTypes;

        var resetNonIdempotentTokens = function () {
            const ft = $scope.fieldTemplate;
            $scope.fieldTemplate.nonIdempotentTokens = $scope.fieldTemplate.method === "FPT" && ((ft.templateType === "card" && ft.cardNumberFormat == formats.NONE.value) || ft.format == formats.NONE.value) ? ft.nonIdempotentTokens || false : undefined;
        };

        var changeMinMax = function (force) {
            if ($scope.fieldTemplate.format && $scope.method) {
                var format = _.find(formats, { value: $scope.fieldTemplate.format });
                $scope.minMax.min = Math.max($scope.method.minLength, format.minLength);
                $scope.minMax.max = Math.min($scope.method.maxLength, format.maxLength);
            } else {
                $scope.minMax.min = $scope.minMax.max = undefined;
            }

            if (force) {
                $scope.fieldTemplate.minLength = $scope.minMax.min;
                $scope.fieldTemplate.maxLength = $scope.minMax.max;
            }
        };

        var resetLuhnCheck = function (force) {
            if (force) {
                $scope.fieldTemplate.luhnCheck = $scope.fieldTemplate.nonIdempotentTokens ? false : undefined;
            }
        };

        var resetHasExtended = function () {
            $scope.hasExtended = $scope.fieldTemplate.templateType == fieldTemplateTypes.card || $scope.fieldTemplate.templateType == fieldTemplateTypes.address;
        };

        $scope.fieldTemplateTitle = function () {
            var t = "";
            if ($scope.fieldTemplate.templateType) {
                t = fieldTemplateTypesArray.find((t) => t.value == $scope.fieldTemplate.templateType).title;
            }
            return t;
        };

        $scope.changeMethod = function () {
            $scope.method = _.find($scope.config.methods, { value: $scope.fieldTemplate.method });
            $scope.fieldTemplate.format = formats.NONE.value; // reset to default

            resetNonIdempotentTokens();
            changeMinMax(true);
            updatePattern();
        };

        $scope.changeTemplateType = function () {
            // reset all
            $scope.fieldTemplate = _.pick($scope.fieldTemplate, ["key", "templateType", "sortOrder"]);

            $scope.fieldTemplate.groupID = 0;
            $scope.fieldTemplate.width = "100";
            $scope.fieldTemplate.required = true;
            $scope.fieldTemplate.format = formats.NONE.value;

            // fill defaults
            switch ($scope.fieldTemplate.templateType) {
                case fieldTemplateTypes.email:
                    $scope.fieldTemplate.label = "Email";
                    $scope.fieldTemplate.name = "email";
                    break;
                case fieldTemplateTypes.bank:
                    $scope.fieldTemplate.label = "Bank Account Number";
                    $scope.fieldTemplate.name = "bank_account_number";
                    break;
                case fieldTemplateTypes.routing:
                    $scope.fieldTemplate.label = "Routing Number";
                    $scope.fieldTemplate.name = "routing_number";
                    break;
                case fieldTemplateTypes.tax:
                    $scope.fieldTemplate.label = "Social Security Number";
                    $scope.fieldTemplate.name = "social_security_number";
                    break;
                case fieldTemplateTypes.date:
                    $scope.fieldTemplate.label = "Date";
                    $scope.fieldTemplate.name = "date";
                    $scope.fieldTemplate.dateFormat = "MM-DD-YYYY";
                    $scope.fieldTemplate.dateSeparator = "/";
                    break;
                case fieldTemplateTypes.phone:
                    $scope.fieldTemplate.label = "Phone Number";
                    $scope.fieldTemplate.name = "phone_number";
                    $scope.fieldTemplate.phoneInputMasking = null;
                    break;
                case fieldTemplateTypes.field:
                    $scope.fieldTemplate.inputType = "input";
                    break;
                case fieldTemplateTypes.address:
                    $scope.fieldTemplate.label = "Address";
                    $scope.fieldTemplate.addressLine1Name = "address_line1";
                    $scope.fieldTemplate.addressLine2Name = "address_line2";
                    $scope.fieldTemplate.cityName = "city";
                    $scope.fieldTemplate.countryName = "country";
                    $scope.fieldTemplate.postalCodeName = "postal_code";
                    $scope.fieldTemplate.stateName = "state";

                    $scope.fieldTemplate.threeDsAddress = threedsAddressTypes.none;
                    $scope.fieldTemplate.addressLine1Enabled = $scope.fieldTemplate.addressLine2Enabled = $scope.fieldTemplate.cityEnabled = $scope.fieldTemplate.stateEnabled = $scope.fieldTemplate.countryEnabled = $scope.fieldTemplate.postalCodeEnabled = true;
                    break;
                case fieldTemplateTypes.card:
                    $scope.fieldTemplate.label = "Card Number";
                    $scope.fieldTemplate.cardNumberName = "card_number";
                    $scope.fieldTemplate.expirationName = "card_exp";
                    $scope.fieldTemplate.cvvName = "card_cvv";
                    $scope.fieldTemplate.cardholderName = "card_holder";

                    $scope.fieldTemplate.cardNumberEnabled = $scope.fieldTemplate.expirationEnabled = $scope.fieldTemplate.cvvEnabled = $scope.fieldTemplate.cardholderEnabled = true;

                    $scope.fieldTemplate.cardNumberFormat = $scope.fieldTemplate.expirationFormat = $scope.fieldTemplate.cvvFormat = $scope.fieldTemplate.cardholderFormat = formats.NONE.value;
                    break;
                default:
                    consoler.error("Unknown template type: " + $scope.fieldTemplate.templateType);
                    break;
            }

            resetHasExtended();

            // reset form
            $scope.fieldDetailsForm.$setPristine();
            $scope.fieldExtendedForm.$setPristine();
        };

        $scope.$watch("fieldTemplate.format", function (v1, v2) {
            var force = (_.isNil(v1) && _.isNil(v2)) || v1 !== v2; // skip initial load
            changeMinMax(force);
            resetLuhnCheck(force);
        });

        $scope.$watch("fieldTemplate.cardNumberFormat", function (v1, v2) {
            var force = fieldTemplate.templateType === "card" && ((_.isNil(v1) && _.isNil(v2)) || v1 !== v2); // skip initial load

            changeMinMax(force);
            resetLuhnCheck(force);
        });

        $scope.$watch("fieldTemplate.nonIdempotentTokens", function (v1, v2) {
            var force = (_.isNil(v1) && _.isNil(v2)) || v1 !== v2; // skip initial load
            resetLuhnCheck(force);
        });

        $scope.$watch("fieldTemplate.alphabet", function (v1, v2) {
            updatePattern();
        });

        var _findDuplicates = function () {
            var templates = _.clone(fieldTemplates);
            if (!fieldTemplateEditRef) {
                templates.push($scope.fieldTemplate);
            } else {
                var current = templates.find(function (t) {
                    return t.key === $scope.fieldTemplate.key;
                });
                _.assign(current, $scope.fieldTemplate);
            }

            return core.template.findDuplicates(templates);
        };

        var updatePattern = function () {
            if ($scope.method) {
                const alphabet = _.find($scope.method.alphabets, { alphabet: { value: $scope.fieldTemplate.alphabet } });
                $scope.fieldTemplate.pattern = alphabet ? alphabet.pattern : null;
                $scope.fieldTemplate.patternMessage = alphabet ? alphabet.patternMessage : null;
            }
        };

        $scope.addressFieldFilter = function (f) {
            return !!$scope.fieldTemplate[f.key + "Enabled"];
        };

        $scope.cardFieldFilter = function (f) {
            return !!$scope.fieldTemplate[f.key + "Enabled"];
        };

        var _showDuplicates = function (duplicates) {
            // summary
            $scope.errors = duplicates
                .filter(function (f) {
                    return f.templateKey === $scope.fieldTemplate.key;
                })
                .map(function (f) {
                    return {
                        description: "System Name '" + f.name + "' in the field '" + f.label + "' is already in use. The System Name must be unique for a given template.",
                        extra: {
                            title: f.label,
                        },
                    };
                });

            // inline
            /*_.forEach(duplicates, function(d){
                var fieldKey = d.templateFieldKey || 'name';
                var formItem = $scope.fieldDetailsForm['field-name-' + fieldKey] || $scope.fieldExtendedForm['field-name-' + fieldKey];
                if(formItem) {
                    formItem.$setValidity('nameNotUnique', false)
                }
            });*/
        };

        $scope.save = function () {
            _findDuplicates().then(function (duplicates) {
                if (duplicates.length > 0) {
                    _showDuplicates(duplicates);
                } else {
                    if ($scope.fieldTemplate.templateType === "card" && $scope.fieldTemplate.cvvEnabled) {
                        core.ui.showConfirmDialog("CVV field", "Regulations state that CVV's codes should never be stored. Please ensure that you do not store this value at any point.", "Got it", null, true).then(function () {
                            $modalInstance.close($scope.fieldTemplate);
                        });
                    } else {
                        $modalInstance.close($scope.fieldTemplate);
                    }
                }
            });
        };

        $scope.cancel = function () {
            $modalInstance.dismiss("cancel");
        };

        $scope.toTemplateType = function () {
            $scope.currentStep = steps.type;
        };

        $scope.toDetails = function () {
            $scope.currentStep = steps.details;
        };

        $scope.toExtended = function () {
            $scope.currentStep = steps.extended;
        };

        (function () {
            resetNonIdempotentTokens();
            resetHasExtended();
        })();
    },
]);

portal.controller("TemplateCloneCtrl", [
    "$scope",
    "$location",
    "$uibModalInstance",
    "template",
    function ($scope, $location, $modalInstance, template) {
        $scope.template = template;

        $scope.save = function () {
            $modalInstance.close($scope.template);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss("cancel");
        };
    },
]);

portal.controller("WhiteilstDomainDetailsCtrl", [
    "$scope",
    "$uibModalInstance",
    function ($scope, $modalInstance) {
        $scope.domain = {
            value: "",
        };

        $scope.save = function () {
            $modalInstance.close($scope.domain.value);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss("cancel");
        };
    },
]);

portal.controller("TemplateStyleEditorCtrl", [
    "$scope",
    "$uibModalInstance",
    "coreService",
    function ($scope, $modalInstance, core) {
        $scope.styleEditor = {};

        const cssDefaults = {
            headerText: null,
            headerTextAlign: "left",
            headerFontSize: "24",
            headerHorizontalLine: false,
            bodyBackgroundColor: "#FFFFFF",
            bodyColor: "#000000",
            bodyFontFamily: "Helvetica",
            bodyFontSize: "14",
        };

        $scope.customCss = {};

        $scope.save = function () {
            $scope.options.customCss = $scope.customCss;
            $modalInstance.close();
        };

        $scope.apply = function () {
            $scope.options.customCss = _.clone($scope.customCss);
            _reloadFrame();
        };

        $scope.cancel = function () {
            $modalInstance.dismiss("cancel");
        };

        var _reloadFrame = function (load) {
            var t = _.clone($scope.template);
            t.Configuration = $scope._buildConfiguration();
            return core.template.generatePreviewLink(t).then(function (r) {
                $scope.styleEditor.link = r.link;
                if (load) {
                    core.template.loadPreview("#iframe_modal", r.data);
                } else {
                    core.template.renderPreview("#iframe_modal", r.data);
                }
            });
        };

        (function () {
            $scope.customCss = _.assign({}, cssDefaults, $scope.options.customCss);
            _reloadFrame(true);
        })();
    },
]);
