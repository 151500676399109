"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("clientService", ["httpService", "$q", "authService", "$location",
    function (httpService, $q, auth, $location) {
        var service = {};
        service.get = function (id) {
            return  httpService.get("/api/client/" + id);
        };
        service.getDetails = function (id) {
            return  httpService.get("/api/client/details/" + id);
        };
        service.create = function (record) {
            return  httpService.put("/api/client/", record);
        };
        service.update = function (record) {
            return  httpService.post("/api/client/", record);
        };
        service.findByPartner = function (partnerId) {
            partnerId = partnerId || "";
            return httpService.get("/api/client/bypartner/" + partnerId);
        };
        service.documents = function () {
            // todo [as]: check if it should be post
            return  httpService.post("/api/client/documents", {});
        };

        service.delete = function(clientId){
            return  httpService.delete("/api/client/" + clientId);
        };

        service.updatePrimaryContact = function(clientId, primaryContactId){
            return  httpService.put("/api/client/" + clientId + '/primary/' + primaryContactId);
        };

        return service;
    }]);


/*****************
 CONTROLLERS
 ******************/

portal.controller("ClientListCtrl", ["$scope", "$location", "coreService",
    function ($scope, $location, core) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, '/manage/clients');
        };
        $scope.filter = core.filter;
        $scope.dtConfig = {
            ajax: {
                url: "/api/client/list",
                data: function (d) {
                    d.filter = $scope.filter;
                }
            },
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: "Id", render: r1, name: "c.Name", width: "5%"},
                {targets: [1], data: "Name", title: "Name", name: "c.Name", width: "25%"},
                {targets: [2], render: core.dt.renderUserEx, name: [ "u.LastName", "u.FirstName"], title: "Contact Name", width: "20%" },
                {targets: [3], data: "Email", title: "Email", name: "u.Email", width: "20%" },
                {targets: [4], data: "CardConexId", title: "Billing ID", name: "c.CardConexId", width: "15%" },
                {targets: [5], data: "Mid", title: "Mid", name: "c.Mid", width: "15%" }
            ],
            createLink: "/manage/clients/0",
            reloadEvent: "reloadList",
            fnRowCallback: function( nRow, aData) {
                if(!aData.IsActive) {
                    $(nRow).addClass("text-muted");
                }
            }
        };

        $scope.showFilter = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };

        $scope.clear = function () {
            $scope.filter.partner = null;
            $scope.filter.status = '';
            $scope.reload();
        };
    }]);

portal.controller("ClientDetailsCtrl", ["$scope", "$location", "$routeParams", "$uibModal", "coreService",
    function ($scope, $location, $routeParams, $modal, core) {
        $scope.client = {TfaRememberDevice : 0};
        $scope.location = {};
        $scope.user = {
            sendWelcomeEmail:  parseInt($routeParams.id) === 0,
            IsActive: 1
        };

        var isSystemOrPartnerUser = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

        $scope.showPartners = isSystemOrPartnerUser;
        $scope.isEdit = parseInt($routeParams.id) > 0;
        $scope.allowDelete = isSystemOrPartnerUser && $scope.isEdit;
        $scope.modifyPrimaryContact = $scope.isEdit;

        $scope.save = function () {
            var p = {client: $scope.client, user: $scope.user, location: $scope.location};
            // console.log("saving client: ", p);
            if ($scope.client.Id > 0) {
                return core.client.update(p).then(function (r) {
                    core.ui.showResult(r, "Client has been updated.", $scope.close);
                }, core.ui.showValidation($scope));
            } else {
                return core.client.create(p).then(function (r) {
                    core.ui.showResult(r, "Client has been added.", $scope.close);
                }, core.ui.showValidation($scope));
            }
        };

        $scope.confirmDelete = function () {
            core.ui.showConfirmDialog('Confirmation', 'Are you sure you wish to delete client and all related data (Users)?')
                .then(function(){
                    core.client.delete($scope.client.Id).then(function (r) {
                        core.ui.showResult(r, "Client has been deleted successfully.", $scope.close);
                    }, core.ui.showValidation($scope));
                });
        };

        $scope.close = function () {
            $location.path("/manage/clients");
        };

        $scope.updatePrimaryContact = function(){
            var modalInstance = $modal.open({
                templateUrl: "/partials/manage/client.contact.html",
                controller: 'PrimaryContactUpdateCtrl',
                resolve: {
                    data: function () {
                        return {
                            clientId: $scope.client.Id
                        };
                    }
                }
            });

            return modalInstance.result
                .then(function () {
                    core.ui.success("Primary Contact has been update successfully.");
                    load();
                });
        };

        var load = function () {
            if ($routeParams.id > 0) {
                core.client.getDetails($routeParams.id).then(function (u) {
                    $scope.client = u.client;
                    $scope.location = u.location;
                    $scope.user = u.user;
                });
            }
        };

        (function () {
            load();
        })();
    }
]);

portal.controller("PrimaryContactUpdateCtrl", ["$scope", "coreService", "$uibModalInstance", "$q", "data",
    function ($scope, core, $modalInstance, $q, data) {

        $scope.data = data;
        $scope.primaryContactId = null;

        $scope.save = function () {
            return core.client.updatePrimaryContact(data.clientId, $scope.primaryContactId)
                .then(function (res) {
                    $modalInstance.close(res);
                }, core.ui.showValidation($scope));
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    }
]);
