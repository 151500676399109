"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("partnerService", ["httpService", "$q",
    function (httpService, $q) {
        var service = {};

        service.get = function (id) {
            return  httpService.get("/api/partner/" + id);
        };

        service.getDetails = function (id) {
            return  httpService.get("/api/partner/details/" + id);
        };

        service.create = function (record) {
            return  httpService.put("/api/partner/", record);
        };

        service.update = function (record) {
            return  httpService.post("/api/partner/", record);
        };

        service.all = function () {
            //console.warn('partner.all is obsolete...');
            return httpService.get("/api/partner");
        };

        service.treeIndent = function (currentPartnerId) {
            return httpService.get("/api/partner/tree/indent/" + (currentPartnerId || 0));
        };

        service.treeFull = function (currentPartnerId, onlyActive, fullHierarchy) {
            var url = "/api/partner/tree/full/" + (currentPartnerId || 0) + '/' + (onlyActive ? '1' : '0')+ '/' + (fullHierarchy ? '1' : '0');
            return httpService.get(url);
        };

        service.breadcrumbs = function (partnerId, separator) {
            // todo [as]: check if it should be post
            return httpService.post("/api/partner/breadcrumbs", {partnerId: partnerId, separator: separator });
        };

        service.delete = function(partnerId){
            return httpService.delete("/api/partner/" + partnerId);
        };

        service.saveDebugModeStatus = function(partners){
            var data = _.map(partners, _.partialRight(_.pick, ['Id', 'DebugModeStatus']));
            return httpService.patch("/api/partner/debugModeStatus", data);
        };

        return service;
    }]);


/*****************
 CONTROLLERS
 ******************/

portal.controller("PartnerListCtrl", ["$scope", "$location", "coreService",
    function ($scope, $location, core) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, '/manage/partners');
        };
       $scope.filter = _.merge(core.filter, { status: '' });

        $scope.dtConfig = {
            ajax: {
                url: "/api/partner/list",
                data: function (d) {
                    d.filter = $scope.filter;
                }
            },
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: "Id", render: r1, name: "p.Name", width: "5%"},
                {targets: [1], data: "Name", title: "Name", name: "p.Name", width: "25%"},
                {targets: [2], data: "ParentName", title: "Parent Name", name: "pp.Name", width: "25%"},
                {targets: [3], render: core.dt.renderUserEx, name: [ "u.LastName", "u.FirstName"], title: "Contact Name", width: "25%" },
                {targets: [4], data: "Email", title: "Contact Email", name: "u.Email", width: "25%" },
                {targets: [5], data: "StatusName", title: "Status", name: "ps.Name", width: "20%" },
                {targets: [6], data: "CardConexId", title: "Billing ID", name: "p.CardConexId", width: "20%" }
            ],
            createLink: "/manage/partners/0",
            reloadEvent: "reloadList",
            fnRowCallback: function( nRow, aData) {
                if(aData.StatusId !== 1) {
                    $(nRow).addClass("text-muted");
                }
            }
        };

        $scope.reload = function(){
            $scope.$emit("reloadList");
        };

        $scope.clear = function () {
            $scope.filter.partner = null;
            $scope.filter.status = '';
            $scope.reload();
        };
    }]);

portal.controller("PartnerDetailsCtrl", ["$scope", "$location", "$routeParams", "$uibModal", "coreService",
    function ($scope, $location, $routeParams, $modal, core) {
        $scope.partner = {
            TfaRememberDevice : 0,
            ParentId:  core.auth.isPartnerAnyUser() ?
                core.auth.principal.partnerId :
                    core.auth.isSystemUser() ? 1 : undefined, // preselect parent partner
            AuthorizationType: 'None'
        };
        $scope.location = {};
        $scope.user = {
            sendWelcomeEmail: parseInt($routeParams.id) === 0,
            IsActive: 1
        };
        $scope.statuses = [];

        $scope.ref = {};

        $scope.injectKeys = [];
        $scope.injectKeyMapping = {};

        $scope.isRootPartner = false;
        $scope.allowChangeParent = true;
        $scope.allowChangePartnerKey = core.auth.isSystemAdmin();

        $scope.allowModify = true;
        $scope.allowDelete = false;

        $scope.isCardConexIdRequred = function(){
            return $scope.partner.ParentId === null;
        };

        $scope.save = function () {
            var p = {partner: $scope.partner, user: $scope.user, injectKeyMapping: $scope.injectKeyMapping};

            switch(p.partner.AuthorizationType){
                case "Hmac":
                    p.partner.Secret = $scope.partner.SecretHmac;
                    break;
                case "Rsa":
                    p.partner.Secret = $scope.partner.SecretRsa;
                    break;
                default:
                    p.partner.Secret = null;
                    break;
            }

            if ($scope.partner.Id > 0) {
                var changed = $scope.ref.Reference !== $scope.partner.Reference || $scope.ref.PartnerKey !== $scope.partner.PartnerKey;
                var _update = function(){
                    return core.partner.update(p).then(function (r) {
                        core.ui.showResult(r, "Partner has been updated.", $scope.close);
                    }, core.ui.showValidation($scope));
                };
                if(changed){
                    $scope.modalInstance = $modal.open({templateUrl: 'key-confirm.html', scope: $scope });
                    return $scope.modalInstance.result.then(_update);
                }else{
                    return _update();
                }
            } else {
                return core.partner.create(p).then(function (r) {
                    core.ui.showResult(r, "Partner has been added.", $scope.close);
                }, core.ui.showValidation($scope));
            }
        };

        $scope.generateKey = function(){
            var key = core.ui.generateUnique();
            var keymd5 = core.ui.generateHash(key);
            $scope.partner.PartnerKey = keymd5;
        };

        $scope.generateHmacKey = function(){
            $scope.partner.SecretHmac = core.ui.generateHmacKey();
        };

        $scope.decodedHmac = function() {
            var decoded;
            try {
                decoded = $scope.partner.SecretHmac ?  atob($scope.partner.SecretHmac) : null;
            } catch(e) {
                decoded = 'invalid base64 key';
            }
            return decoded;
        };

        $scope.close = function () {
            $location.path("/manage/partners");
        };

        $scope.accept = function () {
            $scope.modalInstance.close();
        };
        $scope.deny = function () {
            $scope.modalInstance.dismiss('cancel');
        };

        $scope.delete = function(){
            core.ui.showConfirmDialog('Confirmation', 'Are you sure you wish to delete partner and all related data (Sub Partners, Clients, Users)?')
                .then(function(){
                    core.partner.delete($scope.partner.Id).then(function (r) {
                        core.ui.showResult(r, "Partner has been deleted successfully.", $scope.close);
                    }, core.ui.showValidation($scope));
                });
        };

        $scope.activate = function(){
            // statuses
            core.dictionary.getPartnerStatuses()
                .then(function (s) {
                    $scope.statuses = s;
                });            

                if (parseInt($routeParams.id) > 0) {
                    core.partner.getDetails($routeParams.id).then(function (u) {
                        $scope.partner = u.partner;
    
                        $scope.oldPartner = u.partner;
                        $scope.user = u.user;
    
                        $scope.ref.Reference = u.partner.Reference;
                        $scope.ref.PartnerKey = u.partner.PartnerKey;
    
                        $scope.injectKeyMapping = u.injectKeyMapping;
                        // prepend current key to list of availables
                        if(u.injectKeyMapping) {
                            $scope.injectKeys.push({Id: u.injectKeyMapping.InjectKeyId, Name: u.injectKeyMapping.Name});
                        }
    
                        $scope.allowChangeParent = u.partner.ParentId !== null;

                        $scope.isRootPartner = !u.partner.ParentId;
                        $scope.allowModify = !$scope.isRootPartner || core.auth.isSystemAdmin();
                        $scope.allowDelete = !$scope.isRootPartner && (core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser());
    
                        switch($scope.partner.AuthorizationType){
                            case "Hmac":
                                $scope.partner.SecretHmac = $scope.partner.Secret;
                                break;
                            case "Rsa":
                                $scope.partner.SecretRsa = $scope.partner.Secret;
                                break;
                            default:
                                break;
                        }
                    });
                }
        };

        /*
        // it is just a an example how to turn off automatic reloading ofter signon
        // because of several controls with own activation code which are not refernced
        // from $scope.activate it is a bad idea to uncomment [$scope.$on('authorizedReload',...]
        $scope.$on('authorizedReload', function(src, ev){
            ev.cancel = true;
            $scope.activate();
        });
        */

        (function () {
            $scope.activate();
        })();
    }
]);
