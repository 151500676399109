"use strict";
var portal = angular.module("portal");

/*****************
 CONTROLLERS
 ******************/
portal.controller("ProfileCtrl", ["$scope", "$rootScope", "uiService", "coreService",
    function ($scope, $rootScope, uiService, core) {
        $scope.user = {};

        $scope.save = function () {
            $scope.errorsKey = 'profile';
            return core.user.update($scope.user)
                .then(function (r) {
                    // reload
                    _load().then(function(){
                        uiService.showResult(r, "Account has been updated");
                    });
                }, core.ui.showValidation($scope));
        };

        var _load = function(){
            return core.user.get($rootScope.principal.id)
                .then(function (u) {
                    $scope.user = u;
                    $scope.user.RoleName = core.ui.getRoleName($scope.user.UserRoleId);
                });
        };

        (function () {
            _load();
        })();
    }
]);


portal.controller("SettingsAccountCtrl", ["$scope", "coreService", "$location",
    function ($scope, core, $loation) {
        $scope.pass = {};

        $scope.reset = function () {
            $scope.errorsKey = 'reset';
            core.user.reset($scope.pass)
                .then(function (r) {
                    _clear();
                    core.ui.showResult(r, "Password has been changed");
                    core.user.logout().then(function() {
                        $loation.path("/login");
                    });
                }, core.ui.showValidation($scope));
        };

        var _clear = function(){
            $scope.pass  = {};
            $scope.changePasswordForm.$setPristine();
        };
    }
]);

