"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("documentationService", ["httpService", "$q",
    function (httpService, $q) {
        var service = {};
        service.listDocuments = function (access, partnerId) {
            return  httpService.get("/api/documentation?" + (partnerId ? 'partner=' + partnerId + '&' : '') + (access ? 'access=' + access : ''));
        };

        service.deleteDocument = function (key) {
            return  httpService.post("/api/documentation/delete/", {key: key});
        };

        service.access = {
            partner: 'partner',
            client: 'client'
        };

        service.accesses = [
            {key: service.access.partner, value: "Partner"},
            {key: service.access.client, value: "Client"}
        ];

        service.category = {
            general: 'General',
            guide: 'User Guides',
            security: 'Security Education',
            video: 'Video Library'
        };

        service.categories = [];
        _.forOwn(service.category, function(k, v){
            service.categories.push({key: v, value: k});
        });

        return service;
    }]);


/*****************
 CONTROLLERS
 ******************/
portal.controller("DocumentationListCtrl", ["$scope", "$location", "$uibModal", "$compile", "coreService",
    function ($scope, $location, $modal, $compile, core) {
        $scope.data = [];
        $scope.loading = false;
        $scope.filter = core.filter;
        $scope.filter.access = core.documentation.access.partner;
        $scope.allowDelete = $scope.showFilter = core.auth.isSystemAnyUser();

        $scope.accesses = core.documentation.accesses;

        $scope.findCategoryTitle = function(category) {
            var cat = _.find(core.documentation.categories, function(c){
                return c.key === category;
            });
            return cat ? cat.value : category;
        };

        $scope.reload = function () {
            $scope.data = [];

            $scope.loading = true;
            core.documentation.listDocuments($scope.filter.access, $scope.filter.partner)
                .then(function (d) {
                    $scope.data = d;
                })
                .finally(function(){
                    $scope.loading = false;
                });
        };

        $scope.confirmDelete = function (key) {
            core.ui.showConfirmDialog('Confirmation', 'Are you sure you wish to delete document?')
                .then(function(){
                    core.documentation.deleteDocument(key).then(function (r) {
                        core.ui.showResult(r, "Document has been deleted successfully.", $scope.close);
                        $scope.reload();
                    }, core.ui.showValidation($scope));
                });
        };

        $scope.open = function () {
            var modalInstance = $modal.open({
                templateUrl: "/partials/documentation/upload.html",
                controller: 'DocumentationUploadCtrl',
                resolve : {
                    access : function() {
                        return $scope.filter.access;
                    }
                }
            });
            modalInstance.result.then($scope.reload);
        };

        (function () {
            $scope.reload();
        })();
    }]);

portal.controller("DocumentationUploadCtrl", ["$scope", "$http", "$uibModalInstance",  "coreService", "ROLES", "access",
    function ($scope, $http, $modalInstance, core, ROLES, access) {

        $scope.document = undefined;
        $scope.filter = core.filter;
        $scope.access = access;
        $scope.category = 'general';
        $scope.categories = core.documentation.categories;
        $scope.accesses = core.documentation.accesses;

        var _buildFormData = function(){
            var formData = new FormData($('#document-upload')[0]);
            if($scope.filter.partner){
                formData.append('partner', $scope.filter.partner);
            }
            return formData;
        };

        $scope.ok = function () {
            var data = _buildFormData();
            return $http.post('/api/documentation/upload', data, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined}
                })
                .then(function(r){
                    core.ui.showResult(r.data, "Document has been added.", function () {
                        $modalInstance.close();
                    });
                }, core.ui.showValidation($scope));
        };
        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    }
]);
