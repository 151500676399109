"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("auditService", ["httpService",
    function (httpService) {
        var service = {};
        service.get = function (id) {
            return httpService.get("/api/audit/" + id);
        };

        return service;
    }]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("AuditListCtrl", ["$scope", "$location", "$compile", "$enums", "coreService",
    function ($scope, $location, $compile, $enums, core) {
        var r1 = function (id) {
            return core.dt.renderView(id, '/manage/eventLogging');
        };

        var r3 = function (s) {
            switch(s) {
                case $enums.AuditSeverity.debug:
                    return 'Debug';
                case $enums.AuditSeverity.info:
                    return 'Info';
                case $enums.AuditSeverity.warn:
                    return 'Warn';
                case $enums.AuditSeverity.error:
                    return 'Error';
            }
        };

        var _defaultFilter = function() {
            return _.merge(core.filter, {
                auditSource: null,
                auditAction: "",
                auditSeverity: $enums.AuditSeverity.info.toString(),
                dateFrom: moment().add(-5, 'day').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate()
            });
        };

        $scope.filter = _defaultFilter();

        $scope.auditActions = $scope.auditSources = [];

        $scope.dtConfig = {
            ajax: {
                url: "/api/audit/list",
                data: function (d) {
                    d.filter = _.clone($scope.filter);
                    d.filter.dateFrom = core.ui.getDate(d.filter.dateFrom);
                    d.filter.dateTo = core.ui.getDate(d.filter.dateTo);
                }
            },
            sorting: [5, 'desc'],
            columnDefs: [
                {targets: [0, 6], sortable: false, searchable: false },
                {targets: [0], data: "Id", render: r1, name: "a.Message", width: "1%"},
                {targets: [1], data: "AuditSourceName", title: "Source", name: "s.Name", width: "5%" },
                {targets: [2], data: "AuditActionName", title: "Action", name: "aa.Name", width: "7%" },
                {targets: [3], data: "Message", title: "Message", name: "a.Message", width: "20%" },
                {targets: [4], data: "ReferenceId", title: "Reference ID", name: "a.ReferenceId", width: "5%" },
                {targets: [5], data: "AuditDate", render: core.dt.renderDate, title: "Date", name: "a.AuditDate", width: "10%" },
                {targets: [6], render: core.dt.renderUserWithDetails, data: "AuditUserId", name: [ "u.LastName", "u.FirstName"], title: "User", width: "10%"},
                {targets: [7], data: "AuditSeverity", render: r3, title: "Severity", name: "a.AuditSeverity", width: "5%" }
            ],
            showTableTools: 'Audit',
            reloadEvent: "reloadList",
            drawCallback: function() {
                $compile($('.user-info-details'))($scope);
            }
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            $scope.reload();
        };

        (function(){
            core.dictionary.getAuditSources()
                .then(function(res) {
                    $scope.auditSources = res;
                });

            core.dictionary.getAuditActions()
                .then(function(res) {
                    $scope.auditActions = res;
                });
        })();
    }]);


portal.controller("AuditDetailsCtrl", ["$scope", "$location", "$routeParams", "$http", "$window", "coreService",
    function ($scope, $location, $routeParams, $http, $window, core) {

        $scope.audit = {
        };

        var _load = function(){
            if ($routeParams.id > 0) {
                core.audit.get($routeParams.id).then(function (audit) {
                    $scope.audit = audit;
                    $scope.audit.ExtendedData = JSON.parse($scope.audit.ExtendedData);
                    $scope.audit.ExtendedDataPretty = JSON.stringify($scope.audit.ExtendedData, null, "\t");

                    // diff
                    var ed = $scope.audit.ExtendedData;
                    if(ed.prev && ed.current) {
                        var jsondiffpatch = $window.jsondiffpatch;
                        // [as] we may need to sanitize system props before sending to diff
                        var delta = jsondiffpatch.diff(ed.prev, ed.current);
                        var html = jsondiffpatch.formatters.html.format(delta, ed.prev);
                        $scope.audit.diffHtml = html;
                    }
                });
            }
        };

        $scope.close = function () {
            $location.path("/manage/eventLogging");
        };

        (function () {
            _load();
        })();
    }
]);
