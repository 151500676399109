"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("transactionService", ["httpService",
    function (httpService) {
        var service = {};

        service.getDetails = function (id, transactionType) {
            return  httpService.get("/api/transaction/" + id + "/" + transactionType);
        };
        return service;
    }]);

/*****************
 CONTROLLERS
 ******************/
portal.controller("TransactionListCtrl", ["$scope", "coreService", "$enums", "$uibModal",
    function ($scope, core, $enums, $modal) {
        var r1 = function () {
            return core.dt.renderIcon('eye-open', 'Edit', 'javascript:void(0)');
        };

        var sortingMode = {
            noSorting: "0",
            withSorting: "1"
        };

        var transactionSource = {
            0: "Shieldconex",
            10: "Gateway"
        };

        $scope.transactionTypes = [
            // {value: null, title: "All Transactions"},
            {value: $enums.TransactionType.tokenizeWrite, title: "Tokenization Transactions"},
            {value: $enums.TransactionType.tokenizeRead, title: "Detokenization Transactions"},
            {value: $enums.TransactionType.partnerValidate, title: "Partner Validate"},
            {value: $enums.TransactionType.templateValidate, title: "Template Validate"},
            {value: $enums.TransactionType.healthcheck, title: "Healthcheck"}
        ];

        var _defaultFilter = function() {
            return _.merge(core.filter, {
                partner: null,
                client: null,
                sortingMode: sortingMode.withSorting,
                transactionType: $enums.TransactionType.tokenizeWrite,
                dateFrom: moment().add( -1, 'days').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate()
            });
        };

        $scope.filter = _defaultFilter();
        $scope.partners = $scope.clients = undefined;
        $scope.hideSearchBox = true;
        $scope.applied = false;

        $scope.dtConfig = {
            deferLoading: 0,
            ajax: {
                url: "/api/transaction/list",
                data: function (d) {
                    d.filter = _.clone(core.filter);

                    var dateFrom = core.ui.getDate(d.filter.dateFrom, $scope.useUtc);
                    var dateTo = core.ui.getDate(d.filter.dateTo, $scope.useUtc);
                    d.filter.dateFrom = dateFrom;
                    d.filter.dateTo = dateTo;
                }
            },
            lengthMenu: [[25, 50, 100, 500, 1000, 5000, 10000], [25, 50, 100, 500, 1000, 5000, 10000]],
            sorting: [
                [0, 'asc']
            ],
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0,1,2,3,4,5,6,7,8,9,/*10,*/11,12,13], sortable: false },
                {targets: [0], data: "Id", render: r1, name: "d.SerialNumber", width: "2%"},
                {targets: [1], data: "PartnerName", title: "Partner", name: "p.Name", width: "10%"},
                {targets: [2], data: "DirectPartnerName", title: "Direct Partner", name: "dp.Name", width: "10%"},
                {targets: [3], data: "ClientName", title: "Client", name: "c.Name", width: "10%" },
                {targets: [4], data: "TemplateName", title: "Template Name", name: "tp.Name", width: "10%" },
                {targets: [5], data: "TemplateReference", title: "Template Reference", name: "tp.Reference", width: "10%" },
                {targets: [6], data: "TransactionType", title: "Transaction Type", name: "tt.Name", width: "10%" },
                {targets: [7], data: "MessageId", title: "Message ID", name: "t.MessageId", width: "10%" },
                {targets: [8], data: "Reference", title: "Reference", name: "t.Reference", width: "10%" },
                {targets: [9], data: "Success", render: core.dt.renderYesNoFromBool, title: "Success", name: "t.Success", width: "10%"},
                {targets: [10], data: "CompleteDate", render: core.dt.renderDateWithSeconds, title: "Completed Date", name: "t.CompleteDate", width: "10%", visible: false },
                {targets: [11], data: "CompleteDate", render: core.dt.renderDateWithSeconds, title: "Completed Date", name: "t.CompleteDate", width: "10%" },
                {targets: [12], data: "Bfid", title: "BFID", name: "t.Bfid", width: "10%" },
                {targets: [13], data: "FieldCount", title: "Fields #", name: "t.FieldCount", width: "10%" }
            ],
            // pagingType: "simple",
            reloadEvent: "reloadListTransactions",
            rowClick: "getTransactionDetails",
            info: false,
            buttons: [
                {
                    exportOptions: {
                        columns: [0,1,2,3,4,5,6,7,8,9,11,/*12,*/13]
                    }
                }
            ],
            showTableTools: 'Transactions',
            /*fnServerParams: function(data){
                // i want to fetch more records than i will show on ui
                // that way i can understand i can show "next page" button
                var dl = _.find(data, function(d){ return d.name === "iDisplayLength"; });
                dl.value++;
            },

            xhr_dt: function(settings, json) {
                if(json.data.length <= settings._iDisplayLength) {
                    // the server returns approximate number of records
                    // but now (when i have less record number than requested)
                    // i can calculate exact record number
                    // also it will disable "next page" button
                    json.recordsFiltered = settings._iDisplayStart + json.data.length;
                    json.recordsTotal = json.recordsFiltered;
                }
                else  {
                    // trim result data to show only required number of records
                    while(json.data.length > settings._iDisplayLength){
                        json.data.pop();
                    }
                }
            }*/
        };

        $scope.$watch("filter.sortingMode", function(v){
            var dt = $('#dtTransactions').DataTable();
            dt.column(12).visible(false);
            if(v === sortingMode.noSorting){
                dt.order([0, 'asc']);
                dt.column(10).visible(false);
                dt.column(11).visible(true);
            }else{
                dt.order([10, 'desc']);
                dt.column(10).visible(true);
                dt.column(11).visible(false);
            }
            //dt.clear();
            //dt.draw();
        });

        $scope.reload = function (f) {
            $scope.hideSearchBox = false;
            $scope.$emit("reloadListTransactions");
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            $scope.reload();
        };

        $scope.getTransactionDetails = function (t) {
            var modalInstance = $modal.open({
                templateUrl: "/partials/transaction/transaction.html",
                controller: 'TransactionDetailsCtrl',
                resolve: {
                    transactionDetails: function () {
                        return core.transaction.getDetails(t.Id, t.TransactionTypeId)
                            .then(function(t){
                                t.TransactionSourceName = transactionSource[t.TransactionSource];
                                return t;
                            });
                    }
                }
            });

            modalInstance.result.then($scope.reload);
        };
    }]);

portal.controller("TransactionDetailsCtrl", ["$scope", "$location", "$uibModalInstance", "transactionDetails",
    function ($scope, $location, $modalInstance, transactionDetails) {

        $scope.transaction = transactionDetails;
        $scope.success = $scope.transaction.Success ? 'Yes' : 'No';
        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    }
]);
