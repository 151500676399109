"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("brandingService", ["httpService",
    function (httpService) {
        var service = {};
        service.create = function (record, ext) {
            return httpService.put("/api/branding/", record, ext);
        };
        service.get = function (id) {
            return httpService.get("/api/branding/" + id);
        };
        service.update = function (record, ext) {
            return httpService.post("/api/branding/", record, ext);
        };
        service.delete = function (brandingId) {
            return httpService.delete("/api/branding/" + brandingId);
        };
        return service;
    }]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("BrandingListCtrl", ["$scope", "$location", "coreService",
    function ($scope, $location, core) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, '/manage/branding');
        };
        $scope.dtConfig = {
            ajax: {
                url: "/api/branding/list"
            },
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: "Id", render: r1, name: "b.Name", width: "5%"},
                {targets: [1], data: "Name", title: "Name", name: "b.Name", width: "25%"},
                {targets: [2], data: "RegEx", title: "Regex", name: "b.RegEx", width: "30%" },
                {targets: [3], data: "Title", title: "Title", name: "b.Title", width: "30%" }
            ],
            createLink: "/manage/branding/0"
        };
    }]);


portal.controller("BrandingDetailsCtrl", ["$scope", "$location", "$routeParams", "$http", "coreService",
    function ($scope, $location, $routeParams, $http, core) {

        $scope.branding = {
            ExtendedData: {
                Images: []
            }
        };
        $scope.isEdit = $scope.allowDelete = parseInt($routeParams.id) > 0;

        var _buildFormData = function(){
            var formData = new FormData($('#branding-details')[0]);
            return formData;
        };

        var _load = function(){
            if ($routeParams.id > 0) {
                core.branding.get($routeParams.id).then(function (branding) {
                    $scope.branding = branding;
                });
            }
        };

        var _save = function (cb) {
            var ext = {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            };
            var data = _buildFormData();

            if ($scope.branding.Id > 0) {
                return core.branding.update(data, ext).then(function (r) {
                    core.ui.showResult(r, "Branding has been updated.", cb);
                }, core.ui.showValidation($scope));
            } else{
                return core.branding.create(data, ext).then(function (r) {
                    core.ui.showResult(r, "Branding has been added.", cb);
                }, core.ui.showValidation($scope));
            }
        };

        $scope.save = function () {
            _save($scope.close);
        };

        $scope.apply = function () {
            _save(function(d){
                if ($routeParams.id == 0) {
                    $location.path("/manage/branding/" + d.id);
                }else{
                    _load();
                }
            });
        };

        $scope.confirmDelete = function () {
            core.ui.showConfirmDialog('Confirmation', 'Are you sure you wish to delete branding?')
                .then(function(){
                    core.branding.delete($scope.branding.Id).then(function (r) {
                        core.ui.showResult(r, "Branding has been deleted successfully.", $scope.close);
                    }, core.ui.showValidation($scope));
                });
        };

        $scope.addImage = function(){
            $scope.branding.ExtendedData.Images.push({Key: '', Name: '', Url: ''});
        };

        $scope.chooseImage = function(el, fileName){
            $(el).parents('div.input-group').find('.file-name').val(fileName);
        };

        /*$scope.deleteImage = function(index){
            $scope.branding.Images.splice(index, 1);
        };*/

        $scope.close = function () {
            $location.path("/manage/branding");
        };

        $scope.clipboard = new Clipboard('.clipboard');

        (function () {
            _load();
        })();

        $scope.$on('$destroy', function () {
            if($scope.clipboard) {
                $scope.clipboard.destroy();
            }
        });
    }
]);