"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("coreService", ["httpService", "authService", "uiService", "storageService", "dtService", "partnerService",
    "clientService", "userService", "contactService", "dictionaryService" ,
    "transactionService", "reportService", "documentationService", "emailTemplateService", "maintenanceService",
    "brandingService", "tfaService", "documentStoreService", "eulaService", "systemConfigurationService",
    "auditService", "notificationService", "decryptionMetricService", "templateService", "virtualTerminalService",
    "samlService", "injectKeyService", "templateSharingService", "gatewayTemplateService","gatewayTransactionService",
    function (httpService, authService, uiService, storageService, dtService, partnerService,
              clientService, userService, contactService, dictionaryService, transactionService, reportService,
              documentationService, emailTemplateService,  maintenanceService, brandingService, tfaService,
              documentStoreService, eulaService, systemConfigurationService, auditService, notificationService,
              decryptionMetricService, templateService, virtualTerminalService,samlService, injectKeyService,
              templateSharingService, gatewayTemplateService, gatewayTransactionService) {
        var core = {
            http: httpService,
            auth: authService,

            ui: uiService,
            storage: storageService,
            dt: dtService,
            dictionary: dictionaryService,

            partner: partnerService,
            client: clientService,
            user: userService,
            contact: contactService,
            transaction: transactionService,
            report: reportService,
            documentation: documentationService,
            emailTemplate: emailTemplateService,
            maintenance: maintenanceService,
            branding: brandingService,
            tfa: tfaService,
            documentStore: documentStoreService,
            eula: eulaService,
            systemConfig: systemConfigurationService,
            audit: auditService,
            notification: notificationService,
            decryptionMetric: decryptionMetricService,
            saml: samlService,
            template: templateService,
            virtualTerminal: virtualTerminalService,
            injectKey: injectKeyService,
            templateSharing: templateSharingService,
            gatewayTemplate: gatewayTemplateService,
            gatewayTransaction: gatewayTransactionService,

            // common object for partner/client filter
            filter: {partner: null, client: null}
        };

        return core;
    }]);
