"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("reportService", ["httpService",
    function (httpService) {
        var service = {};
        service.partnerSummary = function () {
            return  httpService.post("/api/reports/partnerSummary");
        };
        service.clientSummary = function () {
            return  httpService.post("/api/reports/clientSummary");
        };
        service.partnerTransactionSummary = function (partnerId, dateFrom, dateTo) {
            return  httpService.post("/api/reports/partnerTransactionSummary", {partnerId: partnerId, dateFrom: dateFrom, dateTo: dateTo});
        };
        service.clientTransactionSummary = function (partnerId, dateFrom, dateTo) {
            return  httpService.post("/api/reports/clientTransactionSummary", {partnerId: partnerId, dateFrom: dateFrom, dateTo: dateTo});
        };
        service.eulaSummary = function (partnerId) {
            return  httpService.post("/api/reports/eulaSummary", {partnerId: partnerId});
        };
        service.clientSummary = function () {
            return  httpService.post("/api/reports/clientSummary");
        };
        service.partnerTransactionSummaryRequest = function (partnerId, dateFrom, dateTo) {
            return  httpService.post("/api/reports/partnerTransactionSummaryRequest", {partnerId: partnerId, dateFrom: dateFrom, dateTo: dateTo});
        };
        service.clientTransactionSummaryRequest = function (partnerId, dateFrom, dateTo) {
            return  httpService.post("/api/reports/clientTransactionSummaryRequest", {partnerId: partnerId, dateFrom: dateFrom, dateTo: dateTo});
        };

        service.threedsPartnerSummaryRequest = function (partnerId, dateFrom, dateTo) {
            return  httpService.post("/api/reports/partner3dsSummaryRequest", {partnerId: partnerId, dateFrom: dateFrom, dateTo: dateTo});
        };
        service.threedsClientSummaryRequest = function (partnerId, dateFrom, dateTo) {
            return  httpService.post("/api/reports/client3dsSummaryRequest", {partnerId: partnerId, dateFrom: dateFrom, dateTo: dateTo});
        };

        return service;
    }]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("PartnerSummaryReportCtrl", ["$scope", "$location", "coreService",
    function ($scope, $location, core) {

        $scope.dtPartnerSummary = {
            serverSide: false,
            //deferRender: true,
            columnDefs: [
                {targets: [0],  data: "PartnerName", name: "PartnerName", title: "Partner", width: '10%'},
                {targets: [1],  data: "Path", name: "Path", title: "Path", width: '40%'},
                {targets: [2],  data: "ClientTotal", name: "ClientTotal", title: "Total Clients"},
                {targets: [3],  data: "UserActiveTotal", name: "UserActiveTotal", title: "Active Users"},
                {targets: [4],  data: "UserInActiveTotal", name: "UserInActiveTotal", title: "Inactive Users"},
                {targets: [5],  data: "TemplateTotal", name: "TemplateTotal", title: "Templates"}
            ],
            sorting: [[0, 'asc']],
            paging: false,
            showTableTools: 'Partner Summary'
        };

        (function () {
            core.report.partnerSummary()
                .then(function(d){
                   core.dt.refresh('#dtPartnerSummary', d);
                });
        })();
    }]);

portal.controller("ClientSummaryReportCtrl", ["$scope", "coreService",
    function ($scope, core) {
        $scope.dtClientSummary = {
            serverSide: false,
            columnDefs: [
                {targets: [0],  data: "PartnerName", name: "PartnerName", title: "Partner", width: '10%'},
                {targets: [1],  data: "DirectPartnerName", name: "DirectPartnerName", title: "Direct&nbsp;Partner", width: '10%'},
                {targets: [2],  data: "Path", name: "Path", title: "Path", width: '30%'},
                {targets: [3],  data: "ClientName", name: "ClientName", title: "Client", width: '10%'},
                {targets: [4],  data: "UserActiveTotal", name: "UserActiveTotal", title: "Active Users"},
                {targets: [5],  data: "UserInActiveTotal", name: "UserInActiveTotal", title: "Inactive Users"},
                {targets: [6],  data: "TemplateTotal", name: "TemplateTotal", title: "Templates"}
            ],
            sorting: [[0, 'asc']],
            paging: false,
            showTableTools: 'Client Summary'
        };

        (function () {
            core.report.clientSummary()
                .then(function(d){
                   core.dt.refresh('#dtClientSummary', d);
                });
        })();
    }]);

portal.controller("PartnerTransactionSummaryReportCtrl", ["$scope", "$location", "$q", "ApplicationOptions", "$enums", "$timeout", "coreService",
    function ($scope, $location, $q, applicationOptions, $enums, $timeout, core) {

        var _defaultFilter = function(){
            return _.merge(core.filter, {
                partner: null,
                dateFrom: moment().add( -1, 'days').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate(),
                useUtc: false
            });
        };

        $scope.filter = _defaultFilter();

        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }

        $scope.dtPartnerTransactionSummary = {
            serverSide: false,
            columnDefs: [
                {targets: [0],  data: "PartnerName", name: "PartnerName", render: core.dt.renderPartnerPath('PartnerPath', true), title: "Partner", width: '8%'},
                {targets: [1],  data: "DirectPartnerName", name: "DirectPartnerName", render: core.dt.renderPartnerPath('DirectPartnerPath'), title: "Direct Partner", width: '8%'},
                {targets: [2],  data: "TotalPartnerValidate", name: "TotalPartnerValidate", title: "Total Partner Validate"},
                {targets: [3],  data: "TotalTemplateValidate", name: "TotalTemplateValidate", title: "Total Template Validate"},
                {targets: [4],  data: "TotalGoodTransactionWrite", name: "TotalGoodTransactionWrite", title: "Good Tokenized Messages"},
                {targets: [5],  data: "TotalBadTransactionWrite", name: "TotalBadTransactionWrite", title: "Bad Tokenized Messages"},
                {targets: [6],  data: "TotalGoodTransactionRead", name: "TotalGoodTransactionRead", title: "Good Detokenized Messages"},
                {targets: [7],  data: "TotalBadTransactionRead", name: "TotalBadTransactionRead", title: "Bad Detokenized Messages"},
                {targets: [8],  data: "TotalGoodTransactionWriteFields", name: "TotalGoodTransactionWriteFields", title: "Good Tokenized Fields"},
                {targets: [9],  data: "TotalBadTransactionWriteFields", name: "TotalBadTransactionWriteFields", title: "Bad Tokenized Fields"},
                {targets: [10],  data: "TotalGoodTransactionReadFields", name: "TotalGoodTransactionReadFields", title: "Good Detokenized Fields"},
                {targets: [11],  data: "TotalBadTransactionReadFields", name: "TotalBadTransactionReadFields", title: "Bad Detokenized Fields"}
            ],
            sorting: [[0, 'asc']],
            paging: false,
            showTableTools: 'Partner Transaction Summary Report'
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            core.dt.refresh('#dtPartnerTransactionSummary', []);
        };

        $scope.reload = function () {
            start();
            var dateFrom = core.ui.getDate($scope.filter.dateFrom, $scope.filter.useUtc);
            var dateTo = core.ui.getDate($scope.filter.dateTo, $scope.filter.useUtc);
            core.report.partnerTransactionSummaryRequest($scope.filter.partner, dateFrom, dateTo)
                .then(function (importId) {
                    core.ui.success("Report generation has been initiated successfully. It may take up to few minutes.");
                    $scope.importId = importId;
                    return core.maintenance.handleImportCompleted($scope.importId, {}, $enums.MaintenanceDisplayMode.full);
                })
                .then(function(importHistory){
                    var content = JSON.parse(importHistory.Content);
                    core.dt.refresh('#dtPartnerTransactionSummary', content.resultData);
                })
                .catch(core.ui.showValidation($scope))
                .finally(stop);
        };

        var start = function(){
            $scope.loading = true;
            core.ui.showLoading('#transaction-summary-partner');
        };

        var stop = function(){
            $scope.loading = false;
            core.ui.hideLoading('#transaction-summary-partner');
        };

        $scope.$on("$destroy", function() {
            core.maintenance.clearWaitTimeout();
        });
    }]);

portal.controller("ClientTransactionSummaryReportCtrl", ["$scope", "$location", "$q", "ApplicationOptions", "$enums", "$timeout", "coreService",
    function ($scope, $location, $q, applicationOptions, $enums, $timeout, core) {

        var _defaultFilter = function(){
            return _.merge(core.filter, {
                partner: null, 
                client: null, 
                dateFrom: moment().add( -1, 'days').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate(),
                useUtc: false
            });
        };

        $scope.filter = _defaultFilter();

        $scope.showPartnerSummary = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }

        $scope.dtClientTransactionSummary = {
            serverSide: false,
            columnDefs: [
                {targets: [0],  data: "PartnerName", name: "PartnerName", render: core.dt.renderPartnerPath('PartnerPath', true), title: "Partner"},
                {targets: [1],  data: "DirectPartnerName", name: "DirectPartnerName", render: core.dt.renderPartnerPath('DirectPartnerPath'), title: "Direct Partner"},
                {targets: [2],  data: "ClientName", name: "ClientName", title: "Client"},
                {targets: [3],  data: "TotalTemplateValidate", name: "TotalTemplateValidate", title: "Total Template Validate"},
                {targets: [4],  data: "TotalGoodTransactionWrite", name: "TotalGoodTransactionWrite", title: "Total Good Tokenized"},
                {targets: [5],  data: "TotalBadTransactionWrite", name: "TotalBadTransactionWrite", title: "Total Bad Tokenized"},
                {targets: [6],  data: "TotalGoodTransactionRead", name: "TotalGoodTransactionRead", title: "Total Good Detokenized"},
                {targets: [7],  data: "TotalBadTransactionRead", name: "TotalBadTransactionRead", title: "Total Bad Detokenized"},
                {targets: [8],  data: "TotalGoodTransactionWriteFields", name: "TotalGoodTransactionWriteFields", title: "Good Tokenized Fields"},
                {targets: [9],  data: "TotalBadTransactionWriteFields", name: "TotalBadTransactionWriteFields", title: "Bad Tokenized Fields"},
                {targets: [10],  data: "TotalGoodTransactionReadFields", name: "TotalGoodTransactionReadFields", title: "Good Detokenized Fields"},
                {targets: [11],  data: "TotalBadTransactionReadFields", name: "TotalBadTransactionReadFields", title: "Bad Detokenized Fields"}
            ],
            sorting: [[0, 'asc']],
            paging: false,
            showTableTools: 'Client Transaction Summary Report'
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            core.dt.refresh('#dtClientTransactionSummary', []);
        };

        $scope.reload = function () {
            start();
            var dateFrom = core.ui.getDate($scope.filter.dateFrom, $scope.filter.useUtc);
            var dateTo = core.ui.getDate($scope.filter.dateTo, $scope.filter.useUtc);
            core.report.clientTransactionSummaryRequest($scope.filter.partner, dateFrom, dateTo)
                .then(function (importId) {
                    core.ui.success("Report generation has been initiated successfully. It may take up to few minutes.");
                    $scope.importId = importId;
                    return core.maintenance.handleImportCompleted($scope.importId, {}, $enums.MaintenanceDisplayMode.full);
                })
                .then(function(importHistory){
                    var content = JSON.parse(importHistory.Content);
                    core.dt.refresh('#dtClientTransactionSummary', content.resultData);
                })
                .catch(core.ui.showValidation($scope))
                .finally(stop);
        };

        var start = function(){
            $scope.loading = true;
            core.ui.showLoading('#transaction-summary-client');
        };

        var stop = function(){
            $scope.loading = false;
            core.ui.hideLoading('#transaction-summary-client');
        };

        $scope.$on("$destroy", function() {
            core.maintenance.clearWaitTimeout();
        });
    }]);

portal.controller("EulaReportCtrl", ["$scope", "$location", "coreService", "$enums",
    function ($scope, $location, core, $enums) {

        $scope.filter = core.filter;

        $scope.summary = {
            total: 0,
            accepted: 0,
            declined: 0,
            notLogin: 0
        };

        var r1 = function(type){
            return type === $enums.UserEulaActionType.accepted ? 'Accepted' : 'Declined';
        };

        //ue.ActionDate, ue.Ip, u.UserName, u.FirstName, u.LastName, p.Name as PartnerName, dp.Name as DirectPartnerName, c.Name as ClientName, d.Name as DocumentName
        $scope.dtConfig = {
            ajax: {
                url: "/api/reports/eulaUsers",
                data: function (d) {
                    d.filter = $scope.filter;
                }
            },
            sorting: [
                [3, 'desc']
            ],
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: core.ui.formatUser, title: "User", name: "u.UserName", width: "20%"},
                {targets: [1], data: "UserName", title: "User Name", name: "u.UserName", width: "20%"},
                {targets: [2], data: "UserEulaActionTypeId", title: "Accepted/Declined", render: r1, name: "ue.UserEulaActionTypeId", width: "20%"},
                {targets: [3], data: "ActionDate", title: "Date", render: core.dt.renderDateWithoutTime, name: "ue.ActionDate", width: "20%"},
                {targets: [4], data: "DocumentName", title: "Document", name: "d.Name", width: "20%"},
                {targets: [5], data: "PartnerName", title: "Partner", name: "p.Name", width: "20%" },
                {targets: [6], data: "DirectPartnerName", title: "Direct Partner", name: "dp.Name", width: "20%" },
                {targets: [7], data: "ClientName", title: "Client", name: "c.Name", width: "20%" }
            ],
            reloadEvent: "reloadList"
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");

            core.report.eulaSummary($scope.filter.partner)
                .then(function(d){
                    $scope.summary = d;
                });
        };

        (function(){
            $scope.reload();
        })();
    }]);

portal.controller("UserReportCtrl", ["$scope", "$location", "coreService",  "$compile",
    function ($scope, $location, core, $compile) {

        var _defaultFilter = function() {
            return _.merge(core.filter, {partner: null, client: null, productCommunication: null});
        };

        $scope.filter = _defaultFilter();

        $scope.showProductCommunication = core.auth.isSystemAnyUser();

        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }

        $scope.dtConfig = {
            ajax: {
                url: "/api/reports/userReport",
                data: function (d) {
                    d.filter = $scope.filter;
                }
            },
            sorting: [
                [0, 'desc']
            ],
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: "UserName", title: "User Name", name: "u.UserName", width: "10%"},
                {targets: [1], data: "Name", title: "Name", name: "u.LastName", width: "10%"},
                {targets: [2], data: "Email", title: "Email", name: "u.Email", width: "10%"},
                {targets: [3], data: "Phone", title: "Phone", name: "u.Phone", width: "10%"},
                {targets: [4], data: "PartnerName", title: "Partner", name: "p.Name", width: "10%"},
                {targets: [5], data: "DirectPartnerName", title: "Direct Partner", name: "p.Name", width: "10%"},
                {targets: [6], data: "ClientName", title: "Client", name: "c.Name", width: "10%"},
                {targets: [7], data: "UserRoleName", title: "Role", name: "ur.Name", width: "10%"},
                {targets: [8], data: "Path", title: "Path", name: "Path", width: "10%"},
                {targets: [9], data: "IsActive", render: core.dt.renderYesNoFromBool, title: "Active?", width: "10%"}
            ],
            reloadEvent: "reloadList",
            showTableTools: 'User Report'
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            $scope.reload();
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };
    }]);

portal.controller("DailyReportCtrl", ["$scope", "$location", "coreService",  "$compile",
    function ($scope, $location, core, $compile) {

        $scope.filter = core.filter;

        $scope.filter.dateFrom = moment().add( -1, 'days').toDate();
        $scope.filter.dateTo = moment().toDate();
        $scope.useUtc = false;

        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }

        $scope.dtConfig = {
            ajax: {
                url: "/api/reports/dailyReport",
                data: function (d) {
                    d.filter = _.clone($scope.filter);

                    var dateFrom = core.ui.getDate(d.filter.dateFrom, $scope.useUtc);
                    var dateTo = core.ui.getDate(d.filter.dateTo, $scope.useUtc);
                    d.filter.dateFrom = dateFrom;
                    d.filter.dateTo = dateTo;
                }
            },
            sorting: [
                [0, 'desc']
            ],
            columnDefs: [
                {targets: [0], sortable: false, searchable: false },
                {targets: [0], data: "ClientName", title: "Client Name", name: "c.Name", width: "10%"},
                {targets: [1], data: "MessageId", title: "Message ID", name: "t.MessageId", width: "10%"},
                {targets: [2], data: "Reference", title: "Reference", name: "t.Reference", width: "10%"},
                {targets: [3], data: "Method", title: "Method", name: "t.Method", width: "10%"},
                {targets: [4], data: "Encrypted", title: "Encrypted", name: "t.Encrypted", width: "10%"},
                {targets: [5], data: "Decrypted", title: "Decrypted", name: "t.Decrypted", width: "10%"},
                {targets: [6], data: "Success", title: "Success", name: "t.Success", width: "10%"},
                {targets: [7], data: "EndDate", title: "Date", name: "t.EndDate", render: core.dt.renderDate, width: "10%"},
                {targets: [8], data: "IsVirtual", title: "Virtual", name: "t.IsVirtual", width: "10%"},
                {targets: [9], data: "SerialNumber", title: "Serial Number", name: "d.SerialNumber", width: "10%"},
                {targets: [10], data: "AlternateKey", title: "Alternate Key", name: "d.AlternateKey", width: "10%"},
                {targets: [11], data: "DeviceName", title: "Device Name", name: "d.Name", width: "10%"},
                {targets: [12], data: "PartnerName", title: "Partner Name", name: "p.Name", width: "10%"},
                {targets: [13], data: "DirectPartnerName", title: "Direct Partner Name", name: "dp.Name", width: "10%"}
            ],
            reloadEvent: "reloadList",
            showTableTools: 'Daily Report'
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };

        (function(){
            $scope.reload();
        })();
    }]);

portal.controller("TokenizationUnclaimedReportCtrl", ["$scope", "$location", "coreService",  "$compile",
    function ($scope, $location, core, $compile) {

        var _defaultFilter = function(){
            return _.merge(core.filter, {
                partner: null, 
                client: null, 
                dateFrom: moment().add( -1, 'months').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate(),
                useUtc: false
            });
        };

        $scope.filter = _defaultFilter();

        /*
        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }
        */

        $scope.dtConfig = {
            ajax: {
                url: "/api/reports/tokenizationUnclaimed",
                data: function (d) {
                    d.filter = _.clone($scope.filter);

                    var dateFrom = core.ui.getDate(d.filter.dateFrom, $scope.filter.useUtc);
                    var dateTo = core.ui.getDate(d.filter.dateTo, $scope.filter.useUtc);
                    d.filter.dateFrom = dateFrom;
                    d.filter.dateTo = dateTo;
                }
            },
            columnDefs: [
                {targets: [0], data: "CompleteDate", render: core.dt.renderDateWithSeconds, title: "Date", name: "tw.CompleteDate", width: "20%"},
                {targets: [1], data: "TemplateName", title: "Template Name", name: "t.Name", width: "20%"},
                {targets: [2], data: "Referrer", render: core.dt.renderLongText(40), title: "Referrer", name: "tw.Referrer", width: "30%"},
                {targets: [3], data: "Bfid", render: core.dt.renderLongText(40), title: "BFID", name: "tw.Bfid", width: "30%"}
            ],
            reloadEvent: "reloadList",
            showTableTools: 'Unclaimed Report'
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();

            $scope.reload();
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };

        (function(){
            $scope.reload();
        })();
    }]);

portal.controller("TokenizationReferrerReportCtrl", ["$scope", "$location", "coreService",  "$compile",
    function ($scope, $location, core, $compile) {

        var _defaultFilter = function(){
            return _.merge(core.filter, {
                partner: null, 
                client: null, 
                dateFrom: moment().add( -1, 'months').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate(),
                useUtc: false
            });
        };

        $scope.filter = _defaultFilter();

        /*
        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }
        */

        $scope.dtConfig = {
            ajax: {
                url: "/api/reports/tokenizationReferrer",
                data: function (d) {
                    d.filter = _.clone($scope.filter);

                    var dateFrom = core.ui.getDate(d.filter.dateFrom, $scope.filter.useUtc);
                    var dateTo = core.ui.getDate(d.filter.dateTo, $scope.filter.useUtc);
                    d.filter.dateFrom = dateFrom;
                    d.filter.dateTo = dateTo;
                }
            },
            columnDefs: [
                {targets: [0], data: "TemplateName", title: "Template Name", name: "t.Name", width: "10%"},
                {targets: [1], data: "Referrer", title: "Referrer", name: "grp.Referrer", width: "30%"},
                {targets: [2], data: "Count", title: "Count", name: "Count", width: "5%"}
            ],
            reloadEvent: "reloadList",
            showTableTools: 'Referrer Report'
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();

            $scope.reload();
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };

        (function(){
            $scope.reload();
        })();
    }]);

portal.controller("3dsDetailsReportCtrl", ["$scope", "$location", "coreService",  "$compile",
    function ($scope, $location, core, $compile) {

        var _defaultFilter = function(){
            return _.merge(core.filter, {
                partner: null, 
                client: null,
                success: null,
                dateFrom: moment().add( -1, 'months').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate(),
                useUtc: false
            });
        };

        $scope.filter = _defaultFilter();

        $scope.dtConfig = {
            ajax: {
                url: "/api/reports/details3ds",
                data: function (d) {
                    d.filter = _.clone($scope.filter);

                    var dateFrom = core.ui.getDate(d.filter.dateFrom, $scope.filter.useUtc);
                    var dateTo = core.ui.getDate(d.filter.dateTo, $scope.filter.useUtc);
                    d.filter.dateFrom = dateFrom;
                    d.filter.dateTo = dateTo;
                }
            },
            columnDefs: [
                {targets: [5, 11], sortable: false, searchable: false },
                {targets: [0], data: "Provider", title: "Provider", name: "t.Provider", width: "5%"},
                {targets: [1], data: "PartnerName", title: "Partner", name: "p.Name", width: "10%"},
                {targets: [2], data: "DirectPartnerName", title: "Direct Partner", name: "dp.Name", width: "10%"},
                {targets: [3], data: "ClientName", title: "Client", name: "c.Name", width: "10%"},
                {targets: [4], data: "Reference", title: "Template Reference", name: "tt.Reference", width: "10%"},
                {targets: [5], data: "Success", title: "Success", name: "t.Success", render: core.dt.renderYesNoFromBool, width: "5%"},
                {targets: [6], data: "Status", title: "Status", name: "t.Status", width: "5%"},
                {targets: [7], data: "CorrelationId", title: "CorrelationId", name: "t.CorrelationId", width: "10%"},
                {targets: [8], data: "TransactionId", title: "TransactionId", name: "t.TransactionId", width: "10%"},
                {targets: [9], data: "Eci", title: "Eci", name: "t.Eci", width: "10%"},
                {targets: [10], data: "Referrer", title: "Referrer", name: "t.Referrer", width: "10%"},
                {targets: [11], data: "CompleteDate", title: "Date", name: "t.CompleteDate", render: core.dt.renderDate, width: "5%"},
            ],
            reloadEvent: "reloadList",
            showTableTools: '3DS Details'
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            $scope.reload();
        };

        $scope.reload = function () {
            $scope.$emit("reloadList");
        };
    }]);

portal.controller("3dsPartnerSummaryReportCtrl", ["$scope", "$location", "$q", "ApplicationOptions", "$enums", "$timeout", "coreService",
    function ($scope, $location, $q, applicationOptions, $enums, $timeout, core) {

        var _defaultFilter = function(){
            return _.merge(core.filter, {
                partner: null, 
                client: null,
                success: null,
                dateFrom: moment().add( -1, 'months').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate(),
                useUtc: false
            });
        };

        $scope.filter = _defaultFilter();

        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }

        $scope.dtConfig = {
            serverSide: false,
            columnDefs: [
                {targets: [0],  data: "PartnerName", name: "PartnerName", render: core.dt.renderPartnerPath('PartnerPath', true), title: "Partner", width: '20%'},
                {targets: [1],  data: "DirectPartnerName", name: "DirectPartnerName", render: core.dt.renderPartnerPath('DirectPartnerPath'), title: "Direct Partner", width: '20%'},
                {targets: [2],  data: "TotalSuccess", name: "TotalSuccess", title: "Total Partner 3DS Authentication Success"},
                {targets: [3],  data: "TotalFailed", name: "TotalFailed", title: "Total Partner 3DS Authentication Decline"}
            ],
            sorting: [[0, 'asc']],
            paging: false,
            showTableTools: 'Partner 3DS Summary'
        };

        $scope.reload = function () {
            start();
            var dateFrom = core.ui.getDate($scope.filter.dateFrom, $scope.filter.useUtc);
            var dateTo = core.ui.getDate($scope.filter.dateTo, $scope.filter.useUtc);
            
            core.report.threedsPartnerSummaryRequest($scope.filter.partner, dateFrom, dateTo)
                .then(function (importId) {
                    core.ui.success("Report generation has been initiated successfully. It may take up to few minutes.");
                    $scope.importId = importId;
                    return core.maintenance.handleImportCompleted($scope.importId, {}, $enums.MaintenanceDisplayMode.full);
                })
                .then(function(importHistory){
                    var content = JSON.parse(importHistory.Content);
                    core.dt.refresh('#dt3dsPartnerSummary', content.resultData);
                })
                .catch(core.ui.showValidation($scope))
                .finally(stop);
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            core.dt.refresh('#dt3dsPartnerSummary', []);
        };

        var start = function(){
            $scope.loading = true;
            core.ui.showLoading('#3ds-summary-partner');
        };

        var stop = function(){
            $scope.loading = false;
            core.ui.hideLoading('#3ds-summary-partner');
        };

        $scope.$on("$destroy", function() {
            core.maintenance.clearWaitTimeout();
        });
    }]);

portal.controller("3dsClientSummaryReportCtrl", ["$scope", "$location", "$q", "ApplicationOptions", "$enums", "$timeout", "coreService",
    function ($scope, $location, $q, applicationOptions, $enums, $timeout, core) {

        var _defaultFilter = function(){
            return _.merge(core.filter, {
                partner: null, 
                client: null,
                success: null,
                dateFrom: moment().add( -1, 'months').startOf('day').toDate(),
                dateTo: moment().endOf('day').toDate(),
                useUtc: false
            });
        };

        $scope.filter = _defaultFilter();

        $scope.showPartnerSummary = core.auth.isSystemAnyUser() || core.auth.isPartnerAnyUser();

        if(core.auth.isPartnerAnyUser()){
            $scope.filter.partner = core.auth.principal.partnerId;
        }

        $scope.dtConfig = {
            serverSide: false,
            columnDefs: [
                {targets: [0],  data: "PartnerName", name: "PartnerName", render: core.dt.renderPartnerPath('PartnerPath', true), title: "Partner"},
                {targets: [1],  data: "DirectPartnerName", name: "DirectPartnerName", render: core.dt.renderPartnerPath('DirectPartnerPath'), title: "Direct Partner"},
                {targets: [2],  data: "ClientName", name: "ClientName", title: "Client"},
                {targets: [3],  data: "TotalSuccess", name: "TotalSuccess", title: "Total Client 3DS Authentication Success"},
                {targets: [4],  data: "TotalFailed", name: "TotalFailed", title: "Total Client 3DS Authentication Decline"}
            ],
            sorting: [[0, 'asc']],
            paging: false,
            showTableTools: 'Client 3DS Summary'
        };

        $scope.clear = function () {
            $scope.filter = _defaultFilter();
            core.dt.refresh('#dt3dsClientSummary', []);
        };

        $scope.reload = function () {
            start();
            var dateFrom = core.ui.getDate($scope.filter.dateFrom, $scope.filter.useUtc);
            var dateTo = core.ui.getDate($scope.filter.dateTo, $scope.filter.useUtc);
            core.report.threedsClientSummaryRequest($scope.filter.partner, dateFrom, dateTo)
                .then(function (importId) {
                    core.ui.success("Report generation has been initiated successfully. It may take up to few minutes.");
                    $scope.importId = importId;
                    return core.maintenance.handleImportCompleted($scope.importId, {}, $enums.MaintenanceDisplayMode.full);
                })
                .then(function(importHistory){
                    var content = JSON.parse(importHistory.Content);
                    core.dt.refresh('#dt3dsClientSummary', content.resultData);
                })
                .catch(core.ui.showValidation($scope))
                .finally(stop);
        };

        var start = function(){
            $scope.loading = true;
            core.ui.showLoading('#3ds-summary-client');
        };

        var stop = function(){
            $scope.loading = false;
            core.ui.hideLoading('#3ds-summary-client');
        };

        $scope.$on("$destroy", function() {
            core.maintenance.clearWaitTimeout();
        });
    }]);