"use strict";
var services = angular.module("portal");

/*****************
 SERVICES
 ******************/
services.factory("dictionaryService", ["$q", "httpService", function ($q, httpService) {
    var _tryGet = function (url) {
        //console.log('getting ' + url);
        if (dict.cache[url]) {
            //console.log('found in cache:', dict.cache[url]);
            return $q.when(dict.cache[url]);
        } else {
            return httpService.get(url)
                .then(function(items){
                    //console.log('loaded from server:', items);
                    dict.cache[url] = items;
                    return items;
                });
        }
    };

    var _reset = function(url){
        if (dict.cache[url]) {
            delete dict.cache[url];
        }
    };

    var dict = {
        cache: {},
        getCountries: function () {
            return  _tryGet("/api/countries/");
        },
        getStateProvinces: function (countryCode) {
            return  _tryGet("/api/stateProvinces/" + countryCode);
        },
        getPartnerStatuses: function () {
            return  _tryGet("/api/partnerStatuses/");
        },
        getAuditSources: function(){
            return  httpService.get("/api/auditSources");
        },
        getAuditActions: function(){
            return  httpService.get("/api/auditActions");
        }
    };

    return dict;
}]);
