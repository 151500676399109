"use strict";
var portal = angular.module("portal");

/*****************
 CONTROLLERS
 ******************/
portal.controller("DashboardCtrl", [
    "$scope",
    "$location",
    "coreService",
    "$route",
    "$rootScope",
    "$uibModal",
    "$uibModal",
    function ($scope, $location, core, $route, $rootScope, $uibModal, $modal) {
        var dtConfigDefaults = {
            dom: "r<'row'<'col-xs-12't>>",
            pageLength: 20,
            lengthChange: false,
            searching: false,
            ordering: true,
            info: false,
            paging: true,
            ajax: null,
            columnDefs: null,
        };

        var limitDashboardRequest = function (d) {
            d.omitTotalRecordCount = true;
        };

        $scope.getProductNotification = function () {
            var modalInstance = $modal.open({
                templateUrl: "/partials/productCommunication/notice.html",
                controller: "ProductCommunicationCtrl",
                backdrop: "static",
                size: "product-communication",
                keyboard: false,
            });
        };

        $scope.showSummary = true;
        $scope.showTxSummary = $scope.showSummary && !core.auth.isClientAnyUser();
        $scope.showUserCount = core.auth.isClientAnyUser() || core.auth.isSystemAdmin() || core.auth.isPartnerAnyUser();

        $scope.summaryData = {};
        $scope.summaryTxData = {};

        $scope.filter = core.filter;
        $scope.filter.dateFrom = moment().subtract(1, "month").toDate();
        $scope.filter.dateTo = moment().toDate();

        core.auth.principal.claims.PRODUCT_COMMUNICATION === false &&
        core.storage.getShowProductCommunication() === true
            ? core.storage.setShowProductCommunication(true)
            : core.storage.setShowProductCommunication(false);

        if (core.storage.getShowProductCommunication() === true) {
            $scope.getProductNotification();
        }

        $scope.refreshSummary = function () {
            $scope.summaryData =
                $scope.summaryTxData =
                $scope.transactionsPie =
                $scope.clientsPie =
                $scope.disableButton =
                $scope.notifications =
                    [];

            _loadSummary(true);
        };

        var _loadSummary = function (enforce) {
            var dateFrom = core.ui.getDate(core.filter.dateFrom, true);
            var dateTo = core.ui.getDate(core.filter.dateTo, true);
            if ($scope.showSummary) {
                core.user.dashboardSummary(dateFrom, dateTo, enforce).then(function (d) {
                    $scope.summaryData = d;

                    _buildCharts(d);
                });
            }

            if ($scope.showTxSummary) {
                core.user.dashboardSummaryTransaction(dateFrom, dateTo, enforce).then(function (d) {
                    $scope.summaryTxData = d;
                    _buildTransactionCharts(d);
                });
            }

            if ($scope.showUserCount) {
                core.user.userCount(enforce).then(function (d) {
                    _userCount(d);
                });
            }
        };

        function _showNotifications() {
            if ($scope.notifications.length > 0) {
                _displayNotification($scope.notifications[0]);
            } else {
                _productCommunication();
            }
        }

        function _displayNotification(notification) {
            let n = {};
            n.id = notification.id;
            n.icon = notification.icon;
            n.title = notification.title;
            n.message = notification.message;

            var modalInstance = $modal
                .open({
                    backdrop: "static",
                    keyboard: false,
                    templateUrl: "/partials/dashboard/notification.details.html",
                    controller: "NotificationDetailCtrl",
                    resolve: { notification: n },
                })
                .result.finally(function () {
                    $scope.notifications.shift();
                    _showNotifications();
                });
        }

        var def = {
            legend: true,
            options: {
                animation: false,
                legend: true,
                responsive: true,
                segmentShowStroke: false,
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                display: true,
                                beginAtZero: true,
                                userCallback: function (label, index, labels) {
                                    // when the floored value is the same as the value we have a whole number
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                display: true,
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        };

        // Charts
        var _buildTransactionCharts = function (d) {
            $scope.transactionsPie = _.merge(
                {
                    labels: [
                        "Successful tokenization",
                        "Failed tokenization",
                        "Successful detokenization",
                        "Failed detokenization",
                    ],
                    data: [[d.WriteSuccessCount, d.WriteFailedCount, d.ReadSuccessCount, d.ReadFailedCount]],
                    series: [
                        "Successful tokenization",
                        "Failed tokenization",
                        "Successful detokenization",
                        "Failed detokenization",
                    ],
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data, series) {
                                    return $scope.transactionsPie["data"][0][tooltipItem["index"]];
                                },
                            },
                        },
                    },
                },
                def
            );
        };

        var _userCount = function (d) {
            $scope.usersByYear = _.groupBy(d, function (b) {
                return moment(b.Created).startOf("year").format("YYYY");
            });

            $scope.userTotals = [];
            $scope.userTotals.total = d.length;

            $scope.userTotals.months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];

            $scope.changeYear = function (index) {
                var months = _.groupBy($scope.usersByYear[index], function (b) {
                    return moment(b.Created).startOf("month").format("MM");
                });

                $scope.userTotals.monthlyTotals = [];

                for (var i = 1; i <= 12; i++) {
                    $scope.userTotals.monthlyTotals.push(0);
                }

                for (var key in months) {
                    $scope.userTotals.monthlyTotals.splice(parseInt(key) - 1, 1, months[key].length);
                }

                $scope.selectedYear = index;
            };

            //start defaulted to the latest year
            $scope.changeYear(
                _.max(_.keys($scope.usersByYear), function (k) {
                    return $scope.usersByYear[k].value;
                })
            );
        };

        var _buildCharts = function (d) {
            $scope.clientsPie = _.merge(
                {
                    labels: ["Clients", "Templates"],
                    data: [[d.summary.ClientCount, d.summary.TemplateCount]],
                    series: ["Clients", "Templates"],
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data, series) {
                                    return $scope.clientsPie["data"][0][tooltipItem["index"]];
                                },
                            },
                        },
                    },
                },
                def
            );
        };

        (function () {
            if (core.auth.isAuthenticated()) {
                core.user.notifications().then(function (n) {
                    $scope.notifications = n;
                    $rootScope.notificationCountNo = n.length;
                    _showNotifications();
                });

                _loadSummary(false);
            }
        })();

        //script to control arrows for long lists
        //var step = 100;
        var scrolling = false;

        $(document)
            .on("mouseover", ".scrollUp", function (event) {
                scrolling = true;
                var scroller = $(this).data("divscrollerid");
                scrollContentList("up", scroller);
            })
            .on("mouseout", ".scrollUp", function (event) {
                scrolling = false;
            });

        $(document)
            .on("mouseover", ".scrollDown", function (event) {
                var scroller = $(this).data("divscrollerid");
                scrolling = true;
                scrollContentList("down", scroller);
            })
            .on("mouseout", ".scrollDown", function (event) {
                scrolling = false;
            });

        function scrollContentList(direction, control) {
            var amount = direction === "up" ? "-=5px" : "+=5px";
            $("#" + control).animate(
                {
                    scrollTop: amount,
                },
                1,
                function () {
                    if (scrolling) {
                        scrollContentList(direction, control);
                    }
                }
            );
        }

        /*
        function bgProgress(intVal,intMax,o){
            if(!o){o={};}
            if(!o.hasOwnProperty('color')){o.color='#ddd';}
            if(!o.hasOwnProperty('bgcolor')){o.bgcolor='transparent';}
            return 'background-image: linear-gradient(90deg, #D3D3D3 '+((intVal/intMax)*100).toFixed(2) +'%, #ffffff '+((intVal/intMax)*100).toFixed(2) +'%)';
        }
        */
    },
]);

portal.controller("ProductCommunicationCtrl", [
    "$scope",
    "$uibModalInstance",
    "$uibModal",
    "$location",
    "coreService",
    "$timeout",
    function ($scope, $modalInstance, $modal, $location, core, $timeout) {
        $scope.decline = function () {
            core.user.updateProductCommunication(core.auth.principal.id, 0).then(function () {
                core.storage.setShowProductCommunication(false);
                $modalInstance.dismiss("cancel");

                var modalInstance = $modal.open({
                    templateUrl: "/partials/productCommunication/noticeDecline.html",
                    controller: "ProductCommunicationCtrl",
                    backdrop: "static",
                    size: "product-communication",
                    keyboard: false,
                });
            });
        };

        $scope.accept = function () {
            core.user.updateProductCommunication(core.auth.principal.id, 1).then(function () {
                core.storage.setShowProductCommunication(false);
                $modalInstance.dismiss("cancel");

                var modalInstance = $modal.open({
                    templateUrl: "/partials/productCommunication/noticeAccept.html",
                    controller: "ProductCommunicationCtrl",
                    backdrop: "static",
                    size: "product-communication",
                    keyboard: false,
                    id: "product-communication-modal-accept",
                });

                $timeout(function () {
                    modalInstance.dismiss("cancel");
                }, 2000);
            });
        };

        $scope.visitUserSettings = function () {
            $modalInstance.dismiss("cancel");
            $location.path("/settings/profile");
        };

        $scope.cancel = function () {
            $modalInstance.dismiss("cancel");
        };
    },
]);
