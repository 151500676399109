"use strict";
var portal = angular.module("portal");

portal.factory("systemConfigurationService", ["httpService", "$q",
    function (httpService, $q) {
        var service = {};
        service.get = function () {
            return  httpService.get("/api/systemConfiguration");
        };

        service.createOrUpdate = function (settings) {
            return  httpService.put("/api/systemConfiguration", settings);
        };

        service.deleteLogs = function() {
            return  httpService.delete("/api/systemConfiguration/debugLogs");
        };

        return service;
    }]);

/*****************
 CONTROLLERS
 ******************/

portal.controller("SystemConfigurationCtrl", ["$scope", "$location", "coreService",
    function ($scope, $location, core) {
        $scope.settings = {};

        $scope.showSystemConfig = core.auth.isSystemAdmin();
        $scope.showDebugModeConfig = false;
        $scope.rootPartners = [];

        // region config
        $scope.saveConfig = function () {
            return core.systemConfig.createOrUpdate($scope.settings)
                .then(function (r) {
                    core.ui.showResult(r, "System Configuration has been updated.");
                }, core.ui.showValidation($scope))
                .then(_updateDebugModeConfig);
        };

        var _reloadSystemConfig = function() {
            core.systemConfig.get()
                .then(function (settings) {
                    $scope.settings = settings;
                })
                .then(_refreshDebugModeConfig);
        };

        $scope.cancelConfig = function () {
            _reloadSystemConfig();
        };

        // endregion config

        // region config

        var _refreshDebugModeConfig = function () {
            $scope.showDebugModeConfig = !!$scope.settings.debugModeStatus.value;
            // reset if off
            if(!$scope.showDebugModeConfig) {
                _.each($scope.rootPartners, function(p) {
                    p.DebugModeStatus = 0;
                });

                //return $scope.saveDebugMode();
            }
        };

        var _updateDebugModeConfig = function () {
            _refreshDebugModeConfig();

            return $scope.saveDebugMode();
        };

        var _reloadDebugModeConfig = function() {
            return core.partner.all()
                .then(function(partners) {
                    $scope.rootPartners = _.filter(partners, {ParentId: null});
                });
        };

        $scope.saveDebugMode = function () {
            return core.partner.saveDebugModeStatus($scope.rootPartners)
                .then(function (r) {
                    core.ui.showResult(r, "Debug Mode Configuration has been updated.");
                }, core.ui.showValidation($scope))
                .then(_reloadDebugModeConfig); // reload to show new date
        };

        $scope.cancelDebugMode = function () {
            _reloadDebugModeConfig();
        };

        $scope.deleteLogs = function () {
            core.ui.showConfirmDialog('Confirmation', 'Are you sure you wish to delete all debug logs?')
                .then(function(){
                    core.systemConfig.deleteLogs().then(function (r) {
                        core.ui.showResult(r, "Debug Logs has been deleted successfully.");
                    }, core.ui.showValidation($scope));
                });
        };

        // endregion config

        (function () {
            _reloadSystemConfig();

            _reloadDebugModeConfig();
        })();
    }]);
