"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("gatewayTransactionService", [
    "httpService",
    function (httpService) {
        var service = {};

        service.getDetails = function (traceId) {
            return httpService.get("/api/gateway-transaction/" + traceId);
        };
        service.getPrevNext = function (traceId, filter) {
            return httpService.get("/api/gateway-transaction/" + traceId + "/prev-next?filter=" + filter);
        };
        return service;
    },
]);

var statuses = {
    0: "In progress",
    10: "Success",
    20: "Failure",
};

/*****************
 CONTROLLERS
 ******************/
portal.controller("GatewayTransactionListCtrl", [
    "$scope",
    "coreService",
    "$enums",
    "$uibModal",
    "$q",
    function ($scope, core, $enums, $modal, $q) {
        var r1 = function (id) {
            const filter = {
                ..._.omitBy(core.filter, _.isNil),
                ...$scope._orderBy,
            };
            const data = id + `?filter=` + JSON.stringify(filter);
            return core.dt.renderView(data, "/proxy-logs");
        };

        var r2 = function (status) {
            return statuses[status];
        };

        var _defaultFilter = function () {
            const f = _.defaults(core.filter, $scope.filter, {
                partner: null,
                client: null,
                gatewayTemplate: null,
                resultStatus: null,
                dateFrom: moment().add(-1, "days").startOf("day").toDate(),
                dateTo: moment().endOf("day").toDate(),
            });
            return f;
        };

        $scope.minDate = moment().subtract(120, "days").toDate();
        $scope.filter = _defaultFilter();
        $scope.partners = $scope.clients = $scope.gatewayTemplates = undefined;

        $scope._orderBy = { orderBy: "CreatedDate", orderByDescending: true };

        $scope.dtConfig = {
            deferLoading: 0,
            ajax: {
                url: "/api/gateway-transaction/list",
                data: function (d) {
                    d.filter = _.clone(core.filter);

                    var dateFrom = core.ui.getDate(d.filter.dateFrom, $scope.useUtc);
                    var dateTo = core.ui.getDate(d.filter.dateTo, $scope.useUtc);
                    d.filter.dateFrom = dateFrom;
                    d.filter.dateTo = dateTo;

                    $scope._orderBy.orderBy = d[`mDataProp_${d.iSortCol_0}`];
                    $scope._orderBy.orderByDescending = d.sSortDir_0 == "desc";
                },
            },
            lengthMenu: [
                [25, 50, 100, 500, 1000, 5000, 10000],
                [25, 50, 100, 500, 1000, 5000, 10000],
            ],
            sorting: [[4, "desc"]],
            fnDrawCallback: function (a) {
                const d = a.oAjaxData;
                if (d) {
                    $scope._orderBy.orderBy = d[`mDataProp_${d.iSortCol_0}`];
                    $scope._orderBy.orderByDescending = d.sSortDir_0 == "desc";
                }
            },
            columnDefs: [
                { targets: [0, 1, 2, 3 /*, 4*/], sortable: false, searchable: false },
                {
                    targets: [0],
                    data: "TraceId",
                    render: r1,
                    name: "t.TraceId",
                    width: "2%",
                },
                {
                    targets: [1],
                    data: "TraceId",
                    title: "TraceId",
                    name: "t.TraceId",
                    width: "10%",
                },
                {
                    targets: [2],
                    data: "ResultStatus",
                    title: "Status",
                    name: "t.ResultStatus",
                    render: r2,
                    width: "10%",
                },
                {
                    targets: [3],
                    data: "ResultCode",
                    title: "Result Code",
                    name: "t.ResultCode",
                    width: "10%",
                },
                {
                    targets: [4],
                    data: "CreatedDate",
                    render: core.dt.renderDateWithSeconds,
                    title: "Created Date",
                    name: "t.CreatedDate",
                    width: "10%",
                },
            ],
            reloadEvent: "reloadGatewayTransactions",
            showTableTools: "GatewayTransactions",
        };

        $scope.reload = function () {
            $scope._reloadTemplates();
        };

        $scope.search = function () {
            $scope.$emit("reloadGatewayTransactions");
        };

        $scope.clear = function () {
            core.filter.client = null;
            core.filter.partner = null;
            $scope.filter = _defaultFilter();
            $scope.search();
        };

        $scope._reloadTemplates = function () {
            if ($scope.filter.partner && $scope.filter.client) {
                return core.gatewayTemplate
                    .find($scope.filter.partner, $scope.filter.client)
                    .then(function (templates) {
                        $scope.gatewayTemplates = templates;
                    });
            } else {
                $scope.gatewayTemplates = undefined;
                return $q.resolve();
            }
        };

        (function () {
            // don't call search if no filter selection
            if ($scope.filter.partner && $scope.filter.client) {
                $scope._reloadTemplates().then(function () {
                    $scope.search();
                });
            }
        })();
    },
]);

portal.controller("GatewayTransactionDetailsCtrl", [
    "$scope",
    "$routeParams",
    "$location",
    "coreService",
    function ($scope, $routeParams, $location, core) {
        $scope.transaction = {};
        $scope.logs = null;
        $scope.nextPrev = null;

        $scope.close = function () {
            $location.path("/proxy-logs");
        };

        $scope.toggle = function (elementId, classname) {
            document.getElementById(elementId).classList.toggle(classname);
        };

        $scope.move = function (traceId) {
            $location.path(`/proxy-logs/${traceId}`).search({ filter: $routeParams.filter });
        };

        var httpStatusMap = {
            200: "Ok",
            201: "Created",
            202: "Accepted",
            400: "Bad request",
            401: "Unauthorized",
            403: "Forbidden",
            404: "Not Found",
            406: "Not Acceptable",
            409: "Conflict",
            500: "Internal Server Error",
            503: "Service Unavailable",
        };

        var logTitleMap = {
            IncomingHttp: "ShieldConex Proxy Request",
            Shieldconex: "ShieldConex Action",
            RemoteCall: "Target Server Request",
        };

        var prepareViewModel = function (logs) {
            logs = logs || [];
            logs.sort((a, b) => {
                var ast = a.logDetails.timestamp || a.createdDate || "";
                var bst = b.logDetails.timestamp || b.createdDate || "";
                return ast.localeCompare(bst);
            });

            var entries = logs.reduce((agg, log) => {
                var key = log.logType.replace(/Request|Response/, "");
                var data = agg[key] || {
                    title: logTitleMap[key] || key,
                    timestamp: new Date(),
                };

                // sort headers alphabetically
                var headers = Object.entries(log.logDetails.headers)
                    .sort(([a], [b]) => a.localeCompare(b))
                    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

                switch (log.logDetails.direction) {
                    case "request":
                        data = {
                            response: {
                                statusCode: 0,
                                statusText: "",
                                headers: {},
                                timestamp: log.logDetails.timestamp,
                                duration: undefined,
                                body: "",
                            },
                            ...data,
                            id: log.logDetails.uid,
                            request: {
                                method: log.logDetails.method,
                                url: log.logDetails.url,
                                timestamp: log.logDetails.timestamp,
                                headers: headers,
                                body: log.logDetails.payload,
                            },
                        };

                        break;
                    case "response":
                        data = {
                            id: log.logDetails.uid,
                            request: {
                                method: log.logDetails.method,
                                url: log.logDetails.url,
                                headers: {},
                                timestamp: undefined,
                                body: "",
                            },
                            ...data,
                            response: {
                                statusCode: log.logDetails.status,
                                statusText: httpStatusMap[log.logDetails.status] || "Unexpected",
                                headers: headers,
                                timestamp: log.logDetails.timestamp,
                                duration: undefined,
                                body: log.logDetails.payload,
                            },
                        };

                        if (data.response.timestamp && data.request.timestamp) {
                            data.response.duration =
                                new Date(data.response.timestamp) - new Date(data.request.timestamp);
                        }

                        break;
                    default:
                        data = {
                            ...data,
                            id: log.logDetails.uid,
                            request: {
                                method: log.logDetails.method,
                                url: log.logDetails.url,
                                headers: headers,
                                body: log.logDetails.payload,
                            },
                            response: {
                                statusCode: log.logDetails.status,
                                statusText: "-",
                                headers: {},
                                timestamp: log.logDetails.timestamp,
                                duration: undefined,
                                body: "",
                            },
                        };

                        break;
                }

                var timestamp = data.request.timestamp || data.response.timestamp || log.createdDate;
                data.timestamp = timestamp ? Date.parse(timestamp) : new Date();
                agg[key] = data;

                return agg;
            }, {});
            entries = Object.entries(entries).map(([k, v]) => v);
            entries.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

            return entries;
        };

        $scope.load = function () {
            var traceId = $routeParams.id;

            $scope.transaction.resultStatusName = "...";
            $scope.logs = null;

            core.gatewayTransaction.getDetails(traceId).then(function (t) {
                $scope.transaction = t.details || {};
                $scope.transaction.resultStatusName = statuses[t.details.resultStatus];
                $scope.logs = prepareViewModel(t.logs);
            });

            if ($routeParams.filter) {
                core.gatewayTransaction.getPrevNext(traceId, $routeParams.filter).then(function (np) {
                    $scope.nextPrev = np;
                });
            }
        };

        (function () {
            $scope.load();
        })();
    },
]);
