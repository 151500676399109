"use strict";
var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("contactService", ["httpService", "$q",
    function (httpService, $q) {
        return {
            send: function (contact) {
                return  httpService.post("/api/contact/", contact);
            }
        };
    }]);

/*****************
 CONTROLLERS
 ******************/
portal.controller("ContactCtrl", ["$scope", "$location", "coreService",
    function ($scope, $location, core) {
        $scope.contact = {};

        $scope.contact.email = core.auth.principal.email;
        $scope.contact.phone = core.auth.principal.phone;

        $scope.send = function () {
            return core.contact.send($scope.contact)
                .then(function (r) {
                    core.ui.showResult(r, "Thank you for contacting Bluefin support. A Bluefin team member will be responding to your shortly.");
                }, core.ui.showValidation($scope));
        };
        $scope.close = function () {
            $location.path("/");
        };
        $scope.reasons = [
            'For Customer Service',
            'Integration Support',
            'Other'
        ];
    }]);

