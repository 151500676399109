var portal = angular.module("portal");

/*****************
 SERVICES
 ******************/
portal.factory("samlService", ["httpService",
    function (httpService) {
        var service = {};

        service.delete = function(id){
            return  httpService.delete("/api/samlconfiguration/" + id);
        };

        service.find = function(id){
            return  httpService.get("/api/samlconfiguration/" + id);
        };

        service.update = function (id, data) {
            return  httpService.post("/api/samlconfiguration/" + id, data);
        };

        service.create = function (data) {
            return  httpService.put("/api/samlconfiguration", data);
        };

        return service;
    }
]);

portal.controller("SamlListCtrl", ["$scope", "coreService",
    function ($scope, core) {
        var r1 = function (id) {
            return core.dt.renderViewEdit(id, '/saml/samlconfiguration');
        };

        $scope.dtConfig = {
            ajax: {
                url: "/api/samlconfiguration/list"
            },
            sorting: [1, 'desc'],
            columnDefs: [
                {targets: [0], data: "Id", render: r1, name: "Id", width: "5%"},
                {targets: [1], data: "Name", title: "Name", name: "Name", width: "20%"},
                {targets: [2], data: "IdPKey", title: "Saml Key", name: "IdPKey", width: "20%"}
            ],
            createLink: "/saml/samlconfiguration/0",
            fnRowCallback: function( nRow, aData) {
                if(!aData.IsActive) {
                    $(nRow).addClass("text-muted");
                }
            }
        };
    }
]);

portal.controller("SamlDetailsCtrl", ["$scope", "$routeParams", "coreService","$location",
    function ($scope, $routeParams, core, $location) {

        var _load = function(){
            if ($routeParams.id > 0) {
                core.saml.find($routeParams.id).then(function (saml) {
                    $scope.saml = saml[0];
                });
            }
        };

        $scope.changePartner = function () {
            _loadClients();
        };

        var _loadClients = function () {
            if ($scope.saml.PartnerId) {
                core.client.findByPartner($scope.saml.PartnerId)
                    .then(function (c) {
                        $scope.clients = c;
                    });
            } else {
                $scope.clients = [];
            }
        };

        $scope.save = function () {
            if ($scope.saml.Id > 0) {
                return core.saml.update($routeParams.id, $scope.saml).then(function (r) {
                    core.ui.showResult(r, "SAML Config has been updated.", $scope.close);
                }, core.ui.showValidation($scope));
            } else {
                return core.saml.create($scope.saml).then(function (r) {
                    core.ui.showResult(r, "SAML Config has been added.", $scope.close);
                }, core.ui.showValidation($scope));
            }
        };

        $scope.close = function () {
            $location.path("/saml/samlconfiguration");
        };

        (function () {
            _load();
        })();
    }
]);